import { RESET_STORE, SET_SCHEMES_DATA_IN_STORE } from '../../actions/types';

const INITIAL_STATE = {
  active: [],
  draft: [],
  closed: [],
  rejected: [],
  view: {},
  schemeCounts: {},
  cancelled: [],
  schemeIndexReport: [],
  schemePayoutReport: [],
};

const schemes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SCHEMES_DATA_IN_STORE: {
      const { type, data } = action.data;
      return {
        ...state,
        [type]: data,
      };
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default schemes;
