import {
  SET_TEMPORARY_TRIPS_IN_STORE,
  SET_SELECTED_TRIP_IN_STORE,
  SET_TEMPORARY_TRIP_AS_DISPATCHED,
  REMOVE_SELECTED_TRIP_FROM_TEMPORARY_LIST,
  SET_ACTIVE_TRIPS_IN_STORE,
  SET_CURRENT_ACTIVE_TRIP_ITEMS,
  SET_DATA_FOR_SELECTED_PARCELS_IN_ACTIVE_TRIP,
  UPDATE_CURRENT_TRIP_PARCELS_IN_STORE,
  UPDATE_VEHICLE_NUMBER_FOR_TEMPORARY_TRIP,
  SET_OPEN_TRIPS_IN_STORE,
  SET_UNPAID_TRIPS_IN_STORE,
  SET_INVOICED_TRIPS_IN_STORE,
  SET_PENDING_RECEIVED_TRIPS_IN_STORE,
  SET_CONFIRMED_TRIPS_IN_STORE,
  SET_CURRENT_CONFIRMED_TRIP_ITEMS,
} from './types';

export const setTemporaryTripsInStore = (data) => {
  return {
    type: SET_TEMPORARY_TRIPS_IN_STORE,
    data,
  };
};
export const setSelectedTripInStore = (tripNumber) => {
  return {
    type: SET_SELECTED_TRIP_IN_STORE,
    data: tripNumber,
  };
};
export const setTemporaryTripAsDispatched = (data) => {
  return {
    type: SET_TEMPORARY_TRIP_AS_DISPATCHED,
    data,
  };
};
export const removeSelectedTripFromTemporaryList = () => {
  return {
    type: REMOVE_SELECTED_TRIP_FROM_TEMPORARY_LIST,
  };
};
export const setActiveTripsListInStore = (data) => {
  return {
    type: SET_ACTIVE_TRIPS_IN_STORE,
    data,
  };
};
export const setOpenTripsListInStore = (data) => {
  return {
    type: SET_OPEN_TRIPS_IN_STORE,
    data,
  };
};

export const setCurrentTripParcels = (data) => {
  return {
    type: SET_CURRENT_ACTIVE_TRIP_ITEMS,
    data,
  };
};
export const setCurrentConfirmedTripParcels = (data) => {
  return {
    type: SET_CURRENT_CONFIRMED_TRIP_ITEMS,
    data,
  };
};
export const setDataForSelectedParcelsInActiveTrip = (data) => {
  return {
    type: SET_DATA_FOR_SELECTED_PARCELS_IN_ACTIVE_TRIP,
    data,
  };
};
export const updateCurrentTripParcelsInStore = (data) => {
  return {
    type: UPDATE_CURRENT_TRIP_PARCELS_IN_STORE,
    data,
  };
};
export const updateVehicleNumberForActiveTemporaryTrip = (data) => {
  return {
    type: UPDATE_VEHICLE_NUMBER_FOR_TEMPORARY_TRIP,
    data,
  };
};

export const setUnpaidTripsInStore = (data) => {
  return {
    type: SET_UNPAID_TRIPS_IN_STORE,
    data,
  };
};

export const setInvoicedTripsInStore = (data) => {
  return {
    type: SET_INVOICED_TRIPS_IN_STORE,
    data,
  };
};

export const setPendingReceivedTripsInStore = (data) => {
  return {
    type: SET_PENDING_RECEIVED_TRIPS_IN_STORE,
    data,
  };
};

export const setConfirmedTripsInStore = (data) => {
  return {
    type: SET_CONFIRMED_TRIPS_IN_STORE,
    data,
  };
};
