import {
  faHome,
  faPeopleArrows,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateSpinnerState } from '../../actions/spinner';
import {
  setSelectedTripInStore,
  setTemporaryTripsInStore,
} from '../../actions/trips';
import { getTemporaryTripsFromServer } from '../../parse-functions/trips';
import { convertArrayToObj } from '../../utils/json';
import TripsTableData from './tripsTableData';
import DateRangeSelector from '../../components/common/DateRangeSelector';
import { updateToastInfo } from '../../actions/settings';
import { setFiltersInStore } from '../../actions/filters';
import { returnEndOfDay, returnStartOfDay } from '../../utils/datetime';
import ChangeLoaderInfoModal from './updateLoaderInfoModal';
import { getLoadersListForStore } from '../../parse-functions/loaders';
import { setLoadersListInStore } from '../../actions/loaders';
import { returnTemporaryTripColumns } from './tripsUtils';
import EditableDropDown from '../../components/common/EditableDropdown';
import { returnWarehouseDropdownOptions } from '../../utils/warehouse';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';

const TemporaryTrips = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageType = 'temporaryTrips';
  const temporaryTrips = useSelector(
    (state) => state.trips.temporaryTrips || {}
  );
  const storeInfo = useSelector((state) => state.user.warehouse || {});
  const filtersValue = useSelector((state) => state.filters?.[pageType]);
  const [showChangeLoaderModal, setShowChangeLoaderModal] = useState(false);
  const loadersList = useSelector((state) => state?.loaders?.loadersList || []);
  const isSuperAdmin = useSelector(
    (state) => state.user.preferences.isSuperAdmin || false
  );
  const [formInput, setFormInput] = useState({
    storeName: filtersValue?.storeName,
    region: filtersValue?.region,
  });
  const zones = useSelector((state) => state?.user?.config?.zones || {});

  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );

  useEffect(() => {
    if (
      (storeInfo.warehouseCode || isAdmin || isSuperAdmin) &&
      loadersList?.length === 0
    ) {
      fetchLoadersListForWarehouse();
    }
  }, [storeInfo]);

  const fetchLoadersListForWarehouse = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const loadersList = await getLoadersListForStore({
        zone: storeInfo?.zone,
        storeCode: storeInfo?.warehouseCode,
        status: 'active',
      });
      dispatch(setLoadersListInStore(loadersList));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  };
  const handleRowClick = (tripNumber, objectId) => {
    dispatch(setSelectedTripInStore(tripNumber));
    navigate(`/trips/temporary/${objectId}`);
  };
  const getTemporaryTrips = async (requestProps) => {
    dispatch(setTemporaryTripsInStore([]));
    dispatch(updateSpinnerState(true));
    const tempTrips = await getTemporaryTripsFromServer(requestProps);
    const tripsObject = convertArrayToObj(tempTrips, 'tripNumber');
    dispatch(setTemporaryTripsInStore(tripsObject));
    dispatch(
      updateToastInfo({
        show: true,
        type: 'success',
        title: 'Trips fetched',
        message: 'All Temporary Trips for selected date has been fetched',
      })
    );
    dispatch(updateSpinnerState(false));
  };
  const onDateSelect = (startDate, endDate) => {
    const requestProps = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      warehouseCode: !isSuperAdmin
        ? storeInfo?.warehouseCode
        : formInput.storeName?.[0],
      region: isSuperAdmin && formInput.region?.[0],
      isSuperAdmin,
    };
    dispatch(
      setFiltersInStore({
        pageType,
        data: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          storeName: formInput.storeName,
          region: formInput.region,
        },
      })
    );
    getTemporaryTrips(requestProps);
  };
  const handleGetTemporaryTrips = () => {
    getTemporaryTrips({
      startDate: filtersValue.startDate
        ? new Date(filtersValue.startDate)
        : returnStartOfDay(new Date()),
      endDate: filtersValue.endDate
        ? new Date(filtersValue.endDate)
        : returnEndOfDay(new Date()),
      warehouseCode:
        filtersValue?.selectedStore?.[0] || storeInfo?.warehouseCode,
      isSuperAdmin,
    });
  };
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Trips')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('Temporary')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t('Temporary Trips')}</h4>
          <p className="mb-0">
            {t('All your non-dispatched trips are listed here.')}
          </p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              setShowChangeLoaderModal(true);
            }}
            style={{
              marginRight: 20,
            }}
          >
            <FontAwesomeIcon icon={faPeopleArrows} />
            &nbsp;&nbsp; {t('Change Loader Details')}
          </Button>
          <ButtonGroup>
            <Button
              onClick={() => {
                handleGetTemporaryTrips();
              }}
              variant="outline-primary"
              size="sm"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;&nbsp;{t('Refresh')}
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <div className="mb-2 d-flex gap-4">
          {isSuperAdmin && (
            <div className="d-flex gap-4">
              <Form.Group>
                <Form.Label>{t('Region')}</Form.Label>
                <EditableDropDown
                  id="region"
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      region: [e.value],
                    }))
                  }
                  value={formInput.region}
                  options={[
                    { label: 'All', value: 'All' },
                    ...arrayToDropdownOptions(Object.keys(zones)),
                  ]}
                  placeholder="Choose Region"
                  backgroundColor="#F0F0F0"
                  width={'250px'}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>{t('Store Name')}</Form.Label>
                <EditableDropDown
                  id="warehouseCode"
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      storeName: [e.value],
                    }))
                  }
                  value={formInput.storeName}
                  options={[
                    { label: 'All', value: 'All' },
                    ...returnWarehouseDropdownOptions(otherWarehouses),
                  ]}
                  placeholder="Choose Store Name"
                  backgroundColor="#F0F0F0"
                  width={'250px'}
                />
              </Form.Group>
            </div>
          )}
          <div className="d-flex gap-4">
            <div className="mt-4">
              <DateRangeSelector
                onSubmit={onDateSelect}
                filtersValue={filtersValue}
              />
            </div>
          </div>
        </div>
      </div>

      <TripsTableData
        isTemporary
        columns={returnTemporaryTripColumns()}
        data={Object.values(temporaryTrips)}
        onRowClick={handleRowClick}
      />
      <ChangeLoaderInfoModal
        showModal={showChangeLoaderModal}
        closeModal={() => {
          setShowChangeLoaderModal(false);
        }}
      />
    </>
  );
};
export default TemporaryTrips;
