/* eslint-disable no-loop-func */

import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Nav,
  Pagination,
  Table,
} from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import {
  getColumnHeaders,
  returnBuybackScanInformation,
  returnCustomerAddressString,
  returnParcelProductInformation,
  returnParcelReturnReceivedInfo,
  returnParcelReturnScanInformation,
  returnProductBuybackInformation,
} from '../../utils/table';
import PODImageSlider from '../../components/common/images/podImagesSlider';
import { returnRowBGColor, showTagTypeInTrips } from '../../utils/parcels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { getTripBadgeStatus } from '../../utils/trips';
import { orderStatusMap } from '../../utils/constants';
import {
  returnUserReadableDate,
  returnUserReadableDateTime,
} from '../../utils/datetime';
import UploadTrigger from './UploadImageForParcel';

const columnNames = [
  'Customer',
  'Address',
  'Invoice',
  'Serial',
  'Product',
  'Buyback',
  'Mobile Status',
  'Dashboard Status',
  'Return Info',
  'Buyback Scan',
  'Received Info',
  'Others',
  'POD / Sign',
];
const TableRow = (props) => {
  const {
    customerName,
    shippingMobileNo,
    serialNo,
    invoiceNo,
    quantity,
    date,
    mobileStatus,
    mobileSubStatus,
    handleShowPODImagesModal,
    podImages,
    customerSignature,
    objectId,
    removeItemFromList,
    tripStatus,uploadUpdate
  } = props;
  const status = props.dashboardStatus || mobileStatus || props.status;
  const subStatus = props.dashboardSubStatus || mobileSubStatus || '-';
  const returnPODImagesAndSign = () => {
    let imgArray = [];
    if (podImages?.length > 0) {
      imgArray = [...imgArray, ...podImages];
    }
    if (customerSignature) {
      imgArray = [...imgArray, customerSignature];
    }
    return imgArray;
  };
  const totalImages = returnPODImagesAndSign();
  return (
    <tr className={returnRowBGColor(status)}>
      <td>
        <div className="row d-flex">
          {returnCustomerAddressString(
            customerName,
            `${props?.shippingMobileNo || props.mobileNo}`,
            150,
            35
          )}
        </div>
        <div
          className="row d-flex"
          style={{
            marginLeft: 10,
          }}
        >
          {showTagTypeInTrips(props) && !props?.returnScannedAt ? (
            <Badge
              bg={getTripBadgeStatus(showTagTypeInTrips(props))}
              className="w-50 pl-4 badge-lg"
            >{`Scan ${showTagTypeInTrips(props)}`}</Badge>
          ) : (
            ''
          )}
          {props?.customerCode ? `Code: ${props.customerCode}` : ''}
        </div>
      </td>
      <td>
        {returnCustomerAddressString(
          `${props?.shippingAddress1 || ''}${props?.shippingAddress2 || ''}${
            props?.shippingAddress3 || ''
          }`,
          `${props?.shippingPincode}`,
          150,
          35
        )}
      </td>
      <td>
        <div className="d-block">
          <span className="fw-bold">{invoiceNo}</span>
          <div className="fw-normal">
            {date ? returnUserReadableDate(date) : '-'}
          </div>
        </div>
      </td>
      <td>
        <div className="d-block">
          <span className="fw-normal">{serialNo}</span>
          <div className="fw-normal">
            {Number(quantity).toFixed(0)} Quantity
          </div>
        </div>
      </td>
      <td>{returnParcelProductInformation(props)}</td>
      <td>{returnProductBuybackInformation(props)}</td>
      <td>
        <div className="d-block">
          <span className="fw-bold">
            {mobileStatus ? (
              <Badge
                bg={getTripBadgeStatus(mobileStatus)}
                className="badge-lg w-100"
              >
                {orderStatusMap[mobileStatus]}
              </Badge>
            ) : (
              ''
            )}
          </span>
          <div className="fw-normal">
            {mobileSubStatus ? (
              <Badge
                bg={getTripBadgeStatus(mobileSubStatus)}
                className="badge-lg w-100"
              >
                {orderStatusMap[mobileSubStatus]}
              </Badge>
            ) : (
              ''
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="d-block">
          <span className="fw-bold">
            {props?.dashboardStatus ? (
              <Badge
                bg={getTripBadgeStatus(props?.dashboardStatus)}
                className="badge-lg w-100"
              >
                {orderStatusMap[props?.dashboardStatus]}
              </Badge>
            ) : (
              ''
            )}
          </span>
          <div className="fw-normal">
            {props?.dashboardSubStatus ? (
              <Badge
                bg={getTripBadgeStatus(props?.dashboardSubStatus)}
                className="badge-lg w-100"
              >
                {orderStatusMap[props?.dashboardSubStatus]}
              </Badge>
            ) : (
              ''
            )}
          </div>
        </div>
      </td>
      <td>{returnParcelReturnScanInformation(props)}</td>
      <td>{returnBuybackScanInformation(props)}</td>
      <td>{returnParcelReturnReceivedInfo(props)}</td>
      <td>
        <div className="d-block">
          <span className="fw-normal">{props?.remarks || '-'}</span>
          <div className="fw-normal">
            {props?.cashCollected ? `Cash: INR ${props?.cashCollected}` : '-'}
          </div>
        </div>
      </td>
      <td>
        <div className="d-block d-flex align-item-center">
         
            <Button
              disabled={!totalImages?.length}
              onClick={() => {
                handleShowPODImagesModal(
                  totalImages,
                  props?.parcelUpdateLocation, objectId
                );
              }}
              variant="outline-info"
              size="sm"
              className="m-1"
            >{`${totalImages?.length || 0} Images`}</Button>
          <UploadTrigger objectId={objectId} uploadUpdate={uploadUpdate} />
        </div>
      </td>
      {!['pending', 'completed'].includes(tripStatus) ? (
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  removeItemFromList([objectId]);
                }}
                className="text-danger"
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      ) : (
        <td />
      )}
    </tr>
  );
};

const ActiveTripParcels = (props) => {
  const {
    data,
    removeItemFromList,
    onTableSelectAll,
    onRowSelect,
    tripStatus,
  } = props;
  const [podImages, setShowPODImages] = useState([]);
  const [showPODImagesModal, setShowPODImagesModal] = useState(false);
  const [podImageDescription, setPODImageDescription] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [disablePrevious, setDisablePrevious] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageDataStartIndex, setCurrentPageDataStartIndex] = useState(0);
  const [currentPageDataEndIndex, setCurrentPageDataEndIndex] = useState(0);
  const [objectId,setObjectId] = useState("")
  const columnHeaders = getColumnHeaders(columnNames, onTableSelectAll);
  const pageSize = 1000;

  const uploadUpdate = (urls,selectedObjectId) => {
    setCurrentPageData((prevData) => {
      const updatedPage = prevData.map((each) => {
        if (each?.objectId === selectedObjectId) {

          const currentPod = each?.podImages || []
          return { ...each, podImages: [...currentPod, ...urls] };
        }
        return each;
      });
  
      return updatedPage; // Ensuring a new reference to trigger re-render
    });
  };
  

  useEffect(() => {
    calculateTableData();
  }, [data, currentPage]);

  const calculateTableData = () => {
    // validate the system again and fetch the orders to be shown.
    const currentPageDataStartIndex = (currentPage - 1) * pageSize;
    const currentPageDataEndIndex = currentPage * pageSize;
    setCurrentPageDataStartIndex(currentPageDataStartIndex);
    setCurrentPageDataEndIndex(currentPageDataEndIndex);
    const dataCopy = Object.assign([], data);
    const currentData = dataCopy.slice(
      currentPageDataStartIndex,
      currentPageDataEndIndex
    );
    setCurrentPageData(currentData);
    setDisablePrevious(currentPage === 1);
    setDisableNext(currentPage === totalPages);
    setTotalPages(Math.ceil(data?.length / pageSize));
  };

  const onPaginationChange = (pageNumber) => {
    if (pageNumber === 'previous') {
      setCurrentPage(currentPage - 1);
    } else if (pageNumber === 'next') {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(pageNumber);
    }
  };
  const returnTotalPages = () => {
    const pagesArr = [];
    for (let i = 1; i <= totalPages; i += 1) {
      pagesArr.push(
        <Pagination.Item
          onClick={() => {
            onPaginationChange(i);
          }}
          active={currentPage === i}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pagesArr;
  };
  const handleShowPODImagesModal = (images, parcelUpdateLocation,objectId) => {
    setShowPODImages(images);
    if (parcelUpdateLocation?.time) {
      setPODImageDescription(
        `Image taken at: ${returnUserReadableDateTime(
          parcelUpdateLocation?.time
        )}`
      );
    }
    setObjectId(objectId)
    setShowPODImagesModal(true);
  };
  return (
    <>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>{columnHeaders}</tr>
            </thead>
            <tbody>
              {currentPageData.map((t, index) => (
                <TableRow
                  onRowSelect={onRowSelect}
                  key={`transaction-${t.objectId}`}
                  {...t}
                  handleShowPODImagesModal={handleShowPODImagesModal}
                  index={index}
                  currentPageDataStartIndex={currentPageDataStartIndex}
                  removeItemFromList={removeItemFromList}
                  tripStatus={tripStatus} uploadUpdate={uploadUpdate}
                />
              ))}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            {totalPages > 1 && (
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    disabled={disablePrevious}
                    onClick={() => {
                      onPaginationChange('previous');
                    }}
                  >
                    Previous
                  </Pagination.Prev>
                  {returnTotalPages()}
                  <Pagination.Next
                    disabled={disableNext}
                    onClick={() => {
                      onPaginationChange('next');
                    }}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
            )}
            <small className="fw-bold">
              Showing{' '}
              <b>
                {currentPageDataStartIndex + 1} -{' '}
                {currentPageDataEndIndex > data?.length
                  ? data?.length
                  : currentPageDataEndIndex}
              </b>{' '}
              out of <b>{data?.length}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
      <PODImageSlider
        description={podImageDescription}
        show={showPODImagesModal}
        handleClose={setShowPODImagesModal}
        images={podImages} objectId={objectId} 
      />
    </>
  );
};
export default ActiveTripParcels;
