import React, { useEffect, useState } from 'react';
import AddSchemeRedesign from './AddSchemeRedesign';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateSpinnerState } from '../../../../actions/spinner';
import { updateToastInfo } from '../../../../actions/settings';
import {
  addNewScheme,
  getSchemeById,
} from '../../../../parse-functions/sms/scheme';
import { Routes } from '../../../../router/routes';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { useTranslation } from 'react-i18next';
import BreadCrumbTitle from '../common/BreadCrumbTitle';

const EditScheme = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editData, setEditData] = useState({});
  const { t } = useTranslation();
  const breadcrumbs = arrayToBreadCrumbs([
    [t('Draft Schemes'), Routes.SMS.Target.RedesignDraftSchemes.path],
    ['Edit Scheme'],
  ]);
  const handleSave = async (data) => {
    try {
      await addNewScheme({
        ...data,
        edit: true,
        editObjectId: data.objectId,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const schemeDataResult = await getSchemeById({ id });
        schemeDataResult.category = schemeDataResult.productCategory;
        schemeDataResult.products = schemeDataResult.products.map((item) => ({
          ...item,
          productName: [item.productName],
          valuationType: [item.valuationType],
        }));
        setEditData(schemeDataResult);
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: 'Scheme Fetch Failed',
          })
        );
      } finally {
        dispatch(updateSpinnerState(false));
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <div className="d-block mb-4 mb-md-0">
        <BreadCrumbTitle breadcrumbs={breadcrumbs} />
      </div>
      {Object.keys(editData).length > 0 && (
        <AddSchemeRedesign editData={editData} handleSave={handleSave} />
      )}
    </div>
  );
};

export default EditScheme;
