import React, { useEffect } from "react";
import logo from "../../../assets/img/brand/vs-logo.png";
import rectangle from "../../../assets/img/Rectangle.png";
import coin from "../../../assets/img/coin.png";
import loyaltyBackground from "../../../assets/img/Group 1000004064.png";
import { useLocation, useNavigate } from "react-router-dom";

const LoyaltyPoints = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;
  const pointsData = location?.state?.pointsData;

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", () => {
      window.history.pushState(null, "", window.location.href);
    });
  }, []);

  return (
    <div
      className="d-flex flex-column align-items-center vh-100 position-relative"
      style={{
        backgroundImage: `url(${loyaltyBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        backgroundColor: "#d91a1a",
        fontFamily: "Arial, sans-serif",
        overflow: "hidden",
      }}
    >
      <div
        className="w-100 bg-white d-flex justify-content-start p-4 align-items-center"
        style={{ position: "absolute", top: 0, height: "5vh" }}
      >
        <img
          src={logo}
          alt="Logo"
          className="img-fluid"
          style={{ maxWidth: "160px" }}
        />
      </div>
      <div
        className="text-center text-white mt-5 pt-5"
        style={{ width: "100%" }}
      >
        <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>Welcome {data?.name}</h2>
        <p style={{ fontSize: "18px" }}>Your current VS Reward Points</p>
      </div>
      <div
        className="position-relative text-center"
        style={{ width: "80%", marginTop: "100px", padding: "10px", minWidth: "500px" }}
      >
        <img
          src={rectangle}
          alt="Points Card"
          className="img-fluid"
          style={{ maxWidth: "50%", minWidth: "400px", height: "auto" }}
        />
        <div
          className="position-absolute"
          style={{ top: "-25%", left: "50%", transform: "translate(-50%, 0%)" }}
        >
          <img
            src={coin}
            alt="Coin"
            className="img-fluid"
            style={{ width: "70%", minWidth: "150px" }}
          />
        </div>
        <div
          className="position-absolute text-white text-center w-100"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h3 style={{ fontSize: "28px", fontWeight: "bold" }}>{pointsData?.PointsEarned} Points Available</h3>
        </div>
      </div>
      <div className="text-white text-center" style={{ width: "100%" }}>
        <p style={{ fontSize: "22px" }}>Use your points to get discounts on your next purchase!</p>
      </div>
      <div
        className="d-flex align-items-center justify-content-between bg-white p-3 rounded mt-4 flex-column flex-md-row"
        style={{
          maxWidth: "90%",
          width: "90%",
          position: "absolute",
          bottom: "5%",
          textAlign: "center",
        }}
      >
        <span style={{ fontSize: "20px", flex: "1", marginBottom: "10px" }}>
          <strong>50 points</strong> will expire on 10/03/2025. Use them before they disappear!
        </span>
        <button
          className="btn btn-dark"
          style={{ fontSize: "20px", padding: "12px 18px" }}
          onClick={() => window.location.href = "https://www.vijaysales.com"}
        >
          Redeem Now
        </button>
      </div>
    </div>
  );
};

export default LoyaltyPoints;
