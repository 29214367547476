import Parse from 'parse';
import {
  faEdit,
  faHome,
  faSearch,
  faTruck,
  faTruckMoving,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Breadcrumb,
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import { removeSelectedTripFromTemporaryList } from '../../actions/trips';
import ConfirmationModal from '../../components/common/confirmationModal';
import {
  deleteEmptyTripByObjectId,
  getTripDetailsByTripNumberFromServer,
  markTripParcelAsScanned,
  removeParcelFromSheet,
} from '../../parse-functions/trips';
import { Routes } from '../../router/routes';
import {
  getTripBadgeStatus,
  returnMatchingSerialParcels,
} from '../../utils/trips';
import TemporaryDispatchModal from './temporaryDispatchModal';
import TemporaryTripParcels from './temporaryTripParcels';
import UploadDisplayTempoSheet from '../../components/planning/uploadDisplaySheet';
import ScanInvoiceNumber from '../../components/planning/ScanInvoiceNumber';
import { convertArrayToObj, sortArrayOfJSONByKeyName } from '../../utils/json';
import EWayBillComponent from '../../components/ewaybill';
import { getVehiclesListInZone } from '../../parse-functions/vehicles';
import { setVehiclesListInStore } from '../../actions/drivers';
import { getLoadersListForStore } from '../../parse-functions/loaders';
import { setLoadersListInStore } from '../../actions/loaders';
import UpdateVehicleNumberModal from './UpdateVehicleNumberModal';
import { returnUserReadableDate } from '../../utils/datetime';

const TemporaryTripDetails = () => {
  const { objectId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTrip = useSelector((state) => state.trips.selectedTrip || null);
  const tripMetaData = useSelector((state) => state.trips.temporaryTrips || {});
  const loadersList = useSelector((state) => state?.loaders?.loadersList || []);
  const [currentTripData, setCurrentTripData] = useState([]);
  const [bulkUpdateEnabled, setBulkUpdateEnabled] = useState(false);
  const [currentActiveItem, setCurrentActiveItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [showDeleteEmptyTripModal, setShowDeleteEmptyTripModal] =
    useState(false);
  const warehouse = useSelector((state) => state?.user?.warehouse || {});
  const vehicleList = useSelector((state) => state?.drivers?.driversList || {});
  const [serialNumberToScanValue, setSerialNumberToScanValue] = useState('');
  const [showUpdateVehicleNumberModal, setShowUpdateVehicleNumberModal] =
    useState(false);
  const [
    showUpdateTemposheetWarningModal,
    setShowUpdateTemposheetWarningModal,
  ] = useState(false);

  useEffect(() => {
    if (selectedTrip) {
      getTripDetails();
    } else {
      navigate(Routes.Trips.Temporary.path);
    }
  }, []);

  useEffect(() => {
    if (warehouse.warehouseCode && loadersList?.length === 0) {
      fetchLoadersListForWarehouse();
    }
  }, [warehouse]);

  const fetchLoadersListForWarehouse = async (getAllLoaders = false) => {
    try {
      dispatch(updateSpinnerState(true));
      const loadersList = await getLoadersListForStore({
        zone: warehouse.zone,
        storeCode: warehouse?.warehouseCode,
        getAllLoaders,
        status: 'active',
      });
      dispatch(setLoadersListInStore(loadersList));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  };
  const getTripDetails = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const tripDetails = await getTripDetailsByTripNumberFromServer(
        selectedTrip,
        true
      );
      // setCurrentTripData(sortArrayOfJSONByKeyName(tripDetails, "invoiceNo"));
      setCurrentTripData(tripDetails);
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  };
  const handleRemoveItemFromList = (parcelObjectId) => {
    setCurrentActiveItem(parcelObjectId);
    setShowDeleteModal(true);
  };
  const handleOnConfirmClick = async () => {
    dispatch(updateSpinnerState(true));
    try {
      await removeParcelFromSheet(currentActiveItem);
      const updatedParcels = currentTripData?.filter(
        (order) => currentActiveItem.indexOf(order.objectId) === -1
      );
      setCurrentTripData(updatedParcels);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Item Removed',
          message: 'Parcel Removed from trip successfully',
        })
      );
      dispatch(updateSpinnerState(false));
      setCurrentActiveItem(null);
      setShowDeleteModal(false);
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Removal Failed',
          message: 'Parcel removal failed, Please try later',
        })
      );
      dispatch(updateSpinnerState(false));
      setCurrentActiveItem(null);
      setShowDeleteModal(false);
    }
  };

  const onTableSelectAll = (value) => {
    const tripData = Object.assign([], currentTripData);
    tripData.forEach((data) => (data.isSelected = value));
    setBulkUpdateEnabled(value);
    setCurrentTripData(tripData);
  };
  const onBulkUpdateApply = (action) => {
    const selectedParcels = currentTripData?.filter(
      (parcel) => parcel.isSelected
    );
    switch (action) {
      case 'scanAll': {
        if (selectedParcels?.length > 0) {
          markItemsAsScanned(selectedParcels, false);
        } else {
          dispatch(
            updateToastInfo({
              show: true,
              type: 'danger',
              title: t('No Matching Item Found'),
              message: t(
                'Please check the serial number. Matching item not found to load'
              ),
            })
          );
        }
        break;
      }
      case 'deleteAll': {
        const selectedParcelIds = selectedParcels.map(
          (parcel) => parcel.objectId
        );
        setCurrentActiveItem(selectedParcelIds);
        setShowDeleteModal(true);
        break;
      }
      default: {
        break;
      }
    }
  };
  const onRowSelect = (value, objectId) => {
    const tripData = Object.assign([], currentTripData);
    tripData.forEach((data) => {
      if (data.objectId === objectId) {
        data.isSelected = value;
      }
    });
    setCurrentTripData(tripData);
    const someSelected = tripData.filter((item) => item.isSelected === true);
    setBulkUpdateEnabled(someSelected?.length > 0);
  };
  const handleDeleteModalClose = () => {
    setCurrentActiveItem(null);
    setShowDeleteModal(false);
  };
  const markItemsAsScanned = async (matchingParcels, updateAllPassed) => {
    const tripData = Object.assign([], currentTripData);
    if (!tripMetaData[selectedTrip]?.vehicleNumber?.length) {
      setShowUpdateTemposheetWarningModal(true);
      return;
    }
    try {
      dispatch(updateSpinnerState(true));
      await markTripParcelAsScanned(
        matchingParcels?.map((item) => item.objectId),
        selectedTrip
      );
      const matchingParcelsObject = convertArrayToObj(
        matchingParcels,
        'objectId'
      );
      setSerialNumberToScanValue('');
      tripData.forEach((parcel) => {
        if (parcel.isSelected || matchingParcelsObject[parcel.objectId]) {
          parcel['scannedBy'] = Parse.User.current().getUsername();
          parcel['scannedAt'] = new Date();
          parcel.isSelected = false;
        }
      });
      setCurrentTripData(tripData);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Item marked as scan'),
          message: t('Item Scanned Successfully. Please scan other items'),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleItemScan = async (val) => {
    const tripData = Object.assign([], currentTripData);
    const matchingParcels = returnMatchingSerialParcels(tripData, val);
    if (matchingParcels?.length > 0) {
      markItemsAsScanned(matchingParcels, true);
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('No Matching Item Found'),
          message: t(
            'Please check the serial number. Matching item not found to load'
          ),
        })
      );
    }
  };

  const handleGetVehiclesListForThisZone = async (getAllVehicles = false) => {
    dispatch(updateSpinnerState(true));
    try {
      const vehiclesList = await getVehiclesListInZone({
        zone: warehouse.zone,
        getAllVehicles,
        status: 'active',
      });
      dispatch(
        setVehiclesListInStore(
          convertArrayToObj(
            JSON.parse(JSON.stringify(vehiclesList)),
            'objectId'
          )
        )
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error Fetching Vehicles List'),
          message:
            e.message || t('Error in getting vehicles list. Please try again.'),
        })
      );
      dispatch(updateSpinnerState(false));
    }
  };

  const handleTemporaryDispatchModalShow = async (value) => {
    if (value && Object.keys(vehicleList)?.length < 1) {
      await handleGetVehiclesListForThisZone();
    }
    setShowDispatchModal(value);
    if (!value) {
      dispatch(updateSpinnerState(false));
    }
  };
  const deleteEmptyTripHandleModal = () => {
    setShowDeleteEmptyTripModal(true);
  };
  const handleDeleteEmptyTripModalClose = () => {
    setShowDeleteEmptyTripModal(false);
  };
  const handleDeleteEmptyTrip = async () => {
    setShowDeleteEmptyTripModal(false);
    dispatch(updateSpinnerState(true));
    try {
      await deleteEmptyTripByObjectId(objectId);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Delete Successful',
          message: t(
            'Trip Delete Successfully. You will be redirected to list page'
          ),
        })
      );
      dispatch(removeSelectedTripFromTemporaryList());
      dispatch(updateSpinnerState(false));
      navigate(Routes.Trips.Temporary.path);
    } catch (e) {
      dispatch(updateSpinnerState(true));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Delete Failed',
          message: t(e.message || 'Trip Delete Failed. Contact Support'),
        })
      );
    }
  };
  const returnTooltipTitle = (type) => {
    let text = '';
    if (type === 'EWB') {
      text =
        tripMetaData[selectedTrip]?.status === 'temporary'
          ? `You cant print EWB before confirming the trip`
          : 'Pring EWB from here';
    } else {
      text =
        tripMetaData[selectedTrip]?.status === 'pending'
          ? `Print ${type} from here`
          : `You cant print ${type} before dispatching the trip`;
    }
    return (
      <Tooltip id={`print${type}Tooltip`} className="m-0">
        {t(text)}
      </Tooltip>
    );
  };
  const checkDispatch = () => {
    // check if all items are marked as scanned.
    const scannedItems = currentTripData.filter((parcel) => parcel.scannedBy);
    if (scannedItems?.length === currentTripData?.length) {
      handleTemporaryDispatchModalShow(true);
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Scan Incomplete',
          message: t('Please scan all items before dispatching'),
        })
      );
    }
  };
  const handleParcelInsertInTemporary = (parcels) => {
    setCurrentTripData([...currentTripData, ...parcels]);
  };
  const handleUpdateVehicleNumber = async () => {
    if (Object.keys(vehicleList)?.length < 1) {
      await handleGetVehiclesListForThisZone();
    }
    dispatch(updateSpinnerState(false));
    setShowUpdateVehicleNumberModal(true);
  };
  const handleGetAllVehiclesFromSystem = async () => {
    await handleGetVehiclesListForThisZone(true);
  };
  const handleGetAllLoadersInSystem = async () => {
    await fetchLoadersListForWarehouse(true);
  };
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Trips')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('Temporary')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>
            {selectedTrip}&nbsp;
            {tripMetaData[selectedTrip]?.vehicleNumber
              ? `(${tripMetaData[selectedTrip]?.vehicleNumber})`
              : ''}
          </h4>
          <p className="mb-0">
            <span
              style={{
                marginRight: 12,
              }}
            >
              {tripMetaData[selectedTrip]?.createdBy && (
                <>
                  Created By:{' '}
                  <span className="fw-bold">
                    {tripMetaData[selectedTrip].createdBy}
                  </span>
                  ,
                </>
              )}
            </span>
            <span
              style={{
                marginRight: 12,
              }}
            >
              {tripMetaData[selectedTrip]?.createdAt && (
                <>
                  Created At:{' '}
                  <span className="fw-bold">
                    {returnUserReadableDate(
                      tripMetaData[selectedTrip].createdAt
                    )}
                  </span>
                </>
              )}
            </span>
            <span
              style={{
                marginRight: 12,
              }}
            >
              {tripMetaData[selectedTrip]?.status && (
                <>
                  <span>
                    &nbsp;&nbsp;
                    <Badge
                      bg={getTripBadgeStatus(tripMetaData[selectedTrip].status)}
                      className="badge-lg"
                    >
                      {tripMetaData[selectedTrip].status}
                    </Badge>{' '}
                    &nbsp;&nbsp;
                  </span>
                </>
              )}
            </span>
            <span
              style={{
                marginRight: 12,
              }}
            >
              {tripMetaData[selectedTrip]?.vehicleArea && (
                <>Vehicle Area: {tripMetaData[selectedTrip]?.vehicleArea}</>
              )}
            </span>
          </p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          {currentTripData?.length > 0 ? (
            <>
              {tripMetaData[selectedTrip]?.vehicleNumber?.length ? (
                <Button
                  onClick={() => {
                    checkDispatch();
                  }}
                  disabled={tripMetaData[selectedTrip]?.status === 'pending'}
                  size="sm"
                  variant="primary"
                  style={{
                    marginRight: 20,
                  }}
                >
                  <FontAwesomeIcon icon={faTruckMoving} />
                  &nbsp;&nbsp; {t('Confirm')}
                </Button>
              ) : (
                <Button
                  onClick={handleUpdateVehicleNumber}
                  size="sm"
                  style={{
                    marginRight: 20,
                  }}
                  variant="primary"
                >
                  <FontAwesomeIcon icon={faTruck} />
                  &nbsp;&nbsp; {t('Update Vehicle Number')}
                </Button>
              )}

              <ButtonGroup>
                <OverlayTrigger overlay={returnTooltipTitle('Sheet')}>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                      if (tripMetaData[selectedTrip]?.status === 'pending') {
                        window.open(
                          `${window.location.origin}/trips/${objectId}/print`,
                          '_blank'
                        );
                      }
                    }}
                  >
                    {t('Print Sheet')}
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={returnTooltipTitle('EWB')}>
                  {tripMetaData[selectedTrip]?.status === 'temporary' ? (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => {
                        if (
                          tripMetaData[selectedTrip]?.status === 'temporary'
                        ) {
                          window.open(
                            `${window.location.origin}/trips/${objectId}/print`,
                            '_blank'
                          );
                        }
                      }}
                    >
                      {t('Print EWB')}
                    </Button>
                  ) : (
                    <EWayBillComponent isTemporary />
                  )}
                </OverlayTrigger>
                <Button
                  onClick={handleUpdateVehicleNumber}
                  size="sm"
                  variant="outline-primary"
                >
                  <FontAwesomeIcon icon={faEdit} />
                  &nbsp;&nbsp; {t('Vehicle Number')}
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <Button
              onClick={() => {
                deleteEmptyTripHandleModal();
              }}
              size="sm"
              variant="danger"
              style={{
                marginRight: 20,
              }}
            >
              <FontAwesomeIcon icon={faTruckMoving} />
              &nbsp;&nbsp; {t('Delete')}
            </Button>
          )}
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                value={serialNumberToScanValue}
                onChange={(event) => {
                  setSerialNumberToScanValue(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.nativeEvent.code === 'Enter') {
                    handleItemScan(event.target.value);
                    setSerialNumberToScanValue('');
                  }
                }}
                type="text"
                placeholder="Enter Serial No to Scan"
              />
            </InputGroup>
          </Col>
          <Col xs={8} md={6} lg={3} xl={4}>
            <ScanInvoiceNumber
              showLabel={false}
              hideBottomMargin={true}
              tripMetaData={tripMetaData[selectedTrip]}
              handleParcelInsertInTemporary={handleParcelInsertInTemporary}
              isTemporaryPage={true}
            />
          </Col>
          <Col xs={4} md={4} lg={3} xl={3}>
            <UploadDisplayTempoSheet
              textTitle="Drop File"
              tripMetaData={tripMetaData[selectedTrip]}
              buttonText={'Upload Display'}
              handleParcelInsertInTemporary={handleParcelInsertInTemporary}
              isTemporaryPage={true}
            />
          </Col>
        </Row>
      </div>
      <TemporaryTripParcels
        onRowSelect={onRowSelect}
        onBulkUpdateApply={onBulkUpdateApply}
        bulkUpdateEnabled={bulkUpdateEnabled}
        onTableSelectAll={onTableSelectAll}
        data={currentTripData}
        removeItemFromList={handleRemoveItemFromList}
      />
      <ConfirmationModal
        showModal={showUpdateTemposheetWarningModal}
        onConfirm={async () => {
          if (Object.keys(vehicleList)?.length < 1) {
            await handleGetVehiclesListForThisZone();
          }
          setShowUpdateTemposheetWarningModal(false);
          setShowUpdateVehicleNumberModal(true);
        }}
        closeModal={() => {
          setShowUpdateTemposheetWarningModal(false);
        }}
        modalBody={
          'Please go ahead and update the vehicle number first before scanning items.'
        }
        modalTitle={'Update Vehicle Number'}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleOnConfirmClick}
        closeModal={handleDeleteModalClose}
        modalBody={'Are you sure you want to remove'}
        modalTitle={'Remove Orders'}
      />
      <ConfirmationModal
        showModal={showDeleteEmptyTripModal}
        onConfirm={handleDeleteEmptyTrip}
        closeModal={handleDeleteEmptyTripModalClose}
        modalBody={'Are you sure you want to delete this trip'}
        modalTitle={'Delete Trip'}
      />
      <TemporaryDispatchModal
        showModal={showDispatchModal}
        closeModal={handleTemporaryDispatchModalShow}
        getAllVehiclesFromSystem={handleGetAllVehiclesFromSystem}
        getAllLoadersInSystem={handleGetAllLoadersInSystem}
      />
      <UpdateVehicleNumberModal
        showModal={showUpdateVehicleNumberModal}
        getAllVehiclesFromSystem={handleGetAllVehiclesFromSystem}
        handleCloseModal={() => {
          setShowUpdateVehicleNumberModal(false);
        }}
      />
    </>
  );
};
export default TemporaryTripDetails;
