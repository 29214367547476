import React, { useEffect, useState } from 'react';
import { returnUserReadableDateTime } from '../../../../utils/datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faBox,
  faCancel,
  faCheck,
  faCheckDouble,
  faCircleCheck,
  faCircleXmark,
  faCross,
  faExchange,
  faFileInvoice,
  faHandHolding,
  faHourglassStart,
  faMoneyBillTransfer,
  faMoneyCheckDollar,
  faPeopleCarry,
  faStore,
  faTentArrowTurnLeft,
  faTimesCircle,
  faTruckFast,
  faTruckRampBox,
  faUserPlus,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';

const Timeline = ({ events = [], rejectedHistory, authorizationLog = [] }) => {
  const [finalSteps, setFinalSteps] = useState([]);
  const actionLog = [...authorizationLog, ...events];

  const historyObj = {
    Assigned: { title: 'Assigned', icon: faUserPlus },
    'Payment Authorization': {
      title: 'Payment Authorization',
      icon: faMoneyCheckDollar,
    },
    Accepted: { title: 'Accepted', icon: faCheck },
    'Awaiting Invoice': { title: 'Awaiting Invoice', icon: faHourglassStart },
    Invoiced: { title: 'Invoiced', icon: faFileInvoice },
    'Ready For Pickup': { title: 'Ready For Pickup', icon: faBox },
    'Picked Up': { title: 'Picked Up', icon: faTruckFast },
    'Out For Delivery': { title: 'Out For Delivery', icon: faPeopleCarry },
    Delivered: { title: 'Delivered', icon: faTruckRampBox },
    Rejected: { title: 'Rejected', icon: faTimesCircle },
    'Auto Rejected': { title: 'Auto Rejected', icon: faBan },
    Requested: { title: 'Requested', icon: faTentArrowTurnLeft },
    Accepted: { title: 'Accepted', icon: faCheck },
    'Picked Up': { title: 'Picked Up', icon: faTruckFast },
    Received: { title: 'Received', icon: faStore },
    'QC Passed': { title: 'QC Passed', icon: faCircleCheck },
    'Refund Initiated': {
      title: 'Refund Initiated',
      icon: faMoneyBillTransfer,
    },
    Refunded: { title: 'Refunded', icon: faCheckDouble },
    Cancelled: { title: 'Cancelled', icon: faXmarkCircle },
  };

  const getHistoryArray = (rejectedHistory) => {
    const show = [];
    rejectedHistory.map((each) => {
      if (historyObj[each?.action]) {
        show.push(historyObj[each?.action]);
      } else {
        show.push({ title: each?.action, icon: faExchange });
      }
    });

    return show;
  };

  const flowDefinitions = {
    default: [
      { title: 'Payment Authorization', icon: faMoneyCheckDollar },
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Accepted', icon: faCheck },
      { title: 'Awaiting Invoice', icon: faHourglassStart },
      { title: 'Invoiced', icon: faFileInvoice },
      { title: 'Ready For Pickup', icon: faBox },
      { title: 'Picked Up', icon: faTruckFast },
      { title: 'Out For Delivery', icon: faPeopleCarry },
      { title: 'Delivered', icon: faTruckRampBox },
    ],
    rejected: [
      { title: 'Payment Authorization', icon: faMoneyCheckDollar },
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Rejected', icon: faTimesCircle },
    ],
    acceptedRejected: [
      { title: 'Payment Authorization', icon: faMoneyCheckDollar },
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Accepted', icon: faCheck },
      { title: 'Rejected', icon: faTimesCircle },
    ],
    autoRejected: [
      { title: 'Payment Authorization', icon: faMoneyCheckDollar },
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Auto Rejected', icon: faBan },
    ],
    qcFailed: [
      { title: 'Payment Authorization', icon: faMoneyCheckDollar },
      { title: 'Assigned', icon: faUserPlus },
      { title: 'Accepted', icon: faCheck },
      { title: 'QC Failed', icon: faCircleXmark },
    ],
    returns: [
      { title: 'Payment Authorization', icon: faMoneyCheckDollar },
      { title: 'Requested', icon: faTentArrowTurnLeft },
      { title: 'Accepted', icon: faCheck },
      { title: 'Picked Up', icon: faTruckFast },
      { title: 'Received', icon: faStore },
      { title: 'QC Passed', icon: faCircleCheck },
      { title: 'Refund Initiated', icon: faMoneyBillTransfer },
      { title: 'Refunded', icon: faCheckDouble },
    ],
  };

  const determineFlow = (actionLog) => {
    if (actionLog?.some((event) => event?.action === 'Auto Rejected')) {
      return 'autoRejected';
    }
    if (actionLog?.some((event) => event.action === 'Rejected')) {
      if (actionLog?.some((event) => event.action === 'Accepted')) {
        return 'acceptedRejected';
      }
      return 'rejected';
    }
    if (actionLog?.some((event) => event.action === 'QC Failed')) {
      return 'qcFailed';
    }
    if (actionLog?.some((event) => event.action === 'Requested')) {
      return 'returns';
    }
    return 'default';
  };

  useEffect(() => {
    let currentFlow = determineFlow(actionLog);

    const arrayList = rejectedHistory
      ? getHistoryArray(actionLog)
      : flowDefinitions[currentFlow];

    const steps = arrayList.map((step) => ({
      ...step,
      date: '',
      completed: rejectedHistory ? true : false,
      user: null,
      remarks: null,
      storeCode : null
    }));

    for (let i = 0; i < actionLog?.length; i++) {
      const currentAction = actionLog[i];
      const stepIndex = rejectedHistory
        ? i
        : steps.findIndex((step) => step.title === currentAction.action);
      if (stepIndex !== -1) {
        steps[stepIndex].date = currentAction?.time?.iso;
        steps[stepIndex].completed = true;
        steps[stepIndex].user = currentAction?.user;
        steps[stepIndex].remarks = currentAction?.remarks;
        steps[stepIndex].reason = currentAction?.reason;
        steps[stepIndex].storeCode = currentAction?.storeCode
        if (currentAction?.icon) {
          steps[stepIndex].icon = currentAction?.icon;
        }
      }
    }

    setFinalSteps(steps);
  }, [events, authorizationLog]);

  const remarksArray = [
    'Requested',
    'Rejected',
    'QC Failed',
    'Cancelled',
    'Refunded',
  ];

  const renderToolTip = (props, user, remarks,storeCode, title,reason) => (
    <Tooltip id="button-tooltip" {...props}>
      User: {user ? user : 'System'} <br />
      {remarks && <>Remarks: {remarks ? remarks : ''} <br /></> }
      {reason && <>Reason: {reason ? reason : ''} <br /></> }
      {storeCode && <>StoreCode: {storeCode ? storeCode : ''}<br /></>}
    </Tooltip>
  );
  return (
    <div
      className="my-2"
      style={{
        display: 'flex',
        overflowX: 'scroll',
        alignItems: 'center',
      }}
    >
      {finalSteps?.map((step, index) => (
        <React.Fragment key={index}>
          <div
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '5rem',
              // marginTop: step?.completed ? '40px' : '10px',
              textAlign: 'center',
              fontSize: 'clamp(10px,12px,12px)',
              color: step?.completed ? 'black' : 'lightgray',
            }}
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) =>
                renderToolTip(props, step?.user, step?.remarks,step?.storeCode, step?.title,step?.reason)
              }
            >
              <div className="fs-4">
                <FontAwesomeIcon icon={step?.icon} />
              </div>
            </OverlayTrigger>
            <div className="fw-medium px-2 text-center">{step?.title}</div>
            <div style={{ fontSize: 'clamp(10px,10px,10px)' }}>
              {step?.date &&
                returnUserReadableDateTime(step?.date).split(',')[0]}
            </div>
            <div style={{ fontSize: 'clamp(10px,10px,10px)' }}>
              {step?.date &&
                returnUserReadableDateTime(step?.date).split(',')[2]}
            </div>
          </div>
          {index < finalSteps.length - 1 && (
            <div
              style={{
                height: '2px',
                width: '4.5rem',
                backgroundColor:
                  step?.completed && finalSteps[index + 1]?.completed
                    ? 'black'
                    : 'lightgray',
                // marginTop: event?.completed ? "-40px" : "-50px",
                // alignSelf: "center",
              }}
            ></div>
          )}
        </React.Fragment>
      ))}
      <style>{`::-webkit-scrollbar { display: none; }`}</style>
    </div>
  );
};

export default Timeline;
