import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Breadcrumb,
  Button,
  ButtonGroup,
} from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import {
  setCurrentConfirmedTripParcels,
  setCurrentTripParcels,
} from '../../actions/trips';
import AchievementModal from '../../components/common/achievementModal';
import ConfirmationModal from '../../components/common/confirmationModal';
import {
  closeCurrentTrip,
  dispatchTemporaryTrip,
  getTripDetailsByTripNumberFromServer,
  removeParcelFromSheet,
} from '../../parse-functions/trips';
import { Routes } from '../../router/routes';
import { convertArrayToObj } from '../../utils/json';
import ActiveTripParcels from './activeTripParcels';
import EWayBillComponent from '../../components/ewaybill';
import { getTripBadgeStatus } from '../../utils/trips';
import { returnUserReadableDate } from '../../utils/datetime';
import { orderStatusMap } from '../../utils/constants';
import ExcelDownloadReport from '../../components/common/excel/download';

const ConfirmedTripsDetails = () => {
  const { objectId, tripNumber } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [bulkUpdateEnabled, setBulkUpdateEnabled] = useState(false);
  const [currentActiveItem, setCurrentActiveItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAchievementModal, setShowAchievementModal] = useState(false);
  const [showDispatchConfirmationModal, setShowDispatchConfirmationModal] =
    useState(false);
  const warehouseCode = useSelector(
    (state) => state?.user?.warehouse?.warehouseCode || ''
  );
  const selectedTrip = useSelector(
    (state) => state.trips.selectedTrip || tripNumber
  );
  const confirmedTrips = useSelector(
    (state) => state.trips.confirmedTrips || {}
  );

  useEffect(() => {
    if (selectedTrip) {
      getTripDetails();
    } else {
      navigate(Routes.Trips.ConfirmedTrips.path);
    }
  }, []);

  const getTripDetails = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const tripDetails = await getTripDetailsByTripNumberFromServer(
        selectedTrip,
        true
      );
      dispatch(
        setCurrentConfirmedTripParcels(
          convertArrayToObj(tripDetails, 'objectId')
        )
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  };
  const handleRemoveItemFromList = (parcelObjectId) => {
    setCurrentActiveItem(parcelObjectId);
    setShowDeleteModal(true);
  };
  const handleOnConfirmClick = async () => {
    dispatch(updateSpinnerState(true));
    try {
      await removeParcelFromSheet(currentActiveItem);
      const parcelsCopy = JSON.parse(
        JSON.stringify(confirmedTrips[selectedTrip]?.parcels)
      );
      delete parcelsCopy[currentActiveItem[0]];
      dispatch(setCurrentTripParcels(parcelsCopy));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Item Removed',
          message: 'Parcel Removed from trip successfully',
        })
      );
      dispatch(updateSpinnerState(false));
      setCurrentActiveItem(null);
      setShowDeleteModal(false);
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Removal Failed',
          message: e.message,
        })
      );
      dispatch(updateSpinnerState(false));
      setCurrentActiveItem(null);
      setShowDeleteModal(false);
    }
  };

  const onTableSelectAll = (value) => {
    const tripData = Object.assign([], confirmedTrips[selectedTrip]?.parcels);
    Object.values(tripData).forEach((data) => (data.isSelected = value));
    setBulkUpdateEnabled(value);
    dispatch(setCurrentTripParcels(tripData));
  };
  const onBulkUpdateApply = (action) => {
    console.log('bulk update action');
    console.log(action);
  };
  const onRowSelect = (value, objectId) => {
    const tripData = Object.assign([], confirmedTrips[selectedTrip]?.parcels);
    Object.values(tripData).forEach((data) => {
      if (data.objectId === objectId) {
        data.isSelected = value;
      }
    });
    dispatch(setCurrentTripParcels(tripData, 'objectId'));
    const someSelected = Object.values(tripData)?.filter(
      (item) => item.isSelected === true
    );
    setBulkUpdateEnabled(someSelected?.length > 0);
  };
  const handleDeleteModalClose = () => {
    setCurrentActiveItem(null);
    setShowDeleteModal(false);
  };

  const handleCloseAchievementModal = () => {
    setShowAchievementModal(false);
    navigate(Routes.Trips.ConfirmedTrips.path);
  };

  const handleTripDispatchConfirmation = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const { objectId } = confirmedTrips[selectedTrip];
      await dispatchTemporaryTrip({ objectId, status: 'pending' });
      dispatch(updateSpinnerState(false));
      setShowAchievementModal(true);
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Trip Closure Failed'),
          message: t(e.message),
        })
      );
      dispatch(updateSpinnerState(false));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Trips')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('Active')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{`${selectedTrip} (${confirmedTrips[selectedTrip]?.vehicleNumber})`}</h4>
          <p className="mb-0">
            <span
              style={{
                marginRight: 12,
              }}
            >
              {confirmedTrips[selectedTrip]?.createdBy && (
                <>
                  Created By:{' '}
                  <span className="fw-bold">
                    {confirmedTrips[selectedTrip].createdBy}
                  </span>
                  ,
                </>
              )}
            </span>
            <span
              style={{
                marginRight: 12,
              }}
            >
              {confirmedTrips[selectedTrip]?.createdAt && (
                <>
                  Created At:{' '}
                  <span className="fw-bold">
                    {returnUserReadableDate(
                      confirmedTrips[selectedTrip].createdAt
                    )}
                  </span>
                  ,
                </>
              )}
            </span>
            <span
              style={{
                marginRight: 12,
              }}
            >
              {confirmedTrips[selectedTrip]?.status && (
                <>
                  <span>
                    &nbsp;&nbsp;
                    <Badge
                      bg={getTripBadgeStatus(
                        confirmedTrips[selectedTrip].status
                      )}
                      className="badge-lg"
                    >
                      {confirmedTrips[selectedTrip].status}
                    </Badge>{' '}
                    &nbsp;&nbsp;,
                  </span>
                </>
              )}
            </span>
            <span
              style={{
                marginRight: 12,
              }}
            >
              {confirmedTrips[selectedTrip]?.dispatchData?.vehicleDetails
                ?.vehicleType && (
                <>
                  Vehicle Type:{' '}
                  <span className="fw-bold">
                    {
                      confirmedTrips[selectedTrip]?.dispatchData?.vehicleDetails
                        ?.vehicleType
                    }
                  </span>
                </>
              )}
            </span>
          </p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0 gap-2">
          <ExcelDownloadReport
            data={
              confirmedTrips[selectedTrip]?.parcels
                ? Object.values(confirmedTrips[selectedTrip]?.parcels)
                : []
            }
            fileName={'TripDetails'}
            className="btn-export"
          />
          <Button
            variant="secondary"
            onClick={() => setShowDispatchConfirmationModal(true)}
          >
            {t('Dispatch')}
          </Button>
          <ButtonGroup>
            <EWayBillComponent />
          </ButtonGroup>
        </div>
      </div>
      <ActiveTripParcels
        onRowSelect={onRowSelect}
        onBulkUpdateApply={onBulkUpdateApply}
        bulkUpdateEnabled={bulkUpdateEnabled}
        onTableSelectAll={onTableSelectAll}
        data={
          confirmedTrips[selectedTrip]?.parcels
            ? Object.values(confirmedTrips[selectedTrip]?.parcels)
            : []
        }
        removeItemFromList={handleRemoveItemFromList}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleOnConfirmClick}
        closeModal={handleDeleteModalClose}
        modalBody={'Are you sure you want to remove'}
        modalTitle={'Remove Orders'}
      />
      <ConfirmationModal
        showModal={showDispatchConfirmationModal}
        onConfirm={handleTripDispatchConfirmation}
        closeModal={() => setShowDispatchConfirmationModal(false)}
        modalBody={
          'Are you sure you want to dispatch this trip?(Cannot delete parcels later.)'
        }
        modalTitle={'Dispatch Trip'}
      />
      <AchievementModal
        show={showAchievementModal}
        handleClose={handleCloseAchievementModal}
        buttonText={t('Go to trips')}
        topTitle={t('Your trip has successfully been dispatched')}
        mainHeaderText={t('Trip Dispatched')}
        subText={t('Please navigate to dispatched trips to print temposheet!')}
      />
    </>
  );
};
export default ConfirmedTripsDetails;
