import { RESET_STORE, SET_ALL_WAREHOUSES_IN_STORE } from '../actions/types';

const INITIAL_STATE = {
  warehouseList: [],
};
const allWarehouseList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALL_WAREHOUSES_IN_STORE: {
      return {
        ...state,
        warehouseList: action.data,
      };
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default allWarehouseList;
