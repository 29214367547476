import React from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';

const InterCityOrders = () => {
  const bulkActionOptions = [
    {
      label: 'Invoiced',
      value: 'invoiced',
    },
    {
      label: 'On Hold',
      value: 'On Hold',
    },
    {
      label: 'Cancel',
      value: 'cancelOrder',
    },
    {
      label: 'Assign',
      value: 'assign',
    },
    {
      label: 'Out Of Stock',
      value: 'Out Of Stock',
    },
  ];

  return (
    <div>
      <OrderPage
        type={"Inter City"}
        bulkActionOptions={bulkActionOptions}
        isInterCity={true}
      />
    </div>
  );
};

export default InterCityOrders;
