import React, { useEffect,useState } from "react";
import { Modal } from "@themesberg/react-bootstrap";
import ImageGallery from "react-image-gallery";
import { getImagesFromParcelId } from "../../../parse-functions/images";
import { useDispatch } from "react-redux";
import { updateSpinnerState } from "../../../actions/spinner";

const PODImageSlider = (props) => {
    const {
        show,
        handleClose,
        images=[],
        description = "",objectId
    } = props;
    const [imagesArray,setImagesArray] = useState(images)
    const [typeImage,setTypeImage] = useState([])
    const dispatch = useDispatch()

    const handleCloseClick = () => {

        setTypeImage([])
        handleClose()

    }

    const fetchPodImage = async (objectId) => 
    {
        try{
            dispatch(updateSpinnerState(true))
            let imagesRes = await getImagesFromParcelId({objectId})
            let imgArray = []
            if(imagesRes?.length > 0)
            {
         imgArray =  imagesRes?.map((img) => ({ original: img, thumbnail: img, description }));
            }else{
         imgArray =  images?.map((img) => ({ original: img, thumbnail: img, description }));
            }
      setTypeImage(imgArray)
      dispatch(updateSpinnerState(false))
            
        }catch(error)
        {
      dispatch(updateSpinnerState(false))

     const imgArray =  images?.map((img) => ({ original: img, thumbnail: img, description }));
     setTypeImage(imgArray)


            console.log(error)
        }
    }
    useEffect((each) => {
        if(imagesArray?.length == 0)
        {
        fetchPodImage(objectId)
        }
    },[images,imagesArray])



    

    return (
        <Modal show={show} onHide={handleCloseClick} className="modal-tertiary pod-images-modal">
            <ImageGallery items={typeImage} />
        </Modal>
    )
}
export default PODImageSlider