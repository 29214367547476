import {
  faFileExport,
  faHome,
  faPlus,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Button } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  setActiveTripsListInStore,
  setOpenTripsListInStore,
  setSelectedTripInStore,
} from '../../actions/trips';
import { getOpenTripsFromServer } from '../../parse-functions/trips';
import { convertArrayToObj } from '../../utils/json';
import TripsTableData from './tripsTableData';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import { setFiltersInStore } from '../../actions/filters';
import { returnEndOfDay, returnStartOfDay } from '../../utils/datetime';
import { returnActiveTripColumns } from './tripsUtils';
import { returnActiveTripsDataToDownload } from '../../utils/trips';
import ExcelDownloadReport from '../../components/common/excel/download';
import TripFilters from './tripFilters';

const PendingCloseTrips = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageType = 'openTrips';
  const navigate = useNavigate();
  const isOpen = useSelector((state) => state.trips.openTrips);
  const activeTrips = useSelector((state) => {
    if (isOpen) {
      return state.trips.activeTrips || {};
    } else {
      return {};
    }
  });
  const defaultFilter = useSelector((state) => state.filters?.[pageType]);
  const storeInfo = useSelector((state) => state.user.warehouse || {});
  const [dataToDownload, setDataToDownload] = useState([]);
  const [showFilterOptions, setShowFilterOptions] = useState(true);
  const isSuperAdmin = useSelector(
    (state) => state.user.preferences.isSuperAdmin || false
  );

  const handleRowClick = (tripNumber, objectId) => {
    dispatch(setSelectedTripInStore(tripNumber));
    navigate(`/trip/${objectId}?isOpen=true`);
  };

  const handlePrintClick = (event, objectId) => {
    event.stopPropagation();
    window.open(`${window.location.origin}/trips/${objectId}/print`, '_blank');
  };

  const getAllTrips = async (requestProps) => {
    dispatch(updateSpinnerState(true));
    try {
      const tempTrips = await getOpenTripsFromServer({
        ...requestProps,
        warehouseCode: !isSuperAdmin
          ? storeInfo.warehouseCode
          : requestProps.warehouseCode,
      });
      const tripsObject = convertArrayToObj(tempTrips, 'tripNumber');
      dispatch(setOpenTripsListInStore(true));
      dispatch(setActiveTripsListInStore(tripsObject));
      const data = returnActiveTripsDataToDownload(tripsObject);
      setDataToDownload(data);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('All trips fetched'),
          message: t(
            'These are all the active trips currently from your warehouse'
          ),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error in getting active Trips'),
          message: e.message,
        })
      );
    }
  };

  const handleGetTrips = (filtersValue) => {
    dispatch(
      setFiltersInStore({
        pageType,
        data: filtersValue,
      })
    );
    getAllTrips({
      startDate: filtersValue?.startDate,
      endDate: filtersValue?.endDate,
      warehouseCode: filtersValue?.storeName?.[0],
      zone: filtersValue?.region?.[0],
      isSuperAdmin,
    });
  };

  useEffect(() => {
    try {
      if (Object.keys(activeTrips).length === 0 && !isSuperAdmin) {
        getAllTrips();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Trips')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('List')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t('Open Temposheets')}</h4>
          <p className="mb-0">
            {t('All the Open trips of your warehouse are listed here.')}
          </p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0 gap-2">
          <Button
            onClick={() => {
              setShowFilterOptions((prev) => !prev);
            }}
            variant="outline-primary"
            size="sm"
          >
            <FontAwesomeIcon icon={faRefresh} />
            &nbsp;&nbsp;{t('Filter')}
          </Button>
          <ExcelDownloadReport
            data={dataToDownload || []}
            fileName={`Open-Trips.csv`}
          />
        </div>
      </div>
      {showFilterOptions && (
        <TripFilters
          onSubmit={handleGetTrips}
          defaultFilter={defaultFilter}
          isAdmin={isSuperAdmin}
        />
      )}

      <TripsTableData
        columns={returnActiveTripColumns(handlePrintClick)}
        data={Object.values(activeTrips)}
        onRowClick={handleRowClick}
      />
    </>
  );
};
export default PendingCloseTrips;
