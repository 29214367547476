import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import { getAllSchemes } from '../../../../parse-functions/sms/scheme';
import SchemeInfoModal from '../Scheme/SchemeInfoModal';
import { infoTable } from '../../../../utils/sms/infoTable';
import { useNavigate } from 'react-router-dom';
import { setFiltersInStore } from '../../../../actions/filters';
import BulkAddSchemeModal from '../BulkAddSchemeModal';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
import { Button, ButtonGroup, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faRefresh } from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../../../components/common/excel/download';
import { setSchemesDataInStore } from '../../../../actions/sms/schemes';
import DatePicker from '../../../../components/common/DatePicker';
import {
  schemeTableRedesignColumns,
  ViewProductsTable,
} from '../../../../utils/sms/schemeTableRedesign';
import TableOnly from '../IndividualScheme/TableOnly';
import { exportSchemeDataSummary } from '../../../../utils/sms/exportSchemeData';

const CancelledSchemes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );

  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );

  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );
  const filtersValue = useSelector(
    (state) => state.filters?.['cancelledSchemes']
  );
  const [schemeData, setSchemeData] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoData, setInfoData] = useState();
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [id, setId] = useState();
  const [date, setDate] = useState(
    filtersValue || {
      startDate: new Date(),
      endDate: new Date(),
    }
  );
  const schemes = useSelector((state) => state.schemes || {});
  const [error, setError] = useState('');

  const role = isAdmin
    ? 'isAdmin'
    : isBrandManager
    ? 'isBrandManager'
    : isBrandVendor
    ? 'isBrandVendor'
    : isAccountManager
    ? 'isAccountManager'
    : 'Unknown';

  const fetchCancelledSchemes = async () => {
    const response = await getAllSchemes({
      status: ['cancelled'],
      role: role,
      startDate: date?.startDate,
      endDate: date?.endDate,
    });
    setSchemeData(response);
    dispatch(setSchemesDataInStore({ type: 'cancelled', data: response }));
  };

  const handleFiltersSubmit = async () => {
    if (date.startDate > date.endDate) {
      setError('Cannot be greater than End Date');
      return;
    }
    dispatch(updateSpinnerState(true));
    try {
      await fetchCancelledSchemes();
      dispatch(
        setFiltersInStore({
          pageType: 'cancelledSchemes',
          data: {
            startDate: date.startDate,
            endDate: date.endDate,
          },
        })
      );
      setError('');
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          message: t('Schemes fetched successfully!'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };

  const onRefreshClick = async () => {
    dispatch(updateSpinnerState(true));
    try {
      await fetchCancelledSchemes();
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Refreshed Schemes!',
          message: 'Schemes fetched successfully!',
        })
      );
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Error fetching Schemes!',
          message: error.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleSelectedRowUpdate = (actionId, row) => {
    switch (actionId) {
      case 'View': {
        navigate(`/scheme/${row.objectId}/details`);
        break;
      }
      case 'Action Timeline': {
        setInfoData(row.actionLog);
        setShowInfoModal(true);
        break;
      }
      case 'Details': {
        navigate(`/scheme/schemeanalysis/${row.objectId}`);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleMoreProductsClick = (id) => {
    setId(id);
    setShowProductsModal(true);
  };

  useState(() => {
    if (schemes.cancelled.length > 0) {
      setSchemeData(schemes.cancelled);
    }
  }, [schemes.cancelled]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbTitle breadcrumbs={[{ title: 'Cancelled Schemes' }]} />
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup className="gap-3">
            <Button
              onClick={onRefreshClick}
              variant="white"
              className="btn-export "
              size="md"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;&nbsp;{t('Refresh')}
            </Button>
            <ExcelDownloadReport
              data={exportSchemeDataSummary(schemeData) || []}
              fileName={`Cancelled Schemes`}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex gap-5 mb-3 ">
        <div className="d-flex gap-3 align-items-start">
          <Form.Group style={{ width: '400px' }}>
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              value={date?.startDate}
              setValue={(date) =>
                setDate((prev) => ({
                  ...prev,
                  startDate: date,
                }))
              }
              errorMessage={error}
              isInvalid={error}
            />
          </Form.Group>
          <Form.Group style={{ width: '400px' }}>
            <Form.Label>End Date</Form.Label>
            <DatePicker
              value={date?.endDate}
              setValue={(date) =>
                setDate((prev) => ({
                  ...prev,
                  endDate: date,
                }))
              }
            />
          </Form.Group>
        </div>
        <div
          className="d-flex"
          style={{ alignItems: error ? 'center' : 'end' }}
        >
          <Button
            variant="white"
            size="md"
            onClick={handleFiltersSubmit}
            style={{ height: '50px' }}
          >
            <FontAwesomeIcon icon={faFilter} /> &nbsp; &nbsp;Filter
          </Button>
        </div>
      </div>
      <TableOnly
        data={schemeData || []}
        columns={schemeTableRedesignColumns({
          handleMoreProductsClick,
          options: ['Action Timeline', 'View', 'Details'],
          handleSelectedRowUpdate,
        })}
        id={'cancelledSchemesTable'}
      />
      <SchemeInfoModal
        showModal={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        data={infoData}
        columns={infoTable()}
      />
      {showProductsModal && (
        <BulkAddSchemeModal
          data={
            schemeData?.filter((item) => item.objectId === id)[0]?.products ||
            []
          }
          showModal={showProductsModal}
          closeModal={() => setShowProductsModal(false)}
          columns={ViewProductsTable(
            schemeData?.filter((item) => item.objectId === id)[0]
          )}
        />
      )}
    </>
  );
};
export default CancelledSchemes;
