import React, { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger,Tooltip } from "@themesberg/react-bootstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";
import QRCode from 'react-qr-code'; // Ensure you have this installed via npm
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";


const ImageUploadModal = ({ show, handleClose , objectId ,uploadUpdate}) => {
  const [images, setImages] = useState([]);
  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const isUploadOnly = searchParams.get("upload") === "true";
  const newObjectId = objectId || searchParams.get("objectId")

  const compressImage = (file, maxWidth = 800, maxHeight = 800, quality = 0.8) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, { type: "image/jpeg", lastModified: Date.now() }));
            },
            "image/jpeg",
            quality
          );
        };
      };
    });
  };
  const handleInterClose = () => {

    setImages([])
    handleClose()
  }
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    // const compressedImages = await Promise.all(files.map((file) => compressImage(file)));
    setImages([...images, ...files]);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    // const compressedImages = await Promise.all(files.map((file) => compressImage(file)));
    setImages([...images, ...files]);
  };

  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (images.length === 0) return;
    setLoading(true);
    const uploaded = [];

    try {
      const formData = new FormData();
      images.forEach((image) => {
        formData.append("files", image);
      });

      const response = await axios.post(`${process.env.REACT_APP_BASE_BACKEND_URL}/upload?objectId=${newObjectId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      
      // uploaded.push(...response.data.uploadedUrls);
      if(uploadUpdate)
      {
      uploadUpdate(response.data.uploadedUrls,newObjectId)
      }
      handleClose()
    } catch (error) {
      console.error("Upload failed", error);
    }

    setUploadedUrls([...uploadedUrls, ...uploaded]);
    setLoading(false);
    setImages([]);
  };
  return (
    <Modal show={show} onHide={handleClose} centered size="md" className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Upload File</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div
          className="upload-box rounded d-flex flex-column align-items-center justify-content-center p-4"
          style={{ border: "2px dashed #ccc", cursor: "pointer", background: "#f8f9fa", minHeight: "180px", borderRadius: "12px" }}
          onClick={() => document.getElementById('fileInput').click()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <FontAwesomeIcon icon={faUpload} color="gray" size="2x" style={{ opacity: "0.50" }} />
          <p className="mb-1" style={{ color: "gray", opacity: "0.50", fontSize: "14px" }}>Drag & drop your file here</p>
          <b>or</b>
          <Button variant="primary" className="mt-2">Browse</Button>
        </div>
        <input
          id="fileInput"
          type="file"
          multiple
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
        
        {images.length > 0 && (
          <div className="selected-images mt-3 text-center">
            <h5 className="fw-bold">Selected Images:</h5>
            <div className="d-flex flex-wrap justify-content-center">
              {images.map((img, idx) => (
                <div key={idx} className="image-container position-relative m-2">
                  <img
                    src={URL.createObjectURL(img)}
                    alt="preview"
                    className="border rounded shadow-sm preview-img"
                    style={{ width: 80, height: 80, objectFit: "cover", borderRadius: "8px" }}
                  />
                  <button
                    className="position-absolute top-0 start-100 translate-middle btn btn-sm btn-danger rounded-circle close-btn"
                    onClick={() => removeImage(idx)}
                    style={{ width: 20, height: 20, fontSize: "12px",display:"flex",alignItems:"center",justifyContent:"center" }}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {!isUploadOnly && images.length === 0 && (
          <div className="qr-section text-center mt-4 p-3" style={{ background: "#f9f9f9", borderRadius: "12px" }}>
            <QRCode value={`${window.location.origin}/trips/upload/image?upload=true&objectId=${newObjectId}`} size={100} />
            <p className="mt-2 text-muted" style={{ fontSize: "14px" }}>Scan to upload via mobile</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>

      <Button
            variant="link"
            className="text-gray"
            style={{
              padding: '10px 0',
              width: '30%',
              fontSize: '14px',
              fontWeight: '500',
              backgroundColor: '#F0F0F0',
              borderRadius: '4px',
            }}
            onClick={handleInterClose}
          >
            Cancel
          </Button>
          <Button
            variant="dark"
            style={{
                padding: '10px 0',
                width: '30%',
                fontSize: '14px',
                fontWeight: '500',
                color: '#fff',
                backgroundColor: loading || images?.length == 0  ? '#ccc' : '#444444', // Gray background when inactive
                borderRadius: '4px',
              cursor: loading || images?.length == 0 ? 'not-allowed' : 'pointer',
            }}
            onClick={handleUpload}
            disabled={loading || images?.length == 0}
          >
             {loading ? "Uploading..." : "Upload Now"}
          </Button>
      </Modal.Footer>
    </Modal>
  );
};



const UploadTrigger = ({objectId , uploadUpdate}) => {
  const [showModal, setShowModal] = useState(false);
  const [searchParams] = useSearchParams();
  const openFromQuery = searchParams.get("upload") === "true";

  useEffect(() => {
    if (openFromQuery) {
      setShowModal(true);
    }
  }, [openFromQuery]);

  return (
     <div className="d-flex align-items-center justify-content-center">

{!showModal && openFromQuery ? (
        <p className="text-center text-danger fw-bold">Please go back to dashboard or scan the QR again</p>
      ) : (
        <OverlayTrigger placement="top" overlay={<Tooltip>Add More Images</Tooltip>}>
        <Button variant="outline-info" onClick={() => setShowModal(true)} className="rounded-circle d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </OverlayTrigger>
      )}
     
      <ImageUploadModal show={showModal} uploadUpdate={uploadUpdate} objectId={objectId} handleClose={() => setShowModal(false)} />
      </div>
  );
};

export default UploadTrigger;




















































// const ImageUploadModal = () => {
//   const [show, setShow] = useState(false);
//   const [images, setImages] = useState([]);
//   const [uploadedUrls, setUploadedUrls] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [qrVisible, setQrVisible] = useState(false);
//   const location = useLocation();

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     if (params.get("uploadMobile")) {
//       setShow(true);
//     }
//   }, [location]);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const handleQrShow = () => setQrVisible(true);
//   const handleQrClose = () => setQrVisible(false);

//   const handleImageChange = (e) => {
//     setImages([...images, ...Array.from(e.target.files)]);
//   };

//   const removeImage = (index) => {
//     setImages(images.filter((_, idx) => idx !== index));
//   };

//   const handleUpload = async () => {
//     if (images.length === 0) return;
//     setLoading(true);
//     const uploaded = [];

//     for (const image of images) {
//       const formData = new FormData();
//       formData.append("file", image);

//       try {
//         const response = await axios.post("https://your-wasabi-upload-endpoint.com/upload", formData, {
//           headers: { "Content-Type": "multipart/form-data" },
//         });
//         uploaded.push(response.data.url);
//       } catch (error) {
//         console.error("Upload failed", error);
//       }
//     }
    
//     setUploadedUrls([...uploadedUrls, ...uploaded]);
//     setLoading(false);
//     setImages([]);
//   };

//   return (
//     <>
//       <div className="d-flex flex-column align-items-center mt-4 gap-3">
//         <Button 
//           variant="primary" 
//           onClick={handleShow} 
//           className="px-4 py-2 fw-bold" 
//           style={{ borderRadius: "30px", boxShadow: "0px 4px 12px rgba(0,0,0,0.1)" }}
//         >
//           Upload Here
//         </Button>
//         <Button 
//           variant="outline-secondary" 
//           onClick={handleQrShow} 
//           className="px-4 py-2 fw-bold" 
//           style={{ borderRadius: "30px", boxShadow: "0px 4px 12px rgba(0,0,0,0.1)" }}
//         >
//           Upload via Mobile
//         </Button>
//       </div>

//       {qrVisible && (
//         <div className="text-center mt-4 p-3 border rounded" style={{ backgroundColor: "#fff", boxShadow: "0px 4px 12px rgba(0,0,0,0.1)" }}>
//           <h5 className="fw-bold mb-3">Scan QR to Upload via Mobile</h5>
//           <QRCode value={`${window.location.origin}/upload?uploadMobile=true`} size={200} fgColor="#007bff" />
//           <div className="mt-3">
//             <Button variant="danger" onClick={handleQrClose} style={{ borderRadius: "20px" }}>
//               Close
//             </Button>
//           </div>
//         </div>
//       )}

//       <Modal show={show} onHide={handleClose} centered size="lg">
//         <Modal.Header closeButton style={{ borderBottom: "none", paddingBottom: 0 }}>
//           <Modal.Title className="fw-bold" style={{ fontSize: "1.5rem" }}>Upload Images</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="text-center">
//           <div className="d-flex flex-wrap justify-content-center gap-3">
//             {images.map((img, idx) => (
//               <div
//                 key={idx}
//                 className="position-relative"
//                 onClick={() => setSelectedImage(URL.createObjectURL(img))}
//                 style={{ cursor: "pointer" }}
//               >
//                 <img
//                   src={URL.createObjectURL(img)}
//                   alt="preview"
//                   className="border rounded shadow-sm"
//                   style={{ width: 100, height: 100, objectFit: "cover" }}
//                 />
//                 <button
//                   className="btn btn-danger btn-sm position-absolute top-0 end-0"
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     removeImage(idx);
//                   }}
//                   style={{ borderRadius: "50%", width: 24, height: 24, lineHeight: "14px" }}
//                 >
//                   &times;
//                 </button>
//               </div>
//             ))}
//             <div
//               className="border rounded shadow-sm d-flex align-items-center justify-content-center"
//               style={{ width: 100, height: 100, background: "#f8f9fa", cursor: "pointer", fontSize: "32px", fontWeight: "bold", color: "#ccc" }}
//               onClick={() => document.getElementById("fileInput").click()}
//             >
//               +
//             </div>
//           </div>
//           <input
//             id="fileInput"
//             type="file"
//             multiple
//             accept="image/*"
//             style={{ display: "none" }}
//             onChange={handleImageChange}
//           />
//           {selectedImage && (
//             <div className="text-center mt-4 position-relative">
//               <img 
//                 src={selectedImage} 
//                 alt="Selected preview" 
//                 className="border rounded shadow-lg" 
//                 style={{ width: "100%", maxWidth: 400, height: "auto", objectFit: "cover" }} 
//               />
//               <button
//                 className="btn btn-danger position-absolute top-0 end-0"
//                 onClick={() => setSelectedImage(null)}
//                 style={{ borderRadius: "50%", width: 30, height: 30, lineHeight: "16px" }}
//               >
//                 &times;
//               </button>
//             </div>
//           )}
//         </Modal.Body>
//         <Modal.Footer style={{ borderTop: "none", paddingTop: 0 }}>
//           <Button variant="secondary" onClick={handleClose} className="fw-bold px-3" style={{ borderRadius: "20px" }}>
//             Close
//           </Button>
//           <Button variant="success" onClick={handleUpload} disabled={loading} className="fw-bold px-3" style={{ borderRadius: "20px" }}>
//             {loading ? "Uploading..." : "Upload"}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {uploadedUrls.length > 0 && (
//         <div className="mt-4 text-center">
//           <h5 className="fw-bold">Uploaded Images:</h5>
//           <div className="d-flex flex-wrap justify-content-center gap-3 mt-3">
//             {uploadedUrls.map((url, idx) => (
//               <img
//                 key={idx}
//                 src={url}
//                 alt="uploaded"
//                 className="border rounded shadow-sm"
//                 style={{ width: 100, height: 100, objectFit: "cover" }}
//               />
//             ))}
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default ImageUploadModal;
