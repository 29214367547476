import * as XLSX from 'xlsx';

const exportJsonToExcel = (data, columns, fileName) => {
  // Validate inputs
  if (!data || !columns || !Array.isArray(columns)) {
    console.error('Invalid data or column structure.');
    return;
  }

  // Map JSON data to specified columns and keys
  const formattedData = data.map((item) => {
    const row = {};
    columns.forEach(({ field, key }) => {
      let value = item[key];
      
      // Handle object values by converting them to JSON strings
      if (typeof value === 'object' && value !== null) {
        value = JSON.stringify(value);
      }
      
      row[field] = value !== undefined ? value : ''; // Match the key and assign to the field name
    });
    return row;
  });

  // Create a worksheet
  const worksheet = XLSX.utils.json_to_sheet(formattedData);

  // Create a workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Save the workbook to an Excel file
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export default exportJsonToExcel;