import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../../actions/spinner';
import { getSchemeById } from '../../../../parse-functions/sms/scheme';
import { useParams } from 'react-router-dom';
import FormComponent from '../FormComponent';
import BulkAddSchemeModal from '../BulkAddSchemeModal';
import { bulkAddSchemeColumns } from '../../../../utils/sms/BulkAddSchemeColumns';
import { useTranslation } from 'react-i18next';
import BreadCrumbComponent from '../../../../components/common/Breadcrumb';
import { Routes } from '../../../../router/routes';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
import { ViewProductsTable } from '../../../../utils/sms/schemeTableRedesign';
import { Card } from '@themesberg/react-bootstrap';
import { setSchemesDataInStore } from '../../../../actions/sms/schemes';
import { updateToastInfo } from '../../../../actions/settings';

const ViewSchemeDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [schemeData, setSchemeData] = useState({});
  const [showProductsModal, setShowProductsModal] = useState(false);
  const schemes = useSelector((state) => state.schemes || {});

  let breadcrumbs = [];
  switch (schemeData.status) {
    case 'ongoing':
    case 'completed':
      breadcrumbs = arrayToBreadCrumbs([
        [t('Active Schemes'), Routes.SMS.Target.RedesignActiveSchemes.path],
        [t('Scheme Information')],
      ]);
      break;
    case 'closed':
      breadcrumbs = arrayToBreadCrumbs([
        [t('Closed Schemes'), Routes.SMS.Target.RedesignClosedSchemes.path],
        [t('Scheme Information')],
      ]);
      break;
    case 'cancelled':
    case 'rejected':
      breadcrumbs = arrayToBreadCrumbs([
        [t('Rejected Schemes'), Routes.SMS.Target.RedesignRejectedSchemes.path],
        [t('Scheme Information')],
      ]);
      break;
    case 'draft':
      breadcrumbs = arrayToBreadCrumbs([
        [t('Draft Schemes'), Routes.SMS.Target.RedesignDraftSchemes.path],
        [t('Scheme Information')],
      ]);
      break;
    default:
      break;
  }

  const schema = [
    [
      {
        title: 'Brand',
        isMultiSelect: false,
        type: 'select',
        id: 'company',
        options: [],
        disabled: true,
      },
      {
        title: 'Scheme Circular No.',
        id: 'schemeCircularNo',
        type: 'text',
        disabled: true,
      },
      {
        title: 'Scheme Circular Date',
        id: 'schemeCircularDate',
        type: 'date',
        disabled: true,
      },
      {
        title: 'Regions',
        id: 'regions',
        type: 'select',
        isMultiSelect: true,
        options: [],
        disabled: true,
      },
      {
        title: 'Valid From',
        id: 'validFrom',
        type: 'date',
        disabled: true,
      },
      {
        title: 'Valid Till',
        id: 'validTill',
        type: 'date',
        disabled: true,
      },
    ],
    [
      {
        title: 'Scheme Type',
        id: 'type',
        type: 'select',
        options: [],
        disabled: true,
      },
      {
        title: 'Category',
        id: 'productCategory',
        type: 'select',
        options: [],
        disabled: true,
        isMultiSelect: true,
      },
      {
        title: 'Sub Category-1',
        id: 'subCategory',
        type: 'select',
        options: [],
        disabled: true,
        isMultiSelect: true,
      },
      {
        title: 'Sub Category-2',
        id: 'subCategory2',
        type: 'select',
        options: [],
        disabled: true,
        isMultiSelect: true,
      },
      {
        title: 'Sub Category-3',
        id: 'subCategory3',
        type: 'select',
        options: [],
        disabled: true,
        isMultiSelect: true,
      },
      {
        title: 'View Products',
        type: 'button',
      },
    ],
  ];

  if (schemeData?.type?.[0] === 'Sell-in') {
    schema[1].splice(1, 0, {
      title: 'Sell In Type',
      id: 'sellIntype',
      type: 'select',
      options: [],
      required: false,
      disabled: true,
    });
  }

  const saveFunction = () => {
    setShowProductsModal(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const schemeDataResult = await getSchemeById({ id });
        schemeDataResult.category = schemeDataResult.productCategory;
        setSchemeData(schemeDataResult);
        dispatch(
          setSchemesDataInStore({ type: 'view', data: schemeDataResult })
        );
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            tile: '',
            message: 'Error while fetching scheme details.',
          })
        );
      } finally {
        dispatch(updateSpinnerState(false));
      }
    };
    if (schemes.view?.objectId !== id) {
      fetchData();
    } else {
      setSchemeData(schemes.view);
    }
  }, [schemes.view]);
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbTitle breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <Card className="p-2">
        <h5 className="m-0">Scheme Details</h5>
      </Card>
      <Card className="mt-2 p-2">
        {Object.keys(schemeData).length > 0 && (
          <FormComponent
            schema={schema}
            editData={schemeData}
            savefunction={saveFunction}
          />
        )}
      </Card>
      {showProductsModal && (
        <BulkAddSchemeModal
          data={schemeData?.products || []}
          showModal={showProductsModal}
          closeModal={() => setShowProductsModal(false)}
          columns={ViewProductsTable(schemeData)}
        />
      )}
    </div>
  );
};

export default ViewSchemeDetails;
