import React, { useState } from 'react';
import { Button, Modal } from '@themesberg/react-bootstrap';
import NewTable from '../../components/common/NewTable';
import {
  getMonthStartEnd,
  shortenTripsString,
  tripsViewColumns,
} from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTripInfoByObjectId } from '../../parse-functions/trips';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import TripSaveModal from './TripSaveModal';
import { useTranslation } from 'react-i18next';
import TripEditModal from './TripEditModal';
import ConfirmationModal from '../../components/common/confirmationModal';
import { exportPerDeliverySummary } from '../../utils/excel';
import { getPaymentsReport } from '../../parse-functions/reports';

const TripViewModal = ({
  show,
  handleClose,
  tripsData,
  handleSaveClick,
  extraData,
  formInput,
  isExport,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTrip, setSelectedTrip] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [saveData, setSaveData] = useState({});
  const vendorsList = useSelector((state) => state?.vendors?.vendorsList || {});
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isWHPaymentUser = useSelector(
    (state) => state?.user?.preferences?.isWHPaymentUser || false
  );
  const storeCode = useSelector(
    (state) => state?.user?.warehouse?.warehouseCode || ''
  );

  const handleEditClick = async (tripId, type, totalAmount) => {
    try {
      if (type === 'half') {
        setShowConfirmModal(true);
        setSaveData({ tripId, half: true, totalAmount });
        return;
      }
      dispatch(updateSpinnerState(true));
      const tripDetails = await getTripInfoByObjectId(tripId, true);
      setSelectedTrip({
        trip: tripDetails.tripInfo,
        parcels: tripDetails.parcels,
      });
      setShowEditModal(true);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch data!'),
          message: `${error.message}`,
        })
      );
    }
  };

  const handleSubmitClick = (data) => {
    setSaveData(data);
    setShowSaveModal(true);
  };

  const onTripViewClick = (tripId) => {
    window.open(`${window.location.origin}/tripdetails/${tripId}`, '_blank');
  };

  const handleSummaryExport = async () => {
    let reportData;
    try {
      dispatch(updateSpinnerState(true));
      reportData = await getPaymentsReport({
        startDate: formInput.startDate,
        endDate: formInput.endDate,
        vehicleNumber: extraData.vehicleDetails.vehicleNumber,
        filterType: 'Month',
        storeName: isAdmin || isWHPaymentUser ? formInput.storeName : storeCode,
      });
      reportData = reportData[0];
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch data!'),
          message: `${error.message}`,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
    const totalAmount = {
      totalgtAmount: 0,
      totalCustQty: 0,
      totalgtQuantity: 0,
      totalCustAmount: 0,
      totalCustProduct: 0,
      totalgtProduct: 0,
    };
    const groupedByDate = reportData?.trips
      ?.filter((item) => item.status !== 'pending')
      ?.reduce((acc, trip) => {
        const date = trip.date;
        if (
          ['fixedPerDelivery', 'extraPerDelivery'].includes(trip.paymentMode) &&
          trip.totalAmount <= 0
        ) {
          return acc;
        }

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(trip);

        return acc;
      }, {});
    const data = Object.entries(groupedByDate).map(([date, trips]) => {
      const result = {
        date,
        gtQuantity: 0,
        gtAmount: 0,
        extraCharges: 0,
        custQty: 0,
        totalQty: 0,
        custAmount: 0,
        custProduct: 0,
        gtProduct: 0,
        totalAmount: 0,
        tripNumbers: [],
      };
      trips.forEach((trip) => {
        if (trip.exportData) {
          const exportData = trip.exportData;
          result.gtQuantity += exportData.gt?.quantity || 0;
          result.gtAmount += exportData.gt?.amount || 0;
          result.gtProduct += exportData.gt?.product || 0;
          result.totalQty += exportData.gt?.quantity || 0;
          totalAmount.totalgtAmount += exportData.gt?.amount || 0;
          totalAmount.totalgtQuantity += exportData.gt?.quantity || 0;
          totalAmount.totalgtProduct += exportData.gt?.product || 0;
          result.custQty += exportData.custQty;
          result.custAmount += exportData.custAmount;
          result.custProduct += exportData.custProduct;
          result.totalQty += exportData.custQty;
          result.totalAmount +=
            (trip.totalAmount || 0) + (trip.voucherAmount || 0);
          totalAmount.totalCustQty += exportData.custQty;
          totalAmount.totalCustAmount += exportData.custAmount;
          totalAmount.totalCustProduct += exportData.custProduct || 0;
        } else {
          result.custQty += trip.totalDeliveryAttempted || 0;
          result.custProduct += trip.totalDeliveryQuantity || 0;
          result.gtQuantity +=
            (trip.totalDisplay || 0) + (trip.droppedAtHub || 0);
          result.gtProduct +=
            (trip.totalDisplayProducts || 0) + (trip.droppedAtHubProducts || 0);
          result.totalQty +=
            (trip.totalDeliveryAttempted || 0) +
            (trip.totalDisplay || 0) +
            (trip.droppedAtHub || 0);
          result.totalAmount +=
            (trip.totalAmount || 0) + (trip.voucherAmount || 0);
          totalAmount.totalgtAmount += trip.totalAmount || 0;
          totalAmount.totalCustQty += trip.totalDeliveryAttempted || 0;
          totalAmount.totalgtQuantity +=
            (trip.totalDisplay || 0) + (trip.droppedAtHub || 0);
          totalAmount.totalCustProduct += trip.totalDeliveryQuantity || 0;
          totalAmount.totalgtProduct +=
            (trip.totalDisplayProducts || 0) + (trip.droppedAtHubProducts || 0);
        }
        result.extraCharges += trip.voucherAmount || 0;
        result.tripNumbers.push(trip.tripNumber);
      });

      const output = {
        DATE: result.date,
        TEMPOSHEETS: shortenTripsString(result.tripNumbers),
        'CUST DEL': result.custQty,
        'CUST DEL PRODUCT QTY': result.custProduct,
        'CUST DEL AMOUNT': result.custAmount,
        'BR DISPLAY QTY': result.gtQuantity,
        'BR DISPLAY PRODUCT QTY': result.gtProduct,
        'BR DISPLAY AMOUNT': result.gtAmount,
        'EXTRA CHARGES': result.extraCharges,
        'TOTAL QTY': result.totalQty,
        'SHEET AMT': result.totalAmount,
      };
      if (
        ['monthlyFixed', 'extraVehicle'].includes(
          extraData.vehicleDetails.paymentMode
        )
      ) {
        delete output['CUST DEL AMOUNT'];
        delete output['BR DISPLAY AMOUNT'];
      }
      return output;
    });
    const finalRow = {
      TEMPOSHEETS: 'TOTAL',
      'CUST DEL': totalAmount.totalCustQty,
      'BR DISPLAY QTY': totalAmount.totalgtQuantity,
      'CUST DEL PRODUCT QTY': totalAmount.totalCustProduct,
      'BR DISPLAY PRODUCT QTY': totalAmount.totalgtProduct,
      'EXTRA CHARGES': reportData?.voucherAmount,
      'TOTAL QTY': totalAmount.totalCustQty + totalAmount.totalgtQuantity,
      'SHEET AMT': reportData?.totalAmount?.toFixed(2),
    };
    if (
      !['monthlyFixed', 'extraVehicle'].includes(
        extraData.vehicleDetails.paymentMode
      )
    ) {
      finalRow['CUST DEL AMOUNT'] = totalAmount.totalCustAmount;
      finalRow['BR DISPLAY AMOUNT'] = totalAmount.totalgtAmount.toFixed(2);
    }
    data.push(finalRow);
    const details = {
      month: extraData.month,
      branchName: extraData?.vehicleDetails?.storeName,
      startDate: getMonthStartEnd(extraData.month).startDate,
      endDate: getMonthStartEnd(extraData.month).endDate,
      vendorName: extraData?.vehicleDetails?.vendorName,
      present: extraData.totalPresent,
      absent: extraData.totalDays - extraData.totalPresent,
      vehicleNumber: extraData?.vehicleDetails?.vehicleNumber,
      paymentMode: extraData?.vehicleDetails?.paymentMode,
      paymentAmount: extraData?.vehicleDetails?.paymentAmount,
      vehicleType: extraData?.vehicleDetails?.vehicleType,
      deliveryAverage: (finalRow['TOTAL QTY'] / extraData.totalPresent).toFixed(
        2
      ),
      deliveryAmountAverage: (
        finalRow['SHEET AMT'] / finalRow['TOTAL QTY']
      ).toFixed(2),
      userName,
    };
    const fileName = `${extraData?.vehicleDetails?.vehicleNumber}_summary`;
    exportPerDeliverySummary(data, details, fileName);
  };

  return (
    <>
      <Modal show={show} centered onHide={handleClose} size="xl">
        <Modal.Header>
          <Modal.Title>
            <h5>Total Trips: {tripsData?.length}</h5>
          </Modal.Title>
          {isExport && (
            <Button variant="primary" onClick={handleSummaryExport}>
              Export Summary
            </Button>
          )}
        </Modal.Header>
        <Modal.Body>
          <NewTable
            id={'viewTrips'}
            data={tripsData}
            columns={tripsViewColumns({
              handleEditClick,
              handleSaveClick,
              onTripViewClick,
              isAdmin: isAdmin || isWHPaymentUser,
            })}
          />
        </Modal.Body>
      </Modal>
      {selectedTrip && (
        <TripEditModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          tripDetails={selectedTrip}
          onSubmit={handleSubmitClick}
          handleSaveClick={handleSaveClick}
        />
      )}
      {Object.keys(saveData).length > 0 && (
        <TripSaveModal
          show={showSaveModal}
          handleClose={() => setShowSaveModal(false)}
          saveData={saveData}
          setSaveData={setSaveData}
          tripDetails={selectedTrip}
          onSubmit={handleSaveClick}
          vendorsList={vendorsList}
          setShowEditModal={setShowEditModal}
        />
      )}
      {
        <ConfirmationModal
          showModal={showConfirmModal}
          closeModal={() => setShowConfirmModal(false)}
          modalBody={`Are you sure to mark it as half day? New Amount:${saveData.totalAmount}`}
          modalTitle={'Mark as Half Day'}
          onConfirm={async () => {
            await handleSaveClick(saveData);
            setShowConfirmModal(false);
          }}
        />
      }
    </>
  );
};

export default TripViewModal;
