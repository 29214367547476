import React, { useState } from "react";
import sideimage from "../../../assets/img/Image.png";
import logoImage from "../../../assets/img/brand/vs-logo.png";
import { useNavigate } from "react-router-dom";

const LoyalityLogin = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
      setError(false);
    }
  };

  const handleSubmit = () => {
    if (mobileNumber.length !== 10) {
      setError(true);
    } else {
      navigate("/rewards/verify", { state: { mobileNumber } });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div
      className="d-flex shadow-lg"
      style={{
        width: "100VW",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#fff"
      }}
    >
      <div
        className="d-flex flex-column justify-content-between overflow-auto"
        style={{ width: "100%", minWidth: "50%", flex: "1", zIndex: 2 ,boxSizing:"border-box"}}
      >
      <div
        className="w-100 h-5 bg-white d-flex justify-content-start p-4 py-5 align-items-center"
        style={{ position: "absolute", top: 0, height: "5vh" ,zIndex:3 }}
      >
        <img
          src={logoImage}
          alt="Logo"
          className="img-fluid"
          style={{ maxWidth: "160px" }}
        />
      </div>
        <div style={{height:"35%"}}></div>
        {/* Main Content */}
        <div className="p-4" style={{ width: "100%", height: "90%"}}>
          <h3 className="fw-bold mb-3">Login</h3>

          {/* Input Field */}
          <label
            style={{
              fontSize: "14px",
              fontWeight: "500",
              marginBottom: "5px",
            }}
          >
            Enter Mobile Number
          </label>
          <br />
          <br />
          <input
            type="text"
            placeholder="Enter a valid Mobile Number"
            value={mobileNumber}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            style={{
              width: "100%",
              padding: "12px",
              border: `1px solid ${error ? "red" : "#ccc"}`,
              borderRadius: "6px",
              fontSize: "16px",
              outline: "none",
              marginBottom: "10px",
              maxWidth: "700px",
            }}
          />
          {error && (
            <p style={{ color: "red", fontSize: "14px" }}>
              Please enter a valid 10-digit mobile number.
            </p>
          )}
          <br />
          <br />
          {/* Button */}
          <button
            style={{
              width: "100%",
              backgroundColor: "#000",
              color: "#fff",
              padding: "14px",
              fontSize: "16px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "6px",
              cursor: "pointer",
              maxWidth: "700px",
            }}
            onClick={handleSubmit}
          >
            Login
          </button>
        </div>
        <div style={{height:"10%"}}></div>

      </div>

      {/* Right Section - Image */}
      <img
        src={sideimage}
        alt="Promo"
        className="d-none d-lg-flex"
        style={{
          width: "50%",
          height: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "12px",
          padding: "20px",zIndex:99
        }}
      />
    </div>
  );
};

export default LoyalityLogin;
