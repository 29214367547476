import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import EditableDropDown from '../../../../components/common/EditableDropdown';
import { ErrorMessage } from '../StyledComponents';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../../../actions/spinner';
import { updateToastInfo } from '../../../../actions/settings';
import {
  addSchemeProduct,
  getAllCompaniesCategoriesSubCategoriesData,
} from '../../../../parse-functions/sms/scheme';
import { addProductValidate } from '../../validate/schemeValidate';

const AddProductsinScheme = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState({
    companyOptions: [],
    categoryOptions: [],
    subCategoryOptions: [],
  });
  const [formInput, setFormInput] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { t } = useTranslation();

  const handleAdd = async (type) => {
    try {
      dispatch(updateSpinnerState(true));
      const valid = addProductValidate(formInput, setFormErrors, type);
      if (!valid) {
        dispatch(updateSpinnerState(false));
        return;
      }
      await addSchemeProduct({ formInput, type });
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('Add Success.'),
        })
      );
      if (type === 'addCompany') {
        setOptions((prev) => ({
          ...prev,
          companyOptions: [
            ...prev.companyOptions,
            {
              label: formInput.newCompanyName,
              value: formInput.newCompanyName,
            },
          ],
        }));
      }
      if (type === 'addCategory') {
        setOptions((prev) => ({
          ...prev,
          categoryOptions: [
            ...prev.categoryOptions,
            {
              label: formInput.newCategoryName,
              value: formInput.newCategoryName,
            },
          ],
        }));
      }
      setFormErrors({});
    } catch (error) {
      if (error.message === 'Van No already exists') {
        setFormErrors({ vanNo: 'Already exists' });
      }
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t(''),
          message: t('Adding Failed.'),
        })
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const data = await getAllCompaniesCategoriesSubCategoriesData();
        setOptions({
          companyOptions: data.companies.map((item) => ({
            label: item.name,
            value: item.name,
          })),
          categoryOptions: data.categories.map((item) => ({
            label: item.name,
            value: item.name,
          })),
          subCategoryOptions: data.subCategories.map((item) => ({
            label: item.name,
            value: item.name,
          })),
        });
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t(''),
            message: t('Failed to fetch data.'),
          })
        );
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbTitle breadcrumbs={[{ title: 'Add New Product' }]} />
        </div>
      </div>
      <div>
        <Card className="p-2 mb-3">
          <h5>Add Product</h5>
        </Card>
        <div className="d-flex gap-5">
          <Card style={{ width: '65%', padding: '1rem 2rem' }}>
            <div className="d-flex gap-2 mt-4">
              <Form.Group style={{ width: '50%' }}>
                <Form.Label>Brand</Form.Label>
                <EditableDropDown
                  id={'company'}
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      company: [e.value],
                    }))
                  }
                  value={formInput['company']}
                  options={options.companyOptions}
                  placeholder={'Choose Brand'}
                  isInvalid={formErrors['company']}
                  backgroundColor={'#F0F0F0'}
                ></EditableDropDown>
                <ErrorMessage>{formErrors['company']}</ErrorMessage>
              </Form.Group>
              <Form.Group style={{ width: '50%' }}>
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type={'text'}
                  id={'productName'}
                  value={formInput['productName']}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      productName: value,
                    }));
                  }}
                  placeholder="Enter Product Name"
                  isInvalid={formErrors['productName']}
                  style={{ backgroundColor: '#F0F0F0' }}
                />
                <Form.Control.Feedback type="invalid">
                  {t(formErrors['productName'])}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="d-flex gap-2 mt-4">
              <Form.Group style={{ width: '50%' }}>
                <Form.Label>Category Name</Form.Label>
                <EditableDropDown
                  id={'category'}
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      category: [e.value],
                    }))
                  }
                  value={formInput['category']}
                  options={options.categoryOptions}
                  placeholder={'Choose Category Name'}
                  isInvalid={formErrors['category']}
                  backgroundColor={'#F0F0F0'}
                ></EditableDropDown>
                <ErrorMessage>{formErrors['category']}</ErrorMessage>
              </Form.Group>
              <Form.Group style={{ width: '50%' }}>
                <Form.Label>Enter VAN No.</Form.Label>
                <Form.Control
                  type={'text'}
                  id={'vanNo'}
                  value={formInput['vanNo']}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      vanNo: value,
                    }));
                  }}
                  placeholder="Enter Product Name"
                  isInvalid={formErrors['vanNo']}
                  style={{ backgroundColor: '#F0F0F0' }}
                />
                <Form.Control.Feedback type="invalid">
                  {t(formErrors['vanNo'])}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="d-flex gap-2 mt-4">
              <Form.Group style={{ width: '50%' }}>
                <Form.Label>Sub-Category Name</Form.Label>
                <EditableDropDown
                  id={'subCategory'}
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      subCategory: [e.value],
                    }))
                  }
                  value={formInput['subCategory']}
                  options={options.subCategoryOptions}
                  placeholder={'Choose Sub-Category Name'}
                  isInvalid={formErrors['subCategory']}
                  backgroundColor={'#F0F0F0'}
                ></EditableDropDown>
                <ErrorMessage>{formErrors['subCategory']}</ErrorMessage>
              </Form.Group>
            </div>
            <div className="d-flex justify-content-end gap-3">
              <Button onClick={() => handleAdd('addProduct')}>
                Create Product
              </Button>
            </div>
          </Card>
          <div style={{ width: '30%' }}>
            <Card className="d-flex flex-row  gap-2 align-items-center p-2">
              <FontAwesomeIcon icon={faAdd} />
              <h5 className="m-0">Add Brand</h5>
            </Card>
            <Card className="p-2">
              <Form.Group>
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  type={'text'}
                  id={'newCompanyName'}
                  value={formInput['newCompanyName']}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      newCompanyName: value,
                    }));
                  }}
                  placeholder="Enter Brand"
                  isInvalid={formErrors['newCompanyName']}
                  style={{ backgroundColor: '#F0F0F0' }}
                />
                <Form.Control.Feedback type="invalid">
                  {t(formErrors['newCompanyName'])}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="d-flex justify-content-end mt-2">
                <Button
                  variant="primary"
                  style={{ height: '40px', width: '118px' }}
                  onClick={() => handleAdd('addCompany')}
                >
                  Add
                </Button>
              </div>
            </Card>
            <Card className="d-flex flex-row gap-2 align-items-center p-2">
              <FontAwesomeIcon icon={faAdd} />
              <h5 className="m-0">Add Category</h5>
            </Card>
            <Card className="p-2">
              <Form.Group>
                <Form.Label>Brand</Form.Label>
                <EditableDropDown
                  id={'companyName'}
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      companyName: [e.value],
                    }))
                  }
                  value={formInput['companyName']}
                  options={options.companyOptions}
                  placeholder={'Choose Brand'}
                  isInvalid={formErrors['companyName']}
                  backgroundColor={'#F0F0F0'}
                ></EditableDropDown>
                <ErrorMessage>{formErrors['companyName']}</ErrorMessage>
              </Form.Group>
              <Form.Group>
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type={'text'}
                  id={'newCategoryName'}
                  value={formInput['newCategoryName']}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormInput((prev) => ({
                      ...prev,
                      newCategoryName: value,
                    }));
                  }}
                  placeholder="Enter Category Name"
                  isInvalid={formErrors['newCategoryName']}
                  style={{ backgroundColor: '#F0F0F0' }}
                />
                <Form.Control.Feedback type="invalid">
                  {t(formErrors['newCategoryName'])}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="d-flex justify-content-end mt-2">
                <Button
                  variant="primary"
                  style={{ height: '40px', width: '118px' }}
                  onClick={() => handleAdd('addCategory')}
                >
                  Add
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProductsinScheme;
