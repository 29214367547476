export const SET_VEHICLE_TYPES_IN_STORE = 'SET_VEHICLE_TYPES_IN_STORE';
export const SET_ACTIVE_VEHICLE_ID = 'SET_ACTIVE_VEHICLE_ID';
export const ADD_ORDERS_FOR_VEHICLE = 'ADD_ORDERS_FOR_VEHICLE';
export const UPDATE_TOAST_INFO = 'UPDATE_TOAST_INFO';

export const UPDATE_SPINNER_STATE = 'UPDATE_SPINNER_STATE';

export const SET_CURRENT_USER_IN_STATE = 'SET_CURRENT_USER_IN_STATE';
export const SET_USER_PREFERENCES_IN_STORE = 'SET_USER_PREFERENCES_IN_STORE';
export const SET_CURRENT_USER_INFO_IN_STATE = 'SET_CURRENT_USER_INFO_IN_STATE';
export const SET_DMS_USERS_LIST_IN_STORE = 'SET_DMS_USERS_LIST_IN_STORE';
export const SET_DMS_USER_LIST_AS_SELECTED = 'SET_DMS_USER_LIST_AS_SELECTED';
export const SET_APPLICATION_CONFIG_IN_STORE =
  'SET_APPLICATION_CONFIG_IN_STORE';
export const SET_CURRENT_WAREHOUSE_INFO_IN_STORE =
  'SET_CURRENT_WAREHOUSE_INFO_IN_STORE';
export const MARK_TRIP_AS_CREATED = 'MARK_TRIP_AS_CREATED';
export const MARK_ORDER_AS_SELECTED = 'MARK_ORDER_AS_SELECTED';
export const REMOVE_SELECTED_ORDERS_FROM_VEHICLE =
  'REMOVE_SELECTED_ORDERS_FROM_VEHICLE';

export const SET_TEMPORARY_TRIPS_IN_STORE = 'SET_TEMPORARY_TRIPS_IN_STORE';
export const SET_SELECTED_TRIP_IN_STORE = 'SET_SELECTED_TRIP_IN_STORE';
export const SET_TEMPORARY_TRIP_AS_DISPATCHED =
  'SET_TEMPORARY_TRIP_AS_DISPATCHED';
export const REMOVE_SELECTED_TRIP_FROM_TEMPORARY_LIST =
  'REMOVE_SELECTED_TRIP_FROM_TEMPORARY_LIST';
export const SET_ACTIVE_TRIPS_IN_STORE = 'SET_ACTIVE_TRIPS_IN_STORE';
export const SET_CURRENT_ACTIVE_TRIP_ITEMS = 'SET_CURRENT_ACTIVE_TRIP_ITEMS';
export const SET_DATA_FOR_SELECTED_PARCELS_IN_ACTIVE_TRIP =
  'SET_DATA_FOR_SELECTED_PARCELS_IN_ACTIVE_TRIP';
export const SET_PARCELS_FOR_STAGES_IN_STORE =
  'SET_PARCELS_FOR_STAGES_IN_STORE';
export const UPDATE_CURRENT_TRIP_PARCELS_IN_STORE =
  'UPDATE_CURRENT_TRIP_PARCELS_IN_STORE';
export const MARK_PARCEL_AS_DISABLED_IN_STATE =
  'MARK_PARCEL_AS_DISABLED_IN_STATE';
export const SET_PARCEL_INFO_IN_STORE_FOR_STAGE =
  'SET_PARCEL_INFO_IN_STORE_FOR_STAGE';
export const SET_COMPANIES_AND_CATEGORIES_DATA_IN_STORE =
  'SET_COMPANIES_AND_CATEGORIES_DATA_IN_STORE';
export const SET_SUB_CATEGORIES_DATA_IN_STORE =
  'SET_SUB_CATEGORIES_DATA_IN_STORE';
export const SET_MATCHING_PRODUCTS_IN_STORE = 'SET_MATCHING_PRODUCTS_IN_STORE';
export const SET_VEHICLES_VENDORS_LIST_IN_STORE =
  'SET_VEHICLES_VENDORS_LIST_IN_STORE';
export const SET_DRIVERS_LIST_IN_STORE = 'SET_DRIVERS_LIST_IN_STORE';
export const SET_REQUISITIONS_IN_STORE = 'SET_REQUISITIONS_IN_STORE';
export const SET_SELECTED_REQUISITION_IN_STORE =
  'SET_SELECTED_REQUISITION_IN_STORE';
export const SET_FILTERS_IN_STORE = 'SET_FILTERS_IN_STORE';
export const SET_LOADERS_LIST_IN_STORE = 'SET_LOADERS_LIST_IN_STORE';
export const MARK_LOADER_AS_SELECTED = 'MARK_LOADER_AS_SELECTED';

export const UPDATE_VEHICLE_NUMBER_FOR_TEMPORARY_TRIP =
  'UPDATE_VEHICLE_NUMBER_FOR_TEMPORARY_TRIP';
export const SET_TEMPOSHEET_RECEIVE_PARCELS_IN_STORE =
  'SET_TEMPOSHEET_RECEIVE_PARCELS_IN_STORE';
export const SET_SELECTED_PARCEL_STATE_WHILE_RECEIVING =
  'SET_SELECTED_PARCEL_STATE_WHILE_RECEIVING';
export const SET_CURRENT_RECEIVE_TEMPOSHEET_PARCELS_AS_RECEIVED =
  'SET_CURRENT_RECEIVE_TEMPOSHEET_PARCELS_AS_RECEIVED';
export const ADD_BRANCH_BREAKUP_REQUISITION_DATA_IN_STORE =
  'ADD_BRANCH_BREAKUP_REQUISITION_DATA_IN_STORE';
export const MARK_VEHICLE_AS_SELECTED = 'MARK_VEHICLE_AS_SELECTED';
export const RESET_STORE = 'RESET_STORE';

export const SET_WMS_USERS_LIST_IN_STORE = 'SET_WMS_USERS_LIST_IN_STORE';
export const SET_WMS_USER_LIST_AS_SELECTED = 'SET_WMS_USER_LIST_AS_SELECTED';
export const SET_SELECTED_TARGETS_IN_STORE = 'SET_SELECTED_TARGETS_IN_STORE';
export const SET_TARGETS_LIST_IN_STORE = 'SET_TARGETS_LIST_IN_STORE';
export const UPDATE_WAREHOUSE_TARGET_ITEMS_IN_STORE =
  'UPDATE_WAREHOUSE_TARGET_ITEMS_IN_STORE';
export const MARK_TARGET_ITEMS_AS_CANCELLED = 'MARK_TARGET_ITEMS_AS_CANCELLED';
export const SET_EXCEL_PRODUCT_UPLOAD_DATA_IN_STORE =
  'SET_EXCEL_PRODUCT_UPLOAD_DATA_IN_STORE';
export const SET_EXCEL_MISMATCHED_PRODUCT_UPLOAD_DATA_IN_STORE =
  'SET_EXCEL_MISMATCHED_PRODUCT_UPLOAD_DATA_IN_STORE';
export const SET_SHOW_MISMATCH_PRODUCTS_MODAL =
  'SET_SHOW_MISMATCH_PRODUCTS_MODAL';
export const SET_CURRENT_USER_PERMISSIONS_IN_STORE =
  'SET_CURRENT_USER_PERMISSIONS_IN_STORE';
export const SET_PRODUCTSLIST_DROPDOWN_IN_STORE =
  'SET_PRODUCTSLIST_DROPDOWN_IN_STORE';
export const SET_STORES_IN_STORE = 'SET_STORES_IN_STORE';
export const SET_SCHEMES_DATA_IN_STORE = 'SET_SCHEMES_DATA_IN_STORE';
export const SET_PRODUCTSMAP_IN_STORE = 'SET_PRODUCTSMAP_IN_STORE';
export const SET_PRODUCT_COMPANIES_IN_STORE = 'SET_PRODUCT_COMPANIES_IN_STORE';
export const SET_PRODUCT_CATEGORIES_IN_STORE =
  'SET_PRODUCT_CATEGORIES_IN_STORE';
export const SET_PRODUCT_SUBCATEGORIES_IN_STORE =
  'SET_PRODUCT_SUBCATEGORIES_IN_STORE';
export const SET_PAGE_NUMBER_IN_STORE = 'SET_PAGE_NUMBER_IN_STORE';
export const SET_PRODUCT_BY_VANNO_IN_STORE = 'SET_PRODUCT_BY_VANNO_IN_STORE';
export const SET_FILEDATA_IN_STORE = 'SET_FILEDATA_IN_STORE';
export const RESET_FILEDATA_IN_STORE = 'RESET_FILEDATA_IN_STORE';
export const SET_OPEN_TRIPS_IN_STORE = `SET_OPEN_TRIPS_IN_STORE`;
export const SET_UNPAID_TRIPS_IN_STORE = 'SET_UNPAID_TRIPS_IN_STORE';
export const SET_INVOICED_TRIPS_IN_STORE = 'SET_INVOICED_TRIPS_IN_STORE';
export const SET_SCHEMES_OPTIONS_IN_STORE = 'SET_SCHEMES_OPTIONS_IN_STORE';
export const SET_ALL_WAREHOUSES_IN_STORE = 'SET_ALL_WAREHOUSES_IN_STORE';
export const SET_PENDING_RECEIVED_TRIPS_IN_STORE =
  'SET_PENDING_RECEIVED_TRIPS_IN_STORE';
export const SET_CONFIRMED_TRIPS_IN_STORE = 'SET_CONFIRMED_TRIPS_IN_STORE';
export const SET_CURRENT_CONFIRMED_TRIP_ITEMS =
  'SET_CURRENT_CONFIRMED_TRIP_ITEMS';
