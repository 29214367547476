import React, { useState } from 'react';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BreadCrumbComponent from '../../components/common/Breadcrumb';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import Table from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../router/routes';
import {
  customSizePerPage,
  customTotal,
} from '../../components/common/parcelStatusPages';
import EmptyTable from '../../components/common/EmptyTableView';
import {
  faArrowRotateLeft,
  faCheckCircle,
  faDisplay,
  faHourglass,
  faRefresh,
  faRoadCircleXmark,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import { getParcelStatusForDeliveryAnalysis } from '../../parse-functions/parcels';
import {
  deliveryAnalysisTableColumns,
  returnPercentage,
} from './analysisUtils';
import DateRangeSelector from '../../components/common/DateRangeSelector';
import { CounterWidget } from '../../components/Widgets';
import PODImageSlider from '../../components/common/images/podImagesSlider';
import { setFiltersInStore } from '../../actions/filters';
import {
  returnEndOfDay,
  returnStartOfDay,
  returnUserReadableDateTime,
} from '../../utils/datetime';
import { returnRowBGColor } from '../../utils/parcels';
import ExcelDownloadReport from '../../components/common/excel/download';
import { returnZoneDropdownOptions } from '../../utils/zone';
import EditableDropDown from '../../components/common/EditableDropdown';
import { returnWarehouseDropdownOptions } from '../../utils/warehouse';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';

const DeliveryAnalysisComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageType = 'deliveryAnalysis';
  const [podImages, setShowPODImages] = useState([]);
  const [podImageDescription, setPODImageDescription] = useState('');
  const [showPODImagesModal, setShowPODImagesModal] = useState(false);
  const warehouse = useSelector((state) => state?.user?.warehouse || {});
  const [parcels, setParcels] = useState([]);
  const [parcelInfo, setParcelInfo] = useState({});
  const filtersValue = useSelector((state) => state.filters?.[pageType]);
  const [totalParcels, setTotalParcels] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const zones = useSelector((state) => state?.user?.config?.zones || {});
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );

  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const [formInput, setFormInput] = useState({
    region: filtersValue?.region,
    branch: filtersValue?.branch,
  });
  const breadCrumbItems = [
    {
      title: t('Analysis'),
      link: Routes.Analysis.DeliveryData.path,
    },
    {
      title: t('Delivery Data'),
      link: '',
    },
  ];

  const onDateSelected = (startDate, endDate) => {
    dispatch(
      setFiltersInStore({
        pageType,
        data: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          region: formInput.region,
          storeName: formInput.storeName,
        },
      })
    );
    getAvailableParcels(startDate, endDate);
  };
  const handleParcelSearch = () => {
    const currentStartDate = filtersValue.startDate
      ? new Date(filtersValue.startDate)
      : returnStartOfDay(new Date());
    const currentEndDate = filtersValue.endDate
      ? new Date(filtersValue.endDate)
      : returnEndOfDay(new Date());
    getAvailableParcels(currentStartDate, currentEndDate);
  };
  const getAvailableParcels = async (startDate, endDate) => {
    dispatch(updateSpinnerState(true));
    try {
      const filters = {
        storeCode: isAdmin ? formInput.storeName?.[0] : warehouse.warehouseCode,
        zone: isAdmin ? formInput.region?.[0] : warehouse.zone,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        isAdmin,
      };
      const response = await getParcelStatusForDeliveryAnalysis(filters);
      let allParcels = [];
      // eslint-disable-next-line no-unused-expressions
      Object.values(response.parcelStatus)?.forEach((parcelCategory) => {
        allParcels = allParcels.concat(parcelCategory);
      });
      setTotalParcels(allParcels?.length);
      setParcels(allParcels);
      setParcelInfo(response.parcelStatus);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Orders fetched'),
          message: t('You can check the orders for each type and status below'),
        })
      );
      setSelectedCategory(null);
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Fetching Orders failed'),
          message: t(e.message),
        })
      );
      dispatch(updateSpinnerState(false));
    }
  };
  const handleShowPODImagesModal = (images, parcelUpdateLocation) => {
    setShowPODImages(images);
    if (parcelUpdateLocation?.time) {
      setPODImageDescription(
        `Image taken at: ${returnUserReadableDateTime(
          parcelUpdateLocation?.time
        )}`
      );
    }
    setShowPODImagesModal(true);
  };
  const handleCardClickForOrders = (categorySelected) => {
    setSelectedCategory(categorySelected);
    setParcels(parcelInfo[categorySelected] || []);
  };
  return (
    <div>
      <ToolkitProvider
        exportCSV
        keyField="id"
        search={true}
        sort
        columns={deliveryAnalysisTableColumns(handleShowPODImagesModal)}
        data={parcels}
        wrapperClasses="table-responsive"
      >
        {({ baseProps, searchProps, csvProps }) => (
          <Paginator.PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: totalParcels?.length,
              paginationTotalRenderer: customTotal,
              sizePerPageRenderer: customSizePerPage,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <BreadCrumbComponent items={breadCrumbItems} />
                    <h4>{t('Delivery Analysis')}</h4>
                    <p className="mb-0">
                      {t(
                        'This is the summary of the parcels dispatched from the warehouse'
                      )}
                    </p>
                  </div>
                  <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          handleParcelSearch();
                        }}
                        variant="outline-primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faRefresh} />
                        &nbsp;&nbsp;{t('Refresh')}
                      </Button>
                      <ExcelDownloadReport
                        data={Object.values(parcels) || []}
                        fileName={'delivery-analysis'}
                      />
                    </ButtonGroup>
                  </div>
                </div>
                {console.log(formInput)}
                <div className="mb-2 d-flex gap-4">
                  {isAdmin && (
                    <div className="d-flex gap-4">
                      <Form.Group>
                        <Form.Label>{t('Region')}</Form.Label>
                        <EditableDropDown
                          id="region"
                          onChange={(e) =>
                            setFormInput((prev) => ({
                              ...prev,
                              region: [e.value],
                            }))
                          }
                          value={formInput.region}
                          options={[
                            { label: 'All', value: 'All' },
                            ...arrayToDropdownOptions(Object.keys(zones)),
                          ]}
                          placeholder="Choose Region"
                          backgroundColor="#F0F0F0"
                          width={'250px'}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('Store Name')}</Form.Label>
                        <EditableDropDown
                          id="warehouseCode"
                          onChange={(e) =>
                            setFormInput((prev) => ({
                              ...prev,
                              storeName: [e.value],
                            }))
                          }
                          value={formInput.storeName}
                          options={[
                            { label: 'All', value: 'All' },
                            ...returnWarehouseDropdownOptions(otherWarehouses),
                          ]}
                          placeholder="Choose Store Name"
                          backgroundColor="#F0F0F0"
                          width={'250px'}
                        />
                      </Form.Group>
                    </div>
                  )}
                  <div className="mt-4">
                    <DateRangeSelector
                      onSubmit={onDateSelected}
                      filtersValue={filtersValue}
                    />
                  </div>
                </div>
                {Object.keys(parcelInfo)?.length > 0 && (
                  <Row>
                    <Col xs={12} sm={6} xl={2} className="mb-4">
                      <CounterWidget
                        isSelected={selectedCategory === 'delivered'}
                        onCardClicked={handleCardClickForOrders}
                        category="Delivered"
                        stage={'delivered'}
                        title={parcelInfo?.delivered?.length || 0}
                        period={''}
                        percentage={returnPercentage(
                          parcelInfo.delivered?.length,
                          totalParcels?.length
                        )}
                        icon={faCheckCircle}
                        iconColor="shape-secondary"
                      />
                    </Col>
                    <Col xs={12} sm={6} xl={2} className="mb-4">
                      <CounterWidget
                        isSelected={selectedCategory === 'pending'}
                        category="Pending"
                        onCardClicked={handleCardClickForOrders}
                        stage={'pending'}
                        title={parcelInfo?.pending?.length || 0}
                        period={''}
                        percentage={returnPercentage(
                          parcelInfo?.pending?.length,
                          totalParcels?.length
                        )}
                        icon={faHourglass}
                        iconColor="shape-secondary"
                      />
                    </Col>
                    <Col xs={12} sm={6} xl={2} className="mb-4">
                      <CounterWidget
                        isSelected={
                          selectedCategory === 'notPlannedForDelivery'
                        }
                        onCardClicked={handleCardClickForOrders}
                        category="Not Loaded"
                        stage={'notPlannedForDelivery'}
                        title={parcelInfo?.notPlannedForDelivery?.length || 0}
                        period={''}
                        percentage={returnPercentage(
                          parcelInfo?.notPlannedForDelivery?.length,
                          totalParcels?.length
                        )}
                        icon={faRoadCircleXmark}
                        iconColor="shape-secondary"
                      />
                    </Col>
                    <Col xs={12} sm={6} xl={2} className="mb-4">
                      <CounterWidget
                        isSelected={selectedCategory === 'returnok'}
                        onCardClicked={handleCardClickForOrders}
                        category="Return OK"
                        stage={'returnok'}
                        title={parcelInfo?.returnok?.length || 0}
                        period={''}
                        percentage={returnPercentage(
                          parcelInfo?.returnok?.length,
                          totalParcels?.length
                        )}
                        icon={faArrowRotateLeft}
                        iconColor="shape-secondary"
                      />
                    </Col>
                    <Col xs={12} sm={6} xl={2} className="mb-4">
                      <CounterWidget
                        isSelected={selectedCategory === 'defectivepicked'}
                        category="Return Def"
                        onCardClicked={handleCardClickForOrders}
                        stage={'defectivepicked'}
                        title={parcelInfo?.defectivepicked?.length || 0}
                        period={''}
                        percentage={returnPercentage(
                          parcelInfo?.defectivepicked?.length,
                          totalParcels?.length
                        )}
                        icon={faTimes}
                        iconColor="shape-secondary"
                      />
                    </Col>
                    <Col xs={12} sm={6} xl={2} className="mb-4">
                      <CounterWidget
                        isSelected={selectedCategory === 'isDisplay'}
                        onCardClicked={handleCardClickForOrders}
                        category="Display / GT"
                        stage={'isDisplay'}
                        title={parcelInfo?.isDisplay?.length || 0}
                        period={''}
                        percentage={returnPercentage(
                          parcelInfo?.isDisplay?.length,
                          totalParcels?.length
                        )}
                        icon={faDisplay}
                        iconColor="shape-secondary"
                      />
                    </Col>
                  </Row>
                )}
                <Card>
                  <div className="table-responsive py-2">
                    <Card.Header>
                      <Row className="d-flex justify-content-between">
                        <Col xs={8} md={6} className="d-flex py-1">
                          <div className="table-settings mt-2 mb-2">
                            <div style={{ gap: 8, display: 'flex' }} />
                          </div>
                        </Col>
                        <Col
                          xs={4}
                          md={4}
                          className="d-flex justify-content-md-end py-1"
                        >
                          <Search.SearchBar
                            {...searchProps}
                            placeholder={t('Search any value in table here')}
                          />
                          <Search.ClearSearchButton {...searchProps} />
                        </Col>
                      </Row>
                    </Card.Header>

                    <Table
                      rowClasses={(row) => {
                        const rowBGColor = returnRowBGColor(
                          row?.dashboardStatus ||
                            row?.mobileStatus ||
                            row?.status
                        );
                        return `${rowBGColor} border-bottom`;
                      }}
                      noDataIndication={<EmptyTable />}
                      {...baseProps}
                      {...paginationTableProps}
                      bodyClasses="border-0"
                      classes="table-flush dataTable-table"
                    />

                    <Card.Footer>
                      <Row>
                        <Col xs={12} md={4} className="py-1">
                          <Paginator.SizePerPageDropdownStandalone
                            {...paginationProps}
                            paginationSize={5}
                            sizePerPageList={[{ text: '5', value: 5 }]}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex align-items-center py-1"
                        >
                          <Paginator.PaginationTotalStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                        >
                          <Paginator.PaginationListStandalone
                            {...paginationProps}
                          />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </div>
                </Card>
              </>
            )}
          </Paginator.PaginationProvider>
        )}
      </ToolkitProvider>
      <PODImageSlider
        description={podImageDescription}
        show={showPODImagesModal}
        handleClose={setShowPODImagesModal}
        images={podImages}
      />
    </div>
  );
};
export default DeliveryAnalysisComponent;
