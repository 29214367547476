import {
  liquidationSchemeSampleFile,
  productsUploadSampleFile,
  upgradeOfferSchemeSampleFile,
} from '../constants';

export const schemeTypes = [
  'Sell-in',
  'Sell-out',
  'Price-drop',
  // 'Liquidation',
  'Upgrade-offer',
  'FOC',
  'Demo',
];

export const schemeTypeMap = {
  sellIn: 'Sell-in',
  sellOut: 'Sell-out',
  priceDrop: 'Price-drop',
  // liquidation: 'Liquidation',
  upgradeOffer: 'Upgrade-offer',
  foc: 'FOC',
  demo: 'Demo',
};

export const schemeSubTypes = (schemeType) => {
  let result = [];
  switch (schemeType) {
    case 'Sell-in':
      result = ['On-Invoice', 'Off-Invoice'];
      break;
    case 'Upgrade-offer':
      result = ['With-Buyback', 'Without-Buyback'];
    default:
      break;
  }
  return result;
};

export const getExampleExcelForScheme = (schemeType) => {
  let result = '';
  switch (schemeType) {
    case 'Liquidation':
      result = upgradeOfferSchemeSampleFile;
      break;
    case 'Upgrade-offer':
      result = upgradeOfferSchemeSampleFile;
      break;
    case 'liquidationFile':
      result = liquidationSchemeSampleFile;
      break;
    default:
      result = productsUploadSampleFile;
      break;
  }

  return result;
};

export const formatToINR = (value) => {
  let num = Number(value);

  if (isNaN(num)) {
    return 'Invalid Number';
  }

  return new Intl.NumberFormat('en-IN', {
    currency: 'INR',
    minimumFractionDigits: 2,
  }).format(num);
};
