import React, { useEffect, useState } from "react";
import sideimage from '../../../assets/img/Image.png';
import logoImage from "../../../assets/img/brand/vs-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../../middlewares/api";
import { apiCallConsts } from "../../../constants/apiCallConsts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation, faTimes } from "@fortawesome/free-solid-svg-icons";
// import { FaCheck, FaTimes, FaExclamationCircle } from "react-icons/fa";


const LoyaltyForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileNumber = location.state?.mobileNumber;
  const [updateType, setUpdateType] = useState("add");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: mobileNumber || "",
    dob: "",
    city: "",
    state: "",
    pincode: "",
  });

  const fetchUserData = async (mobileNumber) => {

    try{
    setLoading(true);
    const hasData = await apiCall(apiCallConsts.GET_METHOD, `/loyality/info?mobileNo=${mobileNumber}`);
    setLoading(false);

    if (hasData?.ReturnCode !== 0) {
      setFormData({
        name: "",
        email: "",
        mobile: mobileNumber,
        dob: "",
        city: "",
        state: "",
        pincode: "",
      });
      return;
    }

    setUpdateType("update");
    setFormData({
      name: hasData?.FirstName + " " + hasData?.LastName,
      email: hasData?.Email,
      mobile: mobileNumber,
      dob: hasData?.DateOfBirth ? parseFormattedDate(hasData?.DateOfBirth) : "",
      city: "",
      state: "",
      pincode: "",
    });
  }catch(error)
  {
    showErrorToast(error?.message);
  }finally{
    setLoading(false)
  }
  };

  const [isChecked, setIsChecked] = useState(false);

  const showErrorToast = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  function parseFormattedDate(formattedDate) {
    const months = { 
      "Jan": 0, "Feb": 1, "Mar": 2, "Apr": 3, "May": 4, "Jun": 5, 
      "Jul": 6, "Aug": 7, "Sept": 8, "Oct": 9, "Nov": 10, "Dec": 11 
    };
  
    const [day, monthStr, year] = formattedDate.split(" ");
    const month = months[monthStr];
  
    if (month === undefined) {
      throw new Error("Invalid month format");
    }
  
    const date = new Date(year, month, day);
    return date.toISOString().split("T")[0]; // Returns yyyy-mm-dd
  }
  
  useEffect(() => {
    if (!mobileNumber) {
      navigate("/rewards/login");
      return;
    }
    fetchUserData(mobileNumber);
  }, [mobileNumber, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async () => {
    try{
    setLoading(true);
    const response = await apiCall(apiCallConsts?.POST_METHOD, "/loyality", {
      data: { ...formData },
      updateType,
    });
    setLoading(false);
    navigate("/rewards/config", { state: { data: formData, pointsData: response } });
  }catch(error)
  {
    showErrorToast(error?.message);

  }finally{
    setLoading(false)
  }
  };

  return (
    <div
      className="d-flex shadow-lg"
      style={{
        width: "100VW",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#fff"
      }}
    >
      {/* Full-page spinner overlay */}
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center position-fixed"
          style={{
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark transparent background
            zIndex: 9999, // Ensure it overlays the form
          }}
        >
          <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}></div>
        </div>
      )}

{errorMessage && (
        <div
        className="d-flex align-items-center justify-content-between rounded shadow"
        style={{
          position: "fixed",
          bottom: "10%",
          right: "20px",
          backgroundColor: "#fff",
          color: "#000",
          padding: "10px 15px",
          minWidth: "300px",
          borderLeft: `5px solid ${!errorMessage ? "#28a745" : "#dc3545"}`,
          zIndex: 1000,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="d-flex">
          <div
            style={{
              backgroundColor: !errorMessage ? "#28a745" : "#dc3545",
              color: "#fff",
              width: "35px",
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          >
             <FontAwesomeIcon icon={ faExclamation} />
          </div>
          <div>
            <strong style={{ color: !errorMessage ? "#28a745" : "#dc3545" }}>
              {!errorMessage ? "Success" : "Error"}
            </strong>
            <div style={{ fontSize: "12px", color: "#555" }}>{errorMessage}</div>
          </div>
        </div>
        <FontAwesomeIcon icon={faTimes}
          
          style={{ cursor: "pointer", color: "#999" }}
          onClick={() => {setErrorMessage("")}}
        />
      </div>
    
      )}

      <div
        className="d-flex flex-column justify-content-between overflow-auto"
        style={{ width: "100%", minWidth: "50%", flex: "1", zIndex: 2, boxSizing: "border-box" }}
      >
        <div
          className="w-100 h-5 bg-white d-flex justify-content-start p-4 py-5 align-items-center"
          style={{ position: "absolute", top: 0, height: "5vh", zIndex: 3 }}
        >
          <img
            src={logoImage}
            alt="Logo"
            className="img-fluid"
            style={{ maxWidth: "160px" }}
          />
        </div>
        <div style={{ height: "1%" }}></div>
        <div className="p-4" style={{ width: "100%", height: "90%" }}>
          <h4 className="fw-bold">Vijay Sales Loyalty Hub</h4>
          <p className="text-muted" style={{ fontSize: "14px" }}>
            Please fill in all required fields (marked with *).
          </p>

          <div className="mb-3">
            <h5 className="fw-bold">Personal Details</h5>
            <label className="form-label">Name*</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your Name"
              style={{ borderRadius: "0px" }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email ID*</label>
            <input
              className="form-control"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your Email ID"
              style={{ borderRadius: "0px" }}
            />
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Mobile No*</label>
              <input
                className="form-control"
                type="text"
                name="mobile"
                value={formData.mobile}
                disabled
                style={{ borderRadius: "0px" }}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Date of Birth*</label>
              <input
                className="form-control"
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                placeholder="DD/MM/YYYY"
                style={{ borderRadius: "0px" }}
              />
            </div>
          </div>
          <h5 className="fw-bold">Location Details</h5>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Select City*</label>
              <input
                className="form-control"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="Enter City"
                style={{ borderRadius: "0px" }}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Select State*</label>
              <input
                className="form-control"
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                placeholder="Enter State"
                style={{ borderRadius: "0px" }}
              />
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Pin Code*</label>
            <input
              className="form-control"
              type="text"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              placeholder="Enter your Pincode"
              style={{ borderRadius: "0px" }}
            />
          </div>
          <div className="mb-3">
            <input
              type="checkbox"
              className="me-2"
              checked={isChecked}
              onChange={handleCheckboxChange}
              style={{ borderRadius: "0px" }}
            />
            <label>I agree to Terms & Conditions</label>
          </div>
          <div className="d-flex gap-4">
            <button
              style={{
                width: "250px",
                backgroundColor: isChecked ? "#000" : "#ccc",
                color: "#fff",
                padding: "12px",
                fontSize: "16px",
                fontWeight: "bold",
                border: "none",
                borderRadius: "6px",
                cursor: isChecked ? "pointer" : "not-allowed",
                marginBlock: "20px",
              }}
              onClick={handleSubmit}
              disabled={!isChecked}
            >
              ✔ Submit
            </button>
            <button
              style={{
                width: "250px",
                backgroundColor: "#fff",
                color: "#000",
                padding: "12px",
                fontSize: "16px",
                fontWeight: "bold",
                border: "1px solid #ccc",
                borderRadius: "6px",
                cursor: "pointer",
                marginBlock: "20px",
              }}
            >
              ✖ Cancel
            </button>
          </div>
        </div>
      </div>
      <img
        src={sideimage}
        alt="Promo"
        className="d-none d-lg-flex"
        style={{
          width: "50%",
          height: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "12px",
          padding: "20px",
          zIndex: 999,
        }}
      />
    </div>
  );
};

export default LoyaltyForm;
