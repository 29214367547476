import React, { useEffect } from 'react';
import TripsTableData from './tripsTableData';
import { returnActiveTripColumns } from './tripsUtils';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Button, ButtonGroup } from '@themesberg/react-bootstrap';
import ExcelDownloadReport from '../../components/common/excel/download';
import { updateSpinnerState } from '../../actions/spinner';
import { useTranslation } from 'react-i18next';
import { returnActiveTripsDataToDownload } from '../../utils/trips';
import { getCurrentTripsFromServer } from '../../parse-functions/trips';
import { setPendingReceivedTripsInStore } from '../../actions/trips';
import { useNavigate } from 'react-router-dom';
import TripFilters from './tripFilters';
import { setFiltersInStore } from '../../actions/filters';

const PendingReceivedTemposheets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageType = 'pendingReceivedTrips';
  const pendingReceivedTrips = useSelector(
    (state) => state?.trips?.pendingReceivedTrips || {}
  );
  const storeInfo = useSelector((state) => state.user.warehouse || {});
  const isSuperAdmin = useSelector(
    (state) => state.user.preferences.isSuperAdmin || false
  );
  const defaultFilter = useSelector((state) => state.filters?.[pageType]);
  const handleGetTrips = async (requestProps) => {
    dispatch(
      setFiltersInStore({
        pageType,
        data: requestProps,
      })
    );
    try {
      dispatch(updateSpinnerState(true));
      const result = await getCurrentTripsFromServer({
        ...requestProps,
        warehouseCode: !isSuperAdmin
          ? storeInfo?.warehouseCode
          : requestProps?.storeName?.[0],
        region: requestProps?.region?.[0],
        isPendingReceived: true,
        isSuperAdmin,
      });
      dispatch(setPendingReceivedTripsInStore(result));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };
  const handlePrintClick = (event, objectId) => {
    event.stopPropagation();
    window.open(`${window.location.origin}/trips/${objectId}/print`, '_blank');
  };

  const handleRowClick = (tripNumber) => {
    navigate(
      `/trip/receive-stock?tripNumber=${encodeURIComponent(tripNumber)}`
    );
  };

  useEffect(() => {
    if (Object.keys(pendingReceivedTrips).length === 0 && !isSuperAdmin) {
      handleGetTrips();
    }
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Trips')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('List')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t('Pending Received Trips')}</h4>
          <p className="mb-0">
            {t(
              'All the pending received of your warehouse are listed here. Click refresh to get Data!'
            )}
          </p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0 gap-2">
          <Button
            onClick={() => handleGetTrips(defaultFilter)}
            variant="outline-primary"
            size="sm"
          >
            <FontAwesomeIcon icon={faRefresh} />
            &nbsp;&nbsp;{t('Refresh')}
          </Button>
          <ExcelDownloadReport
            data={returnActiveTripsDataToDownload(pendingReceivedTrips) || []}
            fileName={`Pending-Received-Trips.csv`}
          />
        </div>
      </div>
      <TripFilters
        onSubmit={handleGetTrips}
        defaultFilter={defaultFilter}
        isAdmin={isSuperAdmin}
      />
      <TripsTableData
        columns={returnActiveTripColumns(handlePrintClick)}
        data={pendingReceivedTrips}
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default PendingReceivedTemposheets;
