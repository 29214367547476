import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Row,
  Nav,
  Tab,
  Card,
} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../router/routes.js';
import {
  faAdd,
  faArchive,
  faArrowUpFromBracket,
  faBullseye,
  faClose,
  faCoins,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings.js';
import { updateSpinnerState } from '../../../../actions/spinner.js';
import { CounterWidget } from '../../../../components/Widgets.js';
import { AddDebitnoteModal } from './DebitnoteModal.js';
import { useParams } from 'react-router-dom';
import {
  getDetailedPurchase,
  getSchemeById,
  updateSchemesStatus,
} from '../../../../parse-functions/sms/scheme.js';
import ConfirmationModal from '../../../../components/common/confirmationModal.js';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs.js';
import PurchaseData from './PurchaseData.js';
import { returnUserReadableDate } from '../../../../utils/datetime.js';
import { getDebitNote } from '../../../../parse-functions/sms/addDebitnote.js';
import TableOnly from './TableOnly.js';
import { debitNoteTable } from '../../../../utils/sms/debitNoteTable.js';
import BreadCrumbTitle from '../common/BreadCrumbTitle.js';
import { SchemeBadge } from '../../../../utils/sms/schemeTableRedesign.js';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import ExcelDownloadReport from '../../../../components/common/excel/download.js';
import { exportSchemeData } from '../../../../utils/sms/exportSchemeData.js';
import {
  convertdownloadDataToFlatJSON,
  returnDownloadableData,
} from '../../../../utils/excel.js';
import { exportToExcel } from 'react-json-to-excel';
import {
  formatToINR,
  schemeTypeMap,
} from '../../../../utils/sms/constantsAndUtils.js';

const RedesignSchemeAnalysisComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState({});
  const [noteType, setNoteType] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [schemeData, setSchemeData] = useState();
  const [noteAdded, setNoteAdded] = useState(false);
  const [debitNoteData, setDebitNoteData] = useState([]);
  const [creditNoteData, setCreditNoteData] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const [detailedPurchaseData, setDetailedPurchaseData] = useState([]);
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );
  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const schemes = useSelector((state) => state.schemes || {});
  const { id } = useParams();
  const [confirmationModalProps, setConfirmationModalProps] = useState([
    {
      type: 'textArea',
      title: t('Remarks'),
      value: '',
    },
  ]);

  const targetAchieved =
    Object.values(orders?.calculationReference || {})?.reduce((acc, item) => {
      if (item?.status === 'completed') {
        acc += 1;
      }
      return acc;
    }, 0) || 0;

  const counterWidgets = [
    {
      category: 'Total Items',
      title: formatToINR(orders?.totalItems) || 0,
      icon: faShoppingCart,
      iconColor: '#F11B24',
    },
    {
      category: 'Total Quantity',
      title: formatToINR(orders?.totalQuantity) || 0,
      icon: faArchive,
      iconColor: '#F11B24',
    },
    {
      category: 'Total Value',
      title: formatToINR(orders?.totalValue) || 0,
      icon: faCoins,
      iconColor: '#F11B24',
    },
  ];

  if (
    [schemeTypeMap.liquidation, schemeTypeMap.upgradeOffer].includes(
      schemeData?.type?.[0]
    )
  ) {
    counterWidgets.shift();
    counterWidgets.push(
      {
        category: 'VSAmount',
        title:
          Object.values(orders?.calculationReference || {})
            ?.reduce((acc, item) => acc + item.totalVsAmount, 0)
            .toFixed(2) || 0,
        icon: faCoins,
        iconColor: '#F11B24',
      },
      {
        category: 'BrandAmount',
        title:
          Object.values(orders?.calculationReference || {})
            ?.reduce((acc, item) => acc + item.totalBrandAmount, 0)
            .toFixed(2) || 0,
        icon: faCoins,
        iconColor: '#F11B24',
      }
    );
  }
  const HandleShowModal = (type) => {
    if (type === 'debit') {
      setNoteType('debit');
    } else {
      setNoteType('credit');
    }
    setShowModal(true);
  };
  const HandleCloseModal = () => {
    setShowModal(false);
  };
  const breadCrumbItems = arrayToBreadCrumbs(
    schemeData?.status === 'closed'
      ? [
          [t('Closed Schemes'), Routes.SMS.Target.RedesignClosedSchemes.path],
          [t('Scheme Details')],
        ]
      : schemeData?.status === 'ongoing' || schemeData?.status === 'completed'
      ? [
          [t('Active Schemes'), Routes.SMS.Target.RedesignActiveSchemes.path],
          [t('Scheme Details')],
        ]
      : [
          [
            t('Rejected Schemes'),
            Routes.SMS.Target.RedesignRejectedSchemes.path,
          ],
          [t('Scheme Details')],
        ]
  );

  const handleCloseScheme = async (data) => {
    setShowDeleteModal(false);
    try {
      await updateSchemesStatus(data);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Close Scheme'),
          message: t('Successfully closed the scheme'),
        })
      );
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Close Scheme'),
          message: t('Error while closing the scheme'),
        })
      );
    }
  };

  useEffect(() => {
    const fetchSchemeData = async () => {
      return await getSchemeById({ id });
    };
    // const fetchOrdersData = async (data) => {
    //   return await getSchemeData(data);
    // };
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const currentSchemeData =
          Object.values(schemes)
            .flat()
            .filter((item) => item.objectId === id) || [];
        if (currentSchemeData.length !== 0) {
          setSchemeData(currentSchemeData[0]);
          setOrders(currentSchemeData[0]?.schemeSummary);
        } else {
          const schemeDataResult = await fetchSchemeData();
          setSchemeData(schemeDataResult);
          setOrders(schemeDataResult?.schemeSummary);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            tile: '',
            message: `Error while fetching scheme details.${error.message}`,
          })
        );
      } finally {
        dispatch(updateSpinnerState(false));
      }
    };

    fetchData();
  }, []);

  const handleExportClick = async () => {
    let excelData = [];
    const { vanList, validFrom, validTill, warehouseList, regions, type } =
      orders;
    if (type === schemeTypeMap.priceDrop) {
      const inventory = schemeData.inventory;
      Object.entries(inventory).forEach(([vanNo, stores]) => {
        const product = schemeData.products
          .filter((product) => product.vanNo === vanNo)
          .map((product) => product.productName)?.[0];
        stores.forEach((store) => {
          excelData.push({
            vanNo: vanNo,
            product,
            storeName: store.storeName,
            totalStock: store.totalStock + store.display || 0,
          });
        });
      });
    } else if (detailedPurchaseData.length === 0) {
      dispatch(updateSpinnerState(true));
      try {
        const data = await getDetailedPurchase({
          vanList,
          validFrom,
          validTill,
          warehouseList,
          regions,
          type,
        });
        excelData = exportSchemeData(schemeData, data, type);
        setDetailedPurchaseData(data);
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: 'Data fetch Failed',
            message: error.message,
          })
        );
      }
    } else {
      excelData = exportSchemeData(schemeData, detailedPurchaseData, type);
    }
    const flatJSONData = convertdownloadDataToFlatJSON(excelData);
    const downloadableData = returnDownloadableData(flatJSONData);
    console.log(downloadableData);
    exportToExcel(
      downloadableData,
      `${schemeData.company[0]}_${schemeData.schemeCircularNo}`
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDebitNote({
          schemeId: id,
        });
        setDebitNoteData(data.filter((item) => item.type === 'Debit note'));
        setCreditNoteData(data.filter((item) => item.type === 'Credit note'));
      } catch (error) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: 'Data fetch Failed',
            message: error.message,
          })
        );
      }
    };
    fetchData();
  }, [noteAdded]);

  return (
    <div>
      {emptyData && (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <h3>Invalid Scheme ID</h3>
        </div>
      )}
      {schemeData && (
        <div>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <BreadCrumbTitle breadcrumbs={breadCrumbItems} />
            </div>
            <Button
              variant="white"
              size="md"
              className="btn-export"
              onClick={handleExportClick}
            >
              <FontAwesomeIcon icon={faArrowUpFromBracket} />
              &nbsp;&nbsp;{t('Export')}
            </Button>
          </div>
          <div>
            <div className="d-flex justify-content-between  align-items-center mb-1">
              <span className="fw-bold fs-5">
                {t(
                  `${schemeData?.schemeCircularNo} / ${schemeData?.company?.[0]}`
                )}
              </span>
              <div className="d-flex gap-2">
                {schemeData.status === 'ongoing' &&
                  schemeData.type?.[0] !== schemeTypeMap.priceDrop && (
                    <div className="custom-days-left">
                      <FontAwesomeIcon icon={faClock} className="me-2" />
                      Days Left:{' '}
                      {Math.max(
                        0,
                        Math.floor(
                          (new Date(schemeData.validTill) - new Date()) /
                            (1000 * 60 * 60 * 24)
                        )
                      )}
                    </div>
                  )}
                <SchemeBadge text={schemeData.status} />
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 align-items-center">
              <div className="d-flex gap-2">
                <Card className="d-flex flex-row align-items-center p-2 gap-2">
                  <span className="fw-bold fs-6">Start Date : </span>
                  <span style={{ fontWeight: '500', fontSize: '14px' }}>
                    {t(`${returnUserReadableDate(schemeData?.validFrom)}`)}
                  </span>
                </Card>
                <Card className="d-flex flex-row align-items-center p-2 gap-2">
                  <span className="fw-bold">End Date : </span>
                  <span style={{ fontWeight: '500', fontSize: '14px' }}>
                    {t(
                      `${returnUserReadableDate(schemeData?.validTill) || '-'}`
                    )}
                  </span>
                </Card>
              </div>
              <div>
                {schemeData.status !== 'closed' &&
                  schemeData.status !== 'rejected' &&
                  !isBrandManager &&
                  !isBrandVendor && (
                    <div className="d-flex gap-2">
                      <Card>
                        <Button
                          onClick={() => HandleShowModal('debit')}
                          variant="white"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faAdd} />
                          &nbsp;&nbsp;{t('Add Debit Note')}
                        </Button>
                      </Card>
                      <Card>
                        <Button
                          onClick={() => HandleShowModal('credit')}
                          variant="white"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faAdd} />
                          &nbsp;&nbsp;{t('Add Supplier Credit Note')}
                        </Button>
                      </Card>
                      {schemeData?.status === 'completed' && (
                        <Card>
                          <Button
                            onClick={() => {
                              setShowDeleteModal(true);
                            }}
                            variant="white"
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faClose} />
                            &nbsp;&nbsp;{t('Close Scheme')}
                          </Button>
                        </Card>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <AddDebitnoteModal
            showModal={showModal}
            closeModal={HandleCloseModal}
            schemeId={id}
            type={noteType}
            setNoteAdded={setNoteAdded}
          />
          <ConfirmationModal
            showModal={showDeleteModal}
            onConfirm={() => {
              handleCloseScheme({
                schemeId: [id],
                status: 'closed',
                reason: confirmationModalProps[0].value,
                userName: userName,
              });
            }}
            closeModal={() => {
              setShowDeleteModal(false);
            }}
            modalBody={t(`Are you sure you want to Close Scheme`)}
            modalTitle={t(`Close Scheme`)}
            confirmationModalProps={confirmationModalProps}
            setConfirmationModalProps={setConfirmationModalProps}
          />
          <Row className="mt-2">
            {counterWidgets.map((each) => {
              const columnSize = counterWidgets.length > 3 ? 3 : 4;
              return (
                <Col xs={12} sm={2} xl={columnSize} className="mb-4">
                  <CounterWidget
                    category={each.category}
                    title={each.title}
                    icon={each.icon}
                    iconColor={each.iconColor}
                    period={each.period}
                    isDanger={each.isDanger}
                  />
                </Col>
              );
            })}
          </Row>
          {schemeData?.type?.[0] === 'Sell-out' &&
            schemeData?.products?.[0].cappingQuantity && (
              <Card className="p-2 mb-2">
                Total Products : {schemeData?.products?.length}
                <div className="d-flex gap-3">
                  <div
                    className="w-50 p-2"
                    style={{ backgroundColor: '#F0F0F0' }}
                  >
                    <FontAwesomeIcon icon={faBullseye} /> &nbsp;&nbsp; Target
                    Achieved : {targetAchieved} products
                  </div>
                  <div
                    className="w-50 p-2"
                    style={{ backgroundColor: '#F0F0F0' }}
                  >
                    <FontAwesomeIcon icon={faBullseye} />
                    &nbsp;&nbsp; Yet to achieve :{' '}
                    {schemeData?.products?.length - targetAchieved} products
                  </div>
                </div>
              </Card>
            )}

          <Tab.Container defaultActiveKey="purchase">
            <Row>
              <Col lg={12}>
                <Nav className="nav-tabs custom-nav-tabs mb-4">
                  <Nav.Item>
                    <Nav.Link eventKey="purchase" className="mb-sm-3 mb-md-0">
                      {t(
                        schemeData?.type?.[0] === 'Sell-out'
                          ? 'Orders Data'
                          : 'Purchase Data'
                      )}
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="debit" className="mb-sm-3 mb-md-0">
                      {t('Debit Notes')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="credit" className="mb-sm-3 mb-md-0">
                      {t('Supplier Credit Notes')}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="purchase" className="py-4">
                    <PurchaseData
                      orders={orders}
                      data={detailedPurchaseData}
                      setData={setDetailedPurchaseData}
                      schemeData={schemeData}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="debit" className="py-4">
                    <TableOnly
                      data={debitNoteData}
                      columns={debitNoteTable()}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="credit" className="py-4">
                    <TableOnly
                      data={creditNoteData}
                      columns={debitNoteTable()}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      )}
    </div>
  );
};
export default RedesignSchemeAnalysisComponent;
