import { Button, Modal } from '@themesberg/react-bootstrap';
import React from 'react';
import { generateRandomId } from '../../../../utils/string';
import { useTranslation } from 'react-i18next';
import {
  returnUserReadableDate,
  returnUserReadableDateTime,
} from '../../../../utils/datetime';
import RenderObject from './RenderObj';

const PaymentDetailsModal = ({ showModal, closeModal, data }) => {
  const { t } = useTranslation();

  const clubbedSameVanNos = (data) => {
    let map = data?.items?.reduce((acc, item) => {
      const vanNo = item?.vanNo;
      if (!acc.hasOwnProperty(vanNo)) {
        acc[vanNo] = {
          productTitle: item?.productTitle,
          quantity: 0,
          orderAmount: 0,isExchange : false
        };
      }
      acc[vanNo] = {
        ...acc[vanNo],
        quantity: acc[vanNo]?.quantity + 1,
        orderAmount: acc[vanNo]?.orderAmount +  parseInt(item?.vsPrice),
        isExchange : item?.isExchange
      };
      return acc;
    }, {});
    return map;
  };

  const mappedVanNos = clubbedSameVanNos(data) || [];

  // const emiDetails =
  //   data?.payment?.[0]?.PaymentPayload?.content?.order?.emi_details || {};

  const {
    orderNo,
    vsOrderNo,
    method,
    paymentDateAndTime,
    paymentType,
    approvalCode,
    transactionId,
    trancationId,
    PaymentPayload,
    paymentPayload,
    ...paymentDetails
  } = data?.payment[0] || {};

  return (
    <Modal
    id={generateRandomId()}
    as={Modal.Dialog}
    centered
    show={showModal}
    onHide={closeModal}
    size="lg"
    style={{ border: "1px solid black" }}
    backdrop="true" // This ensures the modal closes when clicking outside
  >
    <div
      className="modal-backdrop"
      onClick={closeModal}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark background
        zIndex: -1, // Ensure the backdrop is below the modal
      }}
    />
      <Modal.Header style={{ 
  borderLeft: "1px solid black", 
  borderTop: "1px solid black", 
  borderRight: "1px solid black" 
}}>
        <Modal.Title className="h6">{t(`Payments Summary`)}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
   
      <Modal.Body  style={{ 
  borderLeft: "1px solid black", 
  borderBottom: "1px solid black", 
  borderRight: "1px solid black" 
}} >
        <div className="d-flex justify-content-evenly pt-1">
          <div>
            <span className="fw-bold">Method : </span>{' '}
            {data?.payment?.[0]?.method || 'method'}
          </div>
          <div>
            <span className="fw-bold">Mode : </span>{' '}
            {data?.payment?.map((each) => each?.paymentMode) || 'mode'}
          </div>
          <div>
            <span className="fw-bold">Type : </span>{' '}
            {data?.payment?.map((each) => each?.paymentType) || 'mode'}
          </div>
          <div>
            <span className="fw-bold">Amount : </span>{' '}
            {data?.payment?.reduce((sum, payment) => sum + (payment?.billAmount || 0), 0) || 'amount'}
          </div>
        </div>
        <div className="d-flex justify-content-evenly gap-3 mx-3 mt-2">
          <div
            style={{
              backgroundColor: '#EAEDF2',
              padding: '15px',
              borderRadius: '5px',
              lineHeight: '20px',
              width: 'fit-content',
              maxWidth: '397px',
            }}
          >
            <h6>Order Information</h6>
            <div>
              <div>
                <strong>Order Number:</strong>
                {data?.orderNo}
              </div>
              <div>
                <strong>Order Date:</strong>{' '}
                {returnUserReadableDate(data?.createdAt)}
              </div>
              <div>
                <strong>Order Time:</strong>{' '}
                {returnUserReadableDateTime(data?.createdAt)?.split(',')[2]}
              </div>
              <div>
                <strong>Customer Name:</strong> {data?.customer?.firstName}
              </div>
              <div>
                <strong>Shipping Address:</strong>{' '}
                {`${data?.shippingAddress?.shippingAddress1}, ${data?.shippingAddress?.shippingAddress2}, ${data?.shippingAddress?.shippingAddress3}, ${data?.shippingAddress?.shippingCity}, ${data?.shippingAddress?.shippingState}, ${data?.shippingAddress?.shippingPincode}`}
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#EAEDF2',
              padding: '15px',
              borderRadius: '5px',
              lineHeight: '20px',
              width: 'fit-content',
              maxWidth: '397px',
            }}
          >
            <h6>Payment Information</h6>
            <div>
              <div>
                <strong>Transaction Id:</strong>
                {data?.payment[0]?.transactionId ||
                  data?.payment[0]?.trancationId ||
                  ''}
              </div>
              <div>
                <strong>Approval Code:</strong>
                {data?.payment[0]?.approvalCode || ''}
              </div>
              <div>
                <strong>Payment Status:</strong>
                {data?.payment[0]?.paymentStatus}
              </div>
              <div>
                <strong>Payment Date:</strong>{' '}
                {data?.payment[0]?.paymentDateAndTime?.split(' ')[0]}
              </div>
              <div>
                <strong>Payment Time:</strong>{' '}
                {data?.payment[0]?.paymentDateAndTime?.split(' ')[1]}
              </div>
              <div>
                <strong>Billing Address:</strong>{' '}
                {`${data?.shippingAddress?.billingAddress1}, ${data?.shippingAddress?.billingAddress2}, ${data?.shippingAddress?.billingAddress3}, ${data?.shippingAddress?.billingCity}, ${data?.shippingAddress?.billingState}, ${data?.shippingAddress?.billingPincode}`}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            // backgroundColor: '#EAEDF2',
            padding: '15px',
            borderRadius: '5px',
            maxWidth: '829px',
            margin: '0 auto',
          }}
        >
          <h6
            style={{
              //   marginBottom: '15px',
              borderBottom: '2px solid #d9d9d9',
              paddingBottom: '5px',
            }}
          >
            PRODUCT INFORMATION
          </h6>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Product Name</th>
                <th style={{ textAlign: 'right' }}>Quantity</th>
                <th style={{ textAlign: 'right' }}>Price</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(mappedVanNos)?.filter(each => !each?.isExchange)?.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      padding: '5px 0',
                      fontSize: '0.9rem',
                      textWrap: 'wrap',
                      maxWidth: '20rem',
                    }}
                  >
                    {
                      // returnCustomerAddressString(
                      item?.productTitle
                      //   null,
                      //   300,
                      //   70
                      // )
                    }
                  </td>
                  <td style={{ textAlign: 'center', padding: '5px 5px' }}>
                    {item?.quantity}
                  </td>
                  <td
                    style={{ textAlign: 'right', padding: '5px 0' }}
                  >{`₹${item?.orderAmount}`}</td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan="2"
                  style={{
                    padding: '5px 0',
                    fontWeight: 'bold',
                    borderTop: '2px solid #d9d9d9',
                   
                  }}
                >
                  Sub Total
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    padding: '5px 0',
                    fontWeight: 'bold',
                    borderTop: '2px solid #d9d9d9',
                   
                  }}
                >
                  {Object.values(mappedVanNos)?.filter(each => !each?.isExchange)?.reduce(
                    (total, item) => total + item.orderAmount,
                    0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
         {Object.values(mappedVanNos)?.filter(each => each?.isExchange)?.length > 0 && <h6
            style={{
              //   marginBottom: '15px',
              borderBottom: '2px solid #d9d9d9',
              paddingBottom: '5px',
            }}
          >
            BUYBACK INFORMATION
          </h6> }
         {Object.values(mappedVanNos)?.filter(each => each?.isExchange)?.length > 0 && <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Product Name</th>
                <th style={{ textAlign: 'right' }}>Quantity</th>
                <th style={{ textAlign: 'right' }}>Price</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(mappedVanNos)?.filter(each => each?.isExchange)?.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      padding: '5px 0',
                      fontSize: '0.9rem',
                      textWrap: 'wrap',
                      maxWidth: '20rem',
                    }}
                  >
                    {
                      // returnCustomerAddressString(
                      item?.productTitle
                      //   null,
                      //   300,
                      //   70
                      // )
                    }
                  </td>
                  <td style={{ textAlign: 'center', padding: '5px 5px' }}>
                    {item?.quantity}
                  </td>
                  <td
                    style={{ textAlign: 'right', padding: '5px 0' }}
                  >{`₹${item?.orderAmount}`}</td>
                </tr>
              ))}
              
            </tbody>
          </table> }

          {/* {Object.keys(emiDetails).length > 0 && (
            <>
              <h6 style={{ paddingTop: '10px' }}>EMI DETAILS SECTION</h6>
              <div
                style={{
                  backgroundColor: '#EAEDF2',
                  padding: '15px',
                  borderRadius: '5px',
                  lineHeight: '20px',
                  maxWidth: '100%',
                }}
              >
                <div>
                  <div className="d-flex justify-content-between">
                    <strong>EMI Type:</strong>
                    <span>{emiDetails?.emi_type || ''}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Monthly Payment:</strong>
                    <span>{emiDetails?.monthly_payment || ''}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Bank:</strong>
                    <span>{emiDetails?.bank || ''}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Interest:</strong>
                    <span>{data?.customer?.firstName}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Discount in EMI Purchased:</strong>
                    <span>{data?.customer?.firstName}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Processing Fee:</strong>
                    <span>{data?.customer?.firstName}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Tenure:</strong>
                    <span>{data?.customer?.firstName}</span>
                  </div>
                </div>
              </div>
            </>
          )} */}

{data?.payment?.length > 0 && (
  <>
    <div className="fw-medium fs-6 text-uppercase mt-1">
      Payment Gateway Response Section
    </div>
    {data?.payment.map((each, index) => {
      const {
        orderNo,
        vsOrderNo,
        method,
        paymentDateAndTime,
        paymentType,
        approvalCode,
        transactionId,
        trancationId,
        PaymentPayload,
        paymentPayload,
        ...paymentDetails
      } = each || {};

      return (
        <div key={index}>
          <hr /> {/* Separator for clarity */}
          <div className="fw-bold fs-5">
            {data?.payment?.length == 1 ? "" :  index === 0 ? "Prebook Payment" :  index == 1  ? "Balance Payment" : ""}
          </div>
          <RenderObject obj={paymentDetails} />
         
        </div>
      );
    })}
  </>
)}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            <div style={{ width: '48%' }}>
              <h6
                style={{
                  borderBottom: '1px solid #d9d9d9',
                  //   paddingBottom: '5px',
                }}
              >
                Discount & Offers
              </h6>
              {/* <div>
                Coupon Amount: {`₹${data?.payment[0]?.couponAmount || 0}`}
              </div> */}
              <div className="d-inline-flex justify-content-between w-100">
                <div className="fw-medium">Coupon Amount:</div>
                <div>{`₹${data?.payment[0]?.couponAmount || 0}`}</div>
              </div>
              <div className="d-inline-flex justify-content-between w-100">
                <div className="fw-medium">Coupon Code:</div>
                <div>{data?.payment[0]?.couponCode || ''}</div>
              </div>
              <div className="d-inline-flex justify-content-between w-100">
                <div className="fw-medium">PG Offer:</div>
                <div>{`₹${data?.payment[0]?.pgDiscount || 0}`}</div>
              </div>
              <div className="d-inline-flex justify-content-between w-100">
                <div className="fw-medium">Bank Offer:</div>
                <div>{`₹${data?.payment[0]?.bankDiscount || 0}`}</div>
              </div>
              {/* <div>
                Coupon Code:{' '}
                <span className="text-end">
                  {' '}
                  {data?.payment[0]?.couponCode || ''}
                </span>
              </div>
              <div>PG Offer: {`₹${data?.payment[0]?.pgDiscount || 0}`}</div>
              <div>Bank Offer: {`₹${data?.payment[0]?.bankDiscount || 0}`}</div> */}
            </div>
            <div style={{ width: '48%' }}>
              <h6
                style={{
                  borderBottom: '1px solid #d9d9d9',
                  //   paddingBottom: '5px',
                }}
              >
                Taxes & Fees
              </h6>
              <div className="d-inline-flex justify-content-between w-100">
                <div className="fw-medium">CGST:</div>
                <div>{`₹${parseFloat(data?.cgst).toFixed(2) || 0}`}</div>
              </div>
              <div className="d-inline-flex justify-content-between w-100">
                <div className="fw-medium">SGST:</div>
                <div>{`₹${parseFloat(data?.sgst).toFixed(2) || 0}`}</div>
              </div>
              <div className="d-inline-flex justify-content-between w-100">
                <div className="fw-medium"> Shipping Fee:</div>
                <div>{`₹${data?.payment[0]?.shippingAmount || 0}`}</div>
              </div>
              {/* <div>CGST : {`₹${parseFloat(data?.cgst).toFixed(2) || ''}`}</div>
              <div>SGST : {`₹${parseFloat(data?.sgst).toFixed(2) || ''}`}</div>
              <div>
                Shipping Fee: {`₹${data?.payment[0]?.shippingAmount || 0}`}
              </div> */}
            </div>
          </div>

          <div style={{ marginTop: '20px' }}>
            <h6
              style={{
                borderBottom: '1px solid #d9d9d9',
                // paddingBottom: '5px',
              }}
            >
              Payment Allocation
            </h6>
            {data?.payment?.map((each, index) => (
              <div key={index}>
                <div className="d-inline-flex justify-content-between w-100">
                  <div className="fw-medium"> {each?.paymentType}: </div>
                  <div>{`₹${each?.billAmount || 0.0}`}</div>
                </div>
                {/* {each?.paymentType}: {`₹${each?.billAmount || 0.0}`} */}
              </div>
            ))}
          </div>

          <div
            style={{
              marginTop: '20px',
              fontWeight: 'bold',
              borderTop: '2px solid #d9d9d9',
              paddingTop: '10px',
              textAlign: 'right',
            }}
          >
            <div>
              Total Amount: {`₹${data?.payment?.reduce((sum, payment) => sum + (payment?.billAmount || 0), 0) || ''}`}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentDetailsModal;
