import React, { useState } from "react";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BreadCrumbComponent from "../../components/common/Breadcrumb";
import { Button, ButtonGroup, Card, Col, Row } from "@themesberg/react-bootstrap";
import Table from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Routes } from "../../router/routes";
import { customSizePerPage, customTotal } from "../../components/common/parcelStatusPages";
import EmptyTable from "../../components/common/EmptyTableView";
import DropdownComponent from "../../components/common/Dropdown";
import { faFilePen, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { getCurrentTripsFromServer } from "../../parse-functions/trips";
import ExcelDownloadReport from "../../components/common/excel/download";
import DateRangeSelector from "../../components/common/DateRangeSelector";
import { setFiltersInStore } from "../../actions/filters";
import { dailyDeliveryReportModesOptions, dailyDeliveryReportTableColumns, returnDailyDeliveryReportTableData, returnDailyDeliveryReportVehicleTableData } from "../../utils/daily-delivery";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";

const DailyDeliveryReportComponent = () => {
    const pageType = "dailyDeliveryReport";
    const filtersValue = useSelector((state) => state.filters?.[pageType]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const storeInfo = useSelector((state) => state.user.warehouse || {});
    const [apiReportData, setAPIReportData] = useState([]);
    const [reportModeOption, setReportModeOption] = useState("trip");
    const [data, setData] = useState([]);
    const isSuperAdmin = useSelector((state) => state.user.preferences.isSuperAdmin || false);

    const breadCrumbItems = [{
        title: t("Analysis"),
        link: Routes.Analysis.DeliveryData.path
    }, {
        title: t("Delivery Data"),
        link: ""
    }];
    const handleGetAvailableAnalyticsData = async (requestProps) => {
        dispatch(updateSpinnerState(true));
        try {
            const dataList = await getCurrentTripsFromServer(requestProps);
            setAPIReportData(dataList);
            if (reportModeOption === "trip") {
                setData(returnDailyDeliveryReportTableData(dataList));
            } else if (reportModeOption === "vehicle") {
                setData(returnDailyDeliveryReportVehicleTableData(dataList));
            }
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('All Analytics data fetched'),
                message: t('These are all the closed trips currently from your warehouse')
            }));
            dispatch(updateSpinnerState(false));
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t("Error in getting active analytics data"),
                message: e.message
            }));
        }
    }
    const handleReportModeClick = () => {
        if (reportModeOption?.length > 0) {
            switch (reportModeOption) {
                case "vehicle": {
                    setData(returnDailyDeliveryReportVehicleTableData(apiReportData));
                    break;
                }
                case "trip": {
                    setData(returnDailyDeliveryReportTableData(apiReportData));
                    break;
                }
                default: {
                    break;
                }
            }
        } else {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Please select an operation"),
                message: t("Please choose an option from the available dropdown bulk update actions")
            }))
        }
    };

    const onDateSelected = (startDate, endDate) => {
        const requestProps = {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            warehouseCode: storeInfo?.warehouseCode,
            isAnalytics: true,
            isSuperAdmin
        };
        dispatch(setFiltersInStore({
            pageType,
            data: {
                startDate: new Date(startDate),
                endDate: new Date(endDate)
            }
        }))
        handleGetAvailableAnalyticsData(requestProps)
    }
    const handleRefreshData = () => {
        handleGetAvailableAnalyticsData({
            startDate: filtersValue?.startDate ? new Date(filtersValue.startDate) : returnStartOfDay(new Date()),
            endDate: filtersValue?.endDate ? new Date(filtersValue.endDate) : returnEndOfDay(new Date()),
            warehouseCode: !isSuperAdmin ? storeInfo?.warehouseCode : '',
            isAnalytics: true,
            isSuperAdmin
        })
    };
    return (
        <ToolkitProvider
            exportCSV
            keyField="id"
            search={true}
            sort
            columns={dailyDeliveryReportTableColumns()}
            data={data}
            wrapperClasses="table-responsive"
        >
            {({ baseProps, searchProps, csvProps }) => (
                <Paginator.PaginationProvider pagination={
                    Pagination({
                        custom: true,
                        showTotal: true,
                        alwaysShowAllBtns: true,
                        totalSize: data?.length,
                        paginationTotalRenderer: customTotal,
                        sizePerPageRenderer: customSizePerPage
                    })
                }>
                    {({ paginationProps, paginationTableProps }) => (
                        <>
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                <div className="d-block mb-4 mb-md-0">
                                    <BreadCrumbComponent
                                        items={breadCrumbItems}
                                    />
                                    <h4>{t("Daily Delivery Data")}</h4>
                                    <p className="mb-0">{t("Granular level Information for your closed temposheets")}</p>
                                </div>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}>
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => {
                                                        handleRefreshData();
                                                    }}
                                                    variant="outline-primary"
                                                    size="sm">
                                                    <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;{t("Refresh")}
                                                </Button>
                                                <ExcelDownloadReport
                                                    addTotal
                                                    data={data || []}
                                                    fileName={"delivery-data"}
                                                />
                                            </ButtonGroup>
                                        </div>
                                        <div>
                                            <div style={{
                                                fontSize: 11,
                                                paddingTop: 10,
                                                fontStyle: "italic"
                                            }}>This data will be refresh every hour automatically until the trip is closed</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-settings mt-2 mb-4">
                                <div style={{ gap: 8, display: "flex" }} className="w-50">
                                    <DateRangeSelector onSubmit={onDateSelected} filtersValue={filtersValue} />
                                </div>
                            </div>
                            <Card>
                                <div className="table-responsive py-2">
                                    <Card.Header style={{
                                        padding: "8px 12px"
                                    }}>
                                        <Row className="d-flex justify-content-between">
                                            <Col xs={12} md={4}>
                                                <Row className="d-flex">
                                                    <Col>
                                                        <DropdownComponent
                                                            onChange={(option) => {
                                                                setReportModeOption(option.value);
                                                            }}
                                                            width={300}
                                                            options={dailyDeliveryReportModesOptions()}
                                                            placeholder={t("Select mode")}
                                                        />
                                                    </Col>
                                                    <Col className="d-flex">
                                                        <Button
                                                            onClick={() => {
                                                                handleReportModeClick();
                                                            }}
                                                            variant="primary"
                                                            size="sm">
                                                            <FontAwesomeIcon icon={faFilePen} />&nbsp;&nbsp;{t("Apply")}
                                                        </Button>
                                                    </Col>


                                                </Row>
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex justify-content-md-end">
                                                <Search.SearchBar {...searchProps} placeholder={t("Search any value in table here")} />
                                                <Search.ClearSearchButton { ...searchProps } />
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Table
                                        noDataIndication={<EmptyTable />}
                                        {...baseProps}
                                        {...paginationTableProps}
                                        bodyClasses="border-0"
                                        classes="table-flush dataTable-table"
                                    />

                                    <Card.Footer>
                                        <Row>
                                            <Col xs={12} md={4} className="py-1">
                                                <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                                <Paginator.PaginationTotalStandalone {...paginationProps} />
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                                                <Paginator.PaginationListStandalone {...paginationProps} />
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </div>
                            </Card>
                        </>
                    )}
                </Paginator.PaginationProvider>
            )}
        </ToolkitProvider>
    )
}
export default DailyDeliveryReportComponent;