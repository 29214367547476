import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form } from '@themesberg/react-bootstrap';
import {
  faArrowRotateLeft,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { returnWarehouseDropdownOptions } from '../../utils/warehouse';
import DatePicker from '../../components/common/DatePicker';
import EditableDropDown from '../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';
import { returnEndOfDay } from '../../utils/datetime';

const TripFilters = ({ onSubmit, defaultFilter, isAdmin }) => {
  const { t } = useTranslation();
  const initialFormInput = {
    startDate: '',
    endDate: '',
    region: '',
    storeName: '',
  };
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const zones = useSelector((state) => state.user.config?.zones || {});
  const [formInput, setFormInput] = useState(defaultFilter || initialFormInput);

  const onFilterSubmit = () => {
    onSubmit(formInput);
  };

  return (
    <>
      <div
        className="table-settings mt-4 mb-4 p-3"
        style={{
          background: 'white',
          transition: 'max-height 0.7s ease-in-out',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '20px',
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          {isAdmin && (
            <>
              <Form.Group className="mb-2">
                <Form.Label>{t('Region')}</Form.Label>
                <EditableDropDown
                  id="region"
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      region: [e.value],
                    }))
                  }
                  value={formInput.region}
                  options={[
                    { label: 'All', value: 'All' },
                    ...arrayToDropdownOptions(Object.keys(zones)),
                  ]}
                  placeholder="Choose Region"
                  backgroundColor="#F0F0F0"
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>{t('Store Name')}</Form.Label>
                <EditableDropDown
                  id={'storeName'}
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      storeName: [e.value],
                    }))
                  }
                  value={formInput['storeName']}
                  options={returnWarehouseDropdownOptions(otherWarehouses)}
                  placeholder={'Choose Store Name'}
                  backgroundColor={'#F0F0F0'}
                ></EditableDropDown>
              </Form.Group>
            </>
          )}
          <Form.Group>
            <Form.Label>From Date</Form.Label>
            <DatePicker
              value={formInput?.startDate}
              backgroundColor="white"
              setValue={(date) => {
                setFormInput((prev) => {
                  return {
                    ...prev,
                    startDate: date,
                  };
                });
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>To Date</Form.Label>
            <DatePicker
              value={formInput?.endDate}
              backgroundColor="white"
              setValue={(date) => {
                setFormInput((prev) => {
                  return {
                    ...prev,
                    endDate: returnEndOfDay(date),
                  };
                });
              }}
            />
          </Form.Group>
        </div>
        <div
          style={{
            gridColumn: 'span 3',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            marginTop: '20px',
          }}
        >
          <Button
            variant="white"
            onClick={() => setFormInput(initialFormInput)}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
              background: '#EAEDF2',
            }}
          >
            <FontAwesomeIcon icon={faArrowRotateLeft} />
            &nbsp;&nbsp;{t('Reset')}
          </Button>
          <Button
            variant="white"
            className="fa-export"
            onClick={onFilterSubmit}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
              border: '1px solid #262B40',
            }}
          >
            <FontAwesomeIcon icon={faSliders} />
            &nbsp;&nbsp;{t('Apply Filter')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TripFilters;
