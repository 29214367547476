import {
  faStore,
  faPlus,
  faList,
  faWarehouse,
  faCartShopping,
  faUserPlus,
  faCheck,
  faHourglassStart,
  faFileInvoice,
  faBox,
  faTruckFast,
  faTruckRampBox,
  faCircleXmark,
  faBan,
  faExclamation,
  faHourglassEnd,
  faReceipt,
  faPersonCirclePlus,
  faUsers,
  faTentArrowTurnLeft,
  faMoneyBillTransfer,
  faCheckDouble,
  faCircleCheck,
  faTruck,
  faBoxesStacked,
  faArrowRightFromBracket,
  faMagnifyingGlassChart,
  faMotorcycle,
  faClock,
  faEdit,
  faTimes,
  faUserCircle,
  faLocationPinLock,
  faPeopleCarry,
  faRotate,
  faArrowRightArrowLeft,
  faArrowUp,
  faArrowDown,
  faChartLine,
  faCartPlus,
  faPenNib,
  faHouse,
  faDoorOpen,
  faBoxOpen,
  faBiking,
  faPhoneFlip,
  faPhone,
  faDownload,
  faMobilePhone,
  faBarChart,
  faChainBroken,
  faCity,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/brand/vijay-sales-logo.svg';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { Routes as AppRoutes } from '../../router/routes';

const navigationConfig = {
  Dashboard: {
    title: 'Dashboard',
    link: AppRoutes.OMS.Dashboard.Home.path,
    // icon: logo,
    image: logo,
  },
  Analytics : {
    title: 'Analytics',
    icon: faBarChart,
    eventKey: 'Analytics/',
    children: [
      {
        title: 'OverAll',
        link: AppRoutes.OMS.Analytics.overall.path,
        icon: faChartLine,
      },{
        title: 'Delivery',
        link: AppRoutes.OMS.Analytics.deliveryAnalysis.path,
        icon: faTruckFast,
      },{
        title : "Rejected",
        link : AppRoutes.OMS.Analytics.rejectionReport.path,
        icon : faBan
      },
      {
        title: 'Delayed',
        link: AppRoutes.OMS.Orders.Delayed.path,
        icon: faClock,
      },{
        title : "Courier" ,
        link : AppRoutes.OMS.Analytics.courierReport.path,
        icon : faTruck
      }
    ],
  },
  Stores: {
    title: 'Stores',
    icon: faStore,
    eventKey: 'stores/',
    children: [
      {
        title: 'Add Store',
        link: AppRoutes.Warehouse.Add.path,
        icon: faPlus,
      },
      {
        title: 'Stores List',
        link: AppRoutes.OMS.Store.Stores.path,
        icon: faList,
      },
    ],
  },
  Inventory: {
    title: 'Inventory',
    icon: faWarehouse,
    eventKey: 'inventory/',
    children: [
      {
        title: 'Add Product',
        link: AppRoutes.Admin.Product.Add.path,
        icon: faPlus,
      },
      {
        title: 'Products List',
        link: AppRoutes.OMS.Products.List.path,
        icon: faList,
      },
      {
        title: 'Product Inventory',
        link: AppRoutes.OMS.Products.Inventory.path,
        icon: faProductHunt,
      },
    ],
  },
  allOrders: {
    title: 'All Orders',
    link: AppRoutes.OMS.Orders.allOrders.path,
    icon: faMagnifyingGlassChart,
  },
  Orders: {
    title: 'Orders',
    icon: faCartShopping,
    eventKey: 'orders/',
    children: [
      {
        title: 'Assigned',
        link: AppRoutes.OMS.Orders.Assigned.path,
        icon: faUserPlus,
      },
      {
        title: 'Accepted',
        link: AppRoutes.OMS.Orders.Accepted.path,
        icon: faCheck,
      },
      {
        title: 'QC Passed',
        link: AppRoutes.OMS.Orders.Awaiting.path,
        icon: faHourglassStart,
      },
      {
        title: 'Target Shared',
        link: AppRoutes.OMS.Orders.ManualInvoice.path,
        icon: faPenNib,
      },
      {
        title: 'Invoiced',
        link: AppRoutes.OMS.Orders.Invoice.path,
        icon: faFileInvoice,
      },
      {
        title: 'Ready For Pickup',
        link: AppRoutes.OMS.Orders.ReadyForPickup.path,
        icon: faBox,
      },
      {
        title: 'storePickup Pending',
        link: AppRoutes.OMS.Orders.customerPickup.path,
        icon: faBoxOpen,
      },
      {
        title: 'HyperLocal Pending',
        link: AppRoutes.OMS.Orders.hyperLocalPending.path,
        icon: faBiking,
      },
      {
        title: 'Courier Pending',
        link: AppRoutes.OMS.Orders.courierPending.path,
        icon: faTruck,
      },
      {
        title: 'Shipped',
        link: AppRoutes.OMS.Orders.Picked.path,
        icon: faTruckFast,
      },
      {
        title: 'Out For Delivery',
        link: AppRoutes.OMS.Orders.outForDelivery.path,
        icon: faPeopleCarry,
      },
      {
        title: 'Delivered',
        link: AppRoutes.OMS.Orders.Delivered.path,
        icon: faTruckRampBox,
      },
      {
        title: 'Cancelled',
        link: AppRoutes.OMS.Orders.Cancelled.path,
        icon: faTimes,
      },
      {
        title: 'Pending Action',
        link: AppRoutes.OMS.Orders.PendingAction.path,
        icon: faExclamation,
      },
      {
        title: 'Out Of Stock',
        link: AppRoutes.OMS.Orders.OutOfStock.path,
        icon: faDoorOpen,
      },
      {
        title: 'On Hold',
        link: AppRoutes.OMS.Orders.onHold.path,
        icon: faCircleXmark,
      },
      {
        title: 'Pre-Booked',
        link: AppRoutes.OMS.Orders.PreBooked.path,
        icon: faReceipt,
      },
      {
        title: 'Pre-Registered',
        link: AppRoutes.OMS.Orders.PreRegistered.path,
        icon: faPersonCirclePlus,
      },
      {
        title : "InterCity Orders",
        link : AppRoutes.OMS.Orders.intercityOrders.path,
        icon : faCity

      },
      {
        title: 'All Orders',
        link: AppRoutes.OMS.Orders.allOrders.path,
        icon: faMagnifyingGlassChart,
      },
      {
        title: 'Orders Data',
        link: AppRoutes.OMS.Orders.Filters.path,
        icon: faMagnifyingGlassChart,
      },
      {
        title: 'Auto Rejected',
        link: AppRoutes.OMS.Orders.AutoRejected.path,
        icon: faHourglassEnd,
      },
      {
        title: 'Manual Reject',
        link: AppRoutes.OMS.Orders.Rejected.path,
        icon: faBan,
      },
    ],
  },
  CustomerPickup: {
    title: 'Store Pickup',
    icon: faUserCircle,
    eventKey: 'customer/',
    children: [
      {
        title: 'Pending',
        link: AppRoutes.OMS.CustomerPickup.Pending.path,
        icon: faClock,
      },
      {
        title: 'Completed',
        link: AppRoutes.OMS.CustomerPickup.Completed.path,
        icon: faCheckDouble,
      },
    ],
  },
  HyperLocal: {
    title: 'HyperLocal Orders',
    icon: faLocationPinLock,
    eventKey: 'hyperlocal/',
    children: [
      ,
      {
        title: 'Hyperlocal Orders',
        link: AppRoutes.OMS.DeliveryAgents.GetHyperLocalOrders.path,
        icon: faClock,
      },
    ],
  },
  EndlessAisle: {
    title: 'EndlessAisle Orders',
    icon: faCartPlus,
    eventKey: 'endlessAisle/',
    children: [
      {
        title: 'Pending',
        link: AppRoutes.OMS.EndlessAisle.Pending.path,
        icon: faExclamation,
      },
      {
        title: 'Processing',
        link: AppRoutes.OMS.EndlessAisle.Processing.path,
        icon: faClock,
      },
    ],
  },
  Returns: {
    title: 'Returns',
    icon: faTentArrowTurnLeft,
    eventKey: 'return/',
    children: [
      {
        title: 'Reverse Requested',
        link: AppRoutes.OMS.Returns.Requested.path,
        icon: faList,
      },
      {
        title: 'Reverse Accepted',
        link: AppRoutes.OMS.Returns.Accepted.path,
        icon: faCheck,
      },
      {
        title: 'Returns Shipped',
        link: AppRoutes.OMS.Returns.Picked.path,
        icon: faTruckFast,
      },
      {
        title: 'Returns Received',
        link: AppRoutes.OMS.Returns.Received.path,
        icon: faStore,
      },
      {
        title: 'Returns QC Passed',
        link: AppRoutes.OMS.Returns.QC.path,
        icon: faCircleCheck,
      },
    {
      title : "Lost In Transit",
      link : AppRoutes.OMS.Returns.LostInTransit.path,
      icon : faChainBroken
    }
    ],
  },
  Refund: {
    title: 'Refund',
    icon: faMoneyBillTransfer,
    eventKey: 'refund/',
    children: [
      {
        title: 'Refund Initiated',
        link: AppRoutes.OMS.Returns.RefundInitiated.path,
        icon: faMoneyBillTransfer,
      },
      {
        title: 'Refunded',
        link: AppRoutes.OMS.Returns.Refunded.path,
        icon: faCheckDouble,
      },
    ],
  },
  IMEI : {
    title: 'IMEI',
    icon: faMobilePhone,
    eventKey: 'orders/IMEI',
    children: [
      {
        title: 'IMEI',
        link: AppRoutes.OMS.Orders.IMEI.path,
        icon: faMobilePhone,
      }
    ],
  },
  DeliveryAgent: {
    title: 'Delivery Agent',
    icon: faMotorcycle,
    eventKey: 'deliveryagent/',
    children: [
      {
        title: 'Analysis',
        link: AppRoutes.OMS.DeliveryAgents.Analysis.path,
        icon: faChartLine,
      },
      {
        title: 'Manual Assign',
        link: AppRoutes.OMS.DeliveryAgents.ManualAssign.path,
        icon: faPersonCirclePlus,
      },
      {
        title: 'Edit HyperLocal Data',
        link: AppRoutes.OMS.DeliveryAgents.ManageHyperLocal.path,
        icon: faEdit,
      },
    ],
  },
  Users: {
    title: 'Users',
    icon: faUsers,
    eventKey: 'users/',
    children: [
      {
        title: 'Add Incharge',
        link: AppRoutes.Incharge.Add.path,
        icon: faPlus,
      },
      {
        title: 'Add Delivery Agent',
        link: AppRoutes.OMS.Users.AddDeliveryAgent.path,
        icon: faPlus,
      },
      {
        title: 'Users List',
        link: AppRoutes.OMS.Users.List.path,
        icon: faList,
      },
      {
        title: 'Delivery Agents List',
        link: AppRoutes.OMS.Users.DeliveryAgentsList.path,
        icon: faList,
      },
      {
        title: 'Add Region User',
        link: AppRoutes.OMS.Users.AddUser.path,
        icon: faPlus,
      },
    ],
  },

  InterBranch: {
    title: 'Inter Branch',
    icon: faArrowRightArrowLeft,
    eventKey: 'IBT/',
    children: [
      {
        title: 'Create',
        link: AppRoutes.OMS.InterBranchTransfers.Create.path,
        icon: faPlus,
      },
      {
        title: 'Incoming Material',
        link: AppRoutes.OMS.InterBranchTransfers.Sent.path,
        icon: faArrowUp,
      },
      {
        title: 'Outgoing Material',
        link: AppRoutes.OMS.InterBranchTransfers.Received.path,
        icon: faArrowDown,
      },
    ],
  },
  Admin: {
    title: 'Admin',
    icon: faUsers,
    eventKey: 'admin/',
    children: [
      {
        title: 'Serviceability',
        link: AppRoutes.OMS.Serviceability.Test.path,
        icon: faTruck,
      },
      {
        title: 'Block Serviceability',
        link: AppRoutes.OMS.Serviceability.Manual.path,
        icon: faHouse,
      },
      {
        title: '3PL Integrations',
        link: AppRoutes.OMS.DeliveryIntegrations.List.path,
        icon: faBoxesStacked,
      },
      {
        title: 'Payment Authorization',
        link: AppRoutes.OMS.Payment.Authorization.path,
        icon: faCheckDouble,
      },
      {
        title: 'Products Release',
        link: AppRoutes.OMS.Products.Released.path,
        icon: faArrowRightFromBracket,
      },
      {
        title: 'Inventory Sync',
        link: AppRoutes.OMS.Products.Sync.path,
        icon: faRotate,
      },
      {
        title: 'GST Management',
        link: AppRoutes.OMS.GST.Validation.path,
        icon: faBan,
      },
    ],
  },
  Reports: {
    title: 'Downloaded Reports',
    link: AppRoutes.OMS.Orders.reports.path,
    icon: faDownload,
  },
  RegionOrders: {
    title: 'QC Passed Orders',
    link: AppRoutes.OMS.RegionOrders.Awaiting.path,
    icon: faHourglassStart,
  },
};

export default navigationConfig;
