import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Row,
} from '@themesberg/react-bootstrap';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../actions/spinner';
import { returnValidationErrorMessages } from '../../utils/validation';
import { maxLoaders } from '../../constants/dispatch';
import { dispatchTemporaryTrip } from '../../parse-functions/trips';
import { useParams } from 'react-router-dom';
import { updateToastInfo } from '../../actions/settings';
import { setTemporaryTripAsDispatched } from '../../actions/trips';
import DropdownComponent from '../../components/common/Dropdown';
import { returnVehicleDropdownOptions } from '../../utils/vehicles';
import { vehicleNumberSchemaValidation } from '../../utils/planning';
import { returnDropdownOptionsForLoadersList } from '../../utils/loaders';
import { StyledErrorMessage } from '../../utils/messages';
import { getEntryByVehicleNumber } from '../../parse-functions/vehicleEntries';

const temporaryTripDispatchSchema = Joi.object({
  vehicleNumber: Joi.string()
    .required()
    .custom(vehicleNumberSchemaValidation, 'Invalid Vehicle Number'),
  vehicleDetails: Joi.object().required(),
  loaderName1: Joi.string().required(),
  loaderName2: Joi.string().required(),
  loaderName3: Joi.string().allow(''),
  loaderName4: Joi.string().allow(''),
  loaderName5: Joi.string().allow(''),
  loaderNumber1: Joi.string().required(),
  loaderNumber2: Joi.string().required(),
  loaderNumber3: Joi.string().allow(''),
  loaderNumber4: Joi.string().allow(''),
  loaderNumber5: Joi.string().allow(''),
});

const temporaryAutoTripDispatchSchema = Joi.object({
  vehicleNumber: Joi.string()
    .required()
    .custom(vehicleNumberSchemaValidation, 'Invalid Vehicle Number'),
  vehicleDetails: Joi.object().required(),
  loaderName1: Joi.string().allow(''),
  loaderName2: Joi.string().allow(''),
  loaderName3: Joi.string().allow(''),
  loaderName4: Joi.string().allow(''),
  loaderName5: Joi.string().allow(''),
  loaderNumber1: Joi.string().allow(''),
  loaderNumber2: Joi.string().allow(''),
  loaderNumber3: Joi.string().allow(''),
  loaderNumber4: Joi.string().allow(''),
  loaderNumber5: Joi.string().allow(''),
});

const TemporaryDispatchModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { objectId } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const [disableDispatchButton, setDisableDispatchButton] = useState(false);
  const loadersList = useSelector((state) => state?.loaders?.loadersList || []);

  const [formData, setFormData] = useState({
    vehicleNumber: '',
    vehicleDetails: {},
    loaderName1: '',
    loaderName2: '',
    loaderName3: '',
    loaderName4: '',
    loaderName5: '',
    loaderNumber1: '',
    loaderNumber2: '',
    loaderNumber3: '',
    loaderNumber4: '',
    loaderNumber5: '',
  });
  const vehiclesList = useSelector(
    (state) => state?.drivers?.driversList || {}
  );
  const configData = useSelector((state) => state.user.config || {});
  const [showVehicleNumberField, setShowVehicleNumberField] = useState(false);
  const [loadersListOptions, setLoadersListOptions] = useState([]);
  const [mandatoryLoaders, setMandatoryLoaders] = useState(true);

  const { showModal, closeModal } = props;

  useEffect(() => {
    if (loadersList?.length > 0) {
      setLoadersListOptions(returnDropdownOptionsForLoadersList(loadersList));
    }
  }, [loadersList]);

  const handleCloseModal = () => {
    closeModal(false);
  };
  const onConfirm = async () => {
    const schemaToValidate = mandatoryLoaders
      ? temporaryTripDispatchSchema
      : temporaryAutoTripDispatchSchema;
    const { error: formErrors = {}, value: formValue } =
      schemaToValidate.validate(formData, {
        abortEarly: false,
      });
    const { details = {} } = formErrors;
    if (details?.length > 0) {
      const validationErrorMessages = returnValidationErrorMessages(details);
      setFormErrors(validationErrorMessages);
    } else {
      setDisableDispatchButton(true);
      // again check for vehicle number format
      dispatch(updateSpinnerState(true));
      try {
        setFormErrors({});
        await dispatchTemporaryTrip({
          data: formData,
          objectId,
          status: 'confirmed',
        });
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: t('Trip Confirmed.'),
            message: t('You can now print the sheet and EWB'),
          })
        );
        dispatch(updateSpinnerState(false));
        dispatch(setTemporaryTripAsDispatched({ ...formData }));
        handleCloseModal();
        setDisableDispatchButton(false);
      } catch (e) {
        setDisableDispatchButton(false);
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: t('Trip Confirmation Failed'),
            message: t(
              e.message
                ? e.message
                : 'Trip Confirmation Failed. Please contact support'
            ),
          })
        );
      }
    }
  };
  const onFormInputChange = (fieldName, value) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [fieldName]: value,
    }));
  };
  const returnLoaderFields = () => {
    const loaderInfoFields = [];
    for (let i = 0; i < maxLoaders; i += 1) {
      loaderInfoFields.push(
        <Row className="my-2">
          <DropdownComponent
            onChange={(option) => {
              onFormInputChange(`loaderName${i + 1}`, option?.value?.name);
              onFormInputChange(
                `loaderNumber${i + 1}`,
                option?.value?.mobileNo?.toString()
              );
            }}
            options={loadersListOptions}
            placeholder={`Select Loader ${i + 1}`}
          />
          {formErrors[`loaderName${i + 1}`]?.length > 0 && (
            <StyledErrorMessage>Please select loader</StyledErrorMessage>
          )}
          <div></div>
        </Row>
      );
    }
    return loaderInfoFields;
  };

  const updateDriverDetails = async (vehicleNumber, vehicleDetails) => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await getEntryByVehicleNumber({ vehicleNumber });
      if (res.error) {
        onFormInputChange('vehicleDetails', vehicleDetails);
      } else {
        const { createdAt, updatedAt, ...rest } = res;
        onFormInputChange('vehicleDetails', { ...vehicleDetails, ...rest });
      }
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('failed to get Vehicle details'),
          message: t(error.message.error),
        })
      );
    }
  };
  return (
    <Modal
      as={Modal.Dialog}
      size="lg"
      centered
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">{t('Confirm Trip')}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Row>
              <Col xs={10} className={'offset-1'}>
                <Row>
                  <Alert variant="info">
                    <Row>
                      <Col md={7}>{t('Vehicle Details here')}</Col>
                      <Col md={5} className="d-flex justify-content-end">
                        <Form.Check
                          type="switch"
                          label="Get All"
                          id="vehicleZoneSwitch"
                          htmlFor="vehicleZoneSwitch"
                          onChange={(event) => {
                            if (event.target.checked) {
                              props.getAllVehiclesFromSystem();
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </Alert>
                </Row>
                <Row>
                  <Form.Group className="mb-3 mt-2 d-flex p-0">
                    <Col xs={12}>
                      <Form.Label>{t('Vehicle Number')}</Form.Label>
                      <DropdownComponent
                        onChange={(option) => {
                          if (
                            configData.manualEntryVehicles?.indexOf(
                              option.value
                            ) > -1
                          ) {
                            // this is a manual vehicle chosen
                            onFormInputChange('vehicleDetails', {
                              vehicleNumber: option.value,
                            });
                            setShowVehicleNumberField(true);
                            setMandatoryLoaders(false);
                          } else {
                            // this is an actual vehicle chosen
                            onFormInputChange(
                              'vehicleNumber',
                              option.value.vehicleNumber
                            );
                            updateDriverDetails(
                              option.value.vehicleNumber,
                              option.value
                            );
                            // onFormInputChange("vehicleDetails", option.value);
                            setShowVehicleNumberField(false);
                            setMandatoryLoaders(true);
                          }
                        }}
                        options={returnVehicleDropdownOptions(
                          vehiclesList,
                          configData.manualEntryVehicles || []
                        )}
                        placeholder={t('Please Select a Vehicle')}
                      />
                      {showVehicleNumberField && (
                        <Form.Control
                          className="mt-2"
                          placeholder={t('Enter Vehicle Number here')}
                          isInvalid={formErrors['vehicleNumber']?.length}
                          type="text"
                          onChange={(event) => {
                            onFormInputChange(
                              'vehicleNumber',
                              event.target.value
                            );
                          }}
                        />
                      )}
                      {formErrors['vehicleNumber']?.length > 0 && (
                        <Form.Control.Feedback type="invalid">
                          {t(formErrors['vehicleNumber'])}
                        </Form.Control.Feedback>
                      )}
                      {Object.keys(formData.vehicleDetails)?.length > 0 && (
                        <div className="mt-3">
                          <Row>
                            <Col>{t('Vehicle Number:')}</Col>
                            <Col>{formData.vehicleDetails?.vehicleNumber}</Col>
                          </Row>
                          <Row>
                            <Col>{t('Vehicle Type:')}</Col>
                            <Col>{formData.vehicleDetails?.vehicleType}</Col>
                          </Row>
                          <Row>
                            <Col>{t('Driver Name:')}</Col>
                            <Col>{formData.vehicleDetails?.driverName}</Col>
                          </Row>
                          <Row>
                            <Col>{t('Driver Contact:')}</Col>
                            <Col>{formData.vehicleDetails?.contactNumber}</Col>
                          </Row>
                          <Row>
                            <Col>{t('Vehicle Vendor:')}</Col>
                            <Col>{formData.vehicleDetails?.vendorName}</Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="p-0">
              <Col xs={10} className="offset-1 p-0">
                <Alert variant="info">
                  <Row>
                    <Col md={5}>{t('Loader Details')}</Col>
                    <Col md={7} className="d-flex justify-content-end">
                      <Form.Check
                        type="switch"
                        label="Get All"
                        id="loadersZoneSwitch"
                        htmlFor="loadersZoneSwitch"
                        onChange={(event) => {
                          if (event.target.checked) {
                            props.getAllLoadersInSystem();
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Alert>

                {returnLoaderFields()}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              fontSize: 12,
            }}
            className="d-flex justify-content-center"
          >
            If loaders are not available in list, Please visit&nbsp;
            <a href="/loaders/list">Loader Page</a>&nbsp;to upload the sheet
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={disableDispatchButton}
          variant="secondary"
          onClick={onConfirm}
        >
          {t('Confirm')}
        </Button>
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={handleCloseModal}
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default TemporaryDispatchModal;
