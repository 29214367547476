import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import sideimage from "../../../assets/img/Image.png";
import logoImage from "../../../assets/img/brand/vs-logo.png";

const LoyaltyOTP = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const mobileNumber = location.state?.mobileNumber;

  useEffect(() => {
    if (!mobileNumber) {
      navigate("/rewards/login");
    }
  }, [mobileNumber, navigate]);

  const handleChange = (index, value) => {
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setError(false);
    setSuccess(false);

    if (value && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === "Enter" && index === 3) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const enteredOTP = otp.join("");
    const correctOTP = "8888";
    if (enteredOTP !== correctOTP) {
      setError(true);
      setSuccess(false);
      setOtp(["", "", "", ""]);
      inputRefs.current[0].focus();
    } else {
      setError(false);
      setSuccess(true);
      setTimeout(() => {
        navigate("/rewards/form", { state: { mobileNumber } });
      }, 500);
    }
  };

  return (
    <div
      className="d-flex shadow-lg"
      style={{
        width: "100VW",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#fff"
      }}
    >
      <div
        className="d-flex flex-column justify-content-between overflow-auto"
        style={{ width: "100%", minWidth: "50%", flex: "1", zIndex: 2 ,boxSizing:"border-box"}}
      >
      <div
        className="w-100 h-5 bg-white d-flex justify-content-start p-4 py-5 align-items-center"
        style={{ position: "absolute", top: 0, height: "5vh" ,zIndex:3 }}
      >
        <img
          src={logoImage}
          alt="Logo"
          className="img-fluid"
          style={{ maxWidth: "160px" }}
        />
      </div>
        <div style={{height:"35%"}}></div>
        <div className="p-4" style={{ width: "100%", height: "90%"}}>
          <h3 className="fw-bold mb-3">Enter OTP</h3>
          <p style={{ fontSize: "14px", marginBottom: "20px" }}>
            We've sent a 4-digit OTP to {mobileNumber}.
          </p>
          <br />
          <br />
          <div className="d-flex justify-content-between mb-3" style={{ maxWidth: "500px", margin: "0" }}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                style={{
                  width: "60px",
                  height: "60px",
                  textAlign: "center",
                  fontSize: "20px",
                  border: `2px solid ${error ? "red" : success ? "green" : "#ccc"}`,
                  borderRadius: "8px",
                  outline: "none",
                  color: error ? "red" : success ? "green" : "black",
                }}
              />
            ))}
          </div>
          {error && <p style={{ color: "red", textAlign: "center" }}>Incorrect OTP. Please try again.</p>}
          {success && <p style={{ color: "green", textAlign: "center" }}>OTP Verified Successfully!</p>}
          <br />
          <br />
          <div className="d-flex justify-content-between" style={{ marginTop: "20px", maxWidth: "500px" }}>
            <button
              onClick={handleSubmit}
              style={{
                width: "48%",
                backgroundColor: "#000",
                color: "#fff",
                padding: "14px",
                fontSize: "16px",
                fontWeight: "bold",
                border: "none",
                borderRadius: "6px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
            <button
              style={{
                width: "48%",
                backgroundColor: "transparent",
                color: "#000",
                padding: "14px",
                fontSize: "16px",
                fontWeight: "bold",
                border: "2px solid #000",
                borderRadius: "6px",
                cursor: "pointer",
              }}
            >
              Resend OTP
            </button>
          </div>
        </div>
        <div></div>
      </div>
      <img
        src={sideimage}
        alt="Promo"
        className="d-none d-lg-flex"
        style={{ width: "50%", height: "100%", backgroundSize: "cover", backgroundPosition: "center", borderRadius: "12px", padding: "20px",zIndex:99 }}
      />
    </div>
  );
};

export default LoyaltyOTP;