export const vendorUserschema = [
  [
    {
      title: 'Vendor User Info',
      type: 'header',
    },
    {
      title: 'Name',
      id: 'name',
      type: 'text',
      required: true,
    },
    {
      title: 'Email Id',
      id: 'emailId',
      type: 'email',
      required: false,
    },
    {
      title: 'Brand',
      id: 'company',
      type: 'select',
      isMultiSelect: false,
      options: [],
      required: true,
      placeholder: 'Select a Brand',
    },
    {
      title: 'Category',
      id: 'category',
      type: 'select',
      isMultiSelect: true,
      options: [],
      required: true,
      placeholder: 'Select Category/Categories',
    },
  ],

  [
    {
      title: 'Login Info',
      type: 'header',
    },
    {
      title: 'UserName',
      id: 'userName',
      type: 'text',
      required: true,
    },
    {
      title: 'Password',
      id: 'password',
      type: 'text',
      required: true,
    },
    {
      title: 'Repeat Password',
      id: 'repeatPassword',
      type: 'text',
      required: true,
    },
    {
      title: 'Create user',
      type: 'button',
      width: '30%',
    },
  ],
];
