import { returnUserReadableLocalDate } from '../datetime';

export const exportSchemeDataSummary = (data) => {
  const result =
    data &&
    data?.flatMap((item) =>
      item.products.map((row) => ({
        Brand: item?.company?.join(', '),
        SchemeType: item.type[0],
        SchemeCircularDate: returnUserReadableLocalDate(
          item?.schemeCircularDate
        ),
        SchemeCircularNo: item.schemeCircularNo,
        ValidFrom: returnUserReadableLocalDate(item?.validFrom),
        ValidTill: returnUserReadableLocalDate(item?.validTill),
        ProductCategory: item?.productCategory?.join(', '),
        SubCategory: item?.subCategory?.join(', '),
        SubCategory2: item?.subCategory2?.join(', '),
        SubCategory3: item?.subCategory3?.join(', '),
        Product: row?.productName,
        VAN: row?.vanNo,
        ValuationType: row?.valuationType,
        Payout: row?.payout,
        CappingType: row.cappingType?.[0],
        'Capping Value/Qty': row?.cappingAmount,
        Status: item.status,
        SchemeValue: item?.schemeSummary?.totalValue,
        SchemeQuantity: item?.schemeSummary?.totalQuantity,
        Remarks: item?.Remarks,
        'Sold/Purchase Quantity':
          item?.schemeSummary?.calculationReference?.[row.vanNo]
            ?.totalPositiveQuantity,
        'Return Quantity':
          item?.schemeSummary?.calculationReference?.[row.vanNo]
            ?.totalNegativeQuantity,
        'Open Quantity':
          item?.schemeSummary?.calculationReference?.[row.vanNo]
            ?.totalOpenQuantity,
        'Closing stock':
          item?.schemeSummary?.calculationReference?.[row.vanNo]?.totalStock,
      }))
    );
  return result;
};

export const exportSchemeData = (schemeData, data, type) => {
  // let result = schemeData.flatMap((item) =>
  //   item.products.map((row) => {
  //     const currentProduct = data.find(
  //       (product) => product.vanNo === row.vanNo
  //     );
  //     return {
  //       Brand: item?.company?.join(', '),
  //       SchemeType: item.type[0],
  //       SchemeCircularDate: returnUserReadableLocalDate(
  //         item?.schemeCircularDate
  //       ),
  //       SchemeCircularNo: item.schemeCircularNo,
  //       ValidFrom: returnUserReadableLocalDate(item?.validFrom),
  //       ValidTill: returnUserReadableLocalDate(item?.validTill),
  //       ProductCategory: item?.productCategory?.join(', '),
  //       SubCategory: item?.subCategory?.join(', '),
  //       SubCategory2: item?.subCategory2?.join(', '),
  //       SubCategory3: item?.subCategory3?.join(', '),
  //       Product: row?.productName,
  //       VAN: row?.vanNo,
  //       ValuationType: row?.valuationType,
  //       Payout: row?.payout,
  //       CappingType: row.cappingType?.[0],
  //       'Capping Value/Qty': row?.cappingAmount,
  //       Status: item.status,
  //       SchemeValue: item?.schemeSummary?.totalValue,
  //       SchemeQuantity: item?.schemeSummary?.totalQuantity,
  //       Remarks: item?.Remarks,
  //       'Sold/Purchase Quantity': currentProduct.,
  //       'Return Quantity': '',
  //       'Open Quantity': '',
  //       'Closing stock': '',
  //     };
  //   })
  // );
  let result = [];
  switch (type) {
    case 'Sell-in':
      result = data.map((item) => {
        const obj = {
          PurchaseDate: returnUserReadableLocalDate(item.PurchaseDate),
          PurchaseVNo: item.PurchaseVNo,
          Branch: item.Branch,
          Product: item.Product,
          VanNo: item.VanNo,
          Brand: item.company,
          TotalAmount: formatDecimal(
            item.TotalQuantity < 0 ? -item.TotalAmount : item.TotalAmount
          ),
          TotalQuantity: item.TotalQuantity,
          TotalIGST: formatDecimal(item.TotalIGST),
          TotalCGST: formatDecimal(item.TotalCGST),
          TotalSGST: formatDecimal(item.TotalSGST),
          Unitrate: item.Unitrate,
          TotalGSTAmount: formatDecimal(item.TotalGSTAmount),
          SerialNo: item.SerialNo,
          ReferenceBillNo: item.ReferenceBillNo,
          ReferenceBillDate: returnUserReadableLocalDate(
            item.ReferenceBillDate
          ),
          SchemeValue: item?.schemeSummary?.totalValue,
          SchemeQuantity: item?.schemeSummary?.totalQuantity,
        };
        return obj;
      });
      break;
    case 'Sell-out':
      result = data.map((item) => {
        const obj = {
          OrderNo: item.orderNo,
          OrderDate: item.orderDate?.split('T')[0],
          Branch: item.branch,
          VanNo: item.vanNo,
          Product: item.product,
          Quantity: item.quantity,
          Amount: formatDecimal(item.quantity < 0 ? -item.amount : item.amount),
          CGST: formatDecimal(item.CGST),
          SGST: formatDecimal(item.SGST),
          IGST: formatDecimal(item.IGST),
          GSTAmount: formatDecimal(item.gstAmount),
          InvoiceNumber: item.invoiceNo,
          SerialNumber: item.serialNo,
          ProductCode: item.productCode,
          Brand: item.brand,
          ReturnVoucherNo: item.returnVoucherNo,
          Reason: item.reason,
          TowardsVNo: item.towardsVNo,
          OrderType: item.orderType,
          SchemeValue: item?.schemeSummary?.totalValue,
          SchemeQuantity: item?.schemeSummary?.totalQuantity,
        };
        return obj;
      });
      break;
    default:
      result = data;
      break;
  }
  return result;
};

const formatDecimal = (cell) => {
  return cell.toFixed(2);
};

export const exportSchemeIndexReport = (data) => {
  const result =
    data &&
    data?.flatMap((item) =>
      item.products.map((row) => ({
        Brand: item?.company?.join(', '),
        SchemeType: item.type[0],
        SchemeCircularDate: returnUserReadableLocalDate(
          item?.schemeCircularDate
        ),
        SchemeCircularNo: item.schemeCircularNo,
        ValidFrom: returnUserReadableLocalDate(item?.validFrom),
        ValidTill: returnUserReadableLocalDate(item?.validTill),
        ProductCategory: item?.productCategory?.join(', '),
        SubCategory: item?.subCategory?.join(', '),
        SubCategory2: item?.subCategory2?.join(', '),
        SubCategory3: item?.subCategory3?.join(', '),
        Product: row?.productName,
        VAN: row?.vanNo,
        ValuationType: row?.valuationType,
        Payout: row?.payout,
        CappingType: row.cappingType?.[0],
        'Capping Value/Qty': row?.cappingAmount,
        Status: item.status,
      }))
    );
  return result;
};
