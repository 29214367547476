import {
  Badge,
  Form,
  OverlayTrigger,
  Tooltip,
} from '@themesberg/react-bootstrap';
import React from 'react';
import { returnUserReadableDateTime } from './datetime';
import { orderStatusMap } from './constants';
import { getTripBadgeStatus } from './trips';

export const getTableRowCheckBox = (
  onSelectAll,
  id = 'tableSelectAll',
  isChecked,
  isDisabled = false
) => (
  <Form.Check
    disabled={isDisabled}
    className="d-flex justify-content-center"
    defaultValue={isChecked || false}
    checked={isChecked}
    value={isChecked}
    onChange={(event) => {
      event.stopPropagation();
      onSelectAll(event.target.checked, id);
    }}
    label=""
    id={id}
    htmlFor={id}
  />
);

export const getColumnHeaders = (colNames, onSelectAll) => {
  const colData = [];
  // eslint-disable-next-line no-unused-expressions
  colNames?.forEach((colName) => {
    if (colName === 'checkBox') {
      colData.push(getTableRowCheckBox(onSelectAll));
    } else {
      colData.push(<th className="border-bottom">{colName}</th>);
    }
  });
  return colData;
};

export const returnCustomerAddressString = (
  customerAddress,
  pincode,
  width = 300,
  limit = 35,
  tooltipPosition = 'top'
) => {
  if (customerAddress?.length > limit) {
    return (
      <div
        className="d-block"
        style={{
          // maxWidth: width,
          overflow: 'clip',
          overflowWrap: 'anywhere',
          textOverflow: 'ellipsis',
        }}
      >
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement={tooltipPosition}
          overlay={<Tooltip>{customerAddress}</Tooltip>}
        >
          <div className="fw-bold">{`${customerAddress.substring(
            0,
            limit
          )}...`}</div>
        </OverlayTrigger>
        {pincode && <div className="fw-normal">{pincode || '-'}</div>}
      </div>
    );
  }
  return (
    <div
      className="d-block"
      style={{
        // maxWidth: width,
        overflow: 'clip',
        overflowWrap: 'anywhere',
        textOverflow: 'ellipsis',
      }}
    >
      <div className="fw-bold">{customerAddress || '-'}</div>
      {pincode && <div className="fw-normal">{pincode || '-'}</div>}
    </div>
  );
};

export const returnLoaderInfoFromDispatchData = (row) => {
  const maxLoaders = 5;
  const loaderInfo = [];
  for (let i = 1; i < maxLoaders + 1; i += 1) {
    if (
      row?.dispatchData?.[`loaderName${i}`] ||
      row?.dispatchData?.[`loaderNumber${i}`]
    ) {
      loaderInfo.push(
        `${row?.dispatchData?.[`loaderName${i}`]} (${
          row?.dispatchData?.[`loaderNumber${i}`]
        })`
      );
    }
  }
  return (
    <div className="d-block">
      {loaderInfo?.map((data) => {
        return (
          <div>{returnCustomerAddressString(data, null, 200, 30, 'top')}</div>
        );
      })}
    </div>
  );
};

export const returnParcelVehicleInformation = (row) => {
  return (
    <div className="d-block">
      <div className="fw-bold">
        <span>{row?.tripNumber}</span>
      </div>
      <div className="fw-normal">
        {row?.dispatchData?.vehicleDetails?.vehicleNumber}
      </div>
      <div className="fw-normal">
        {row?.dispatchData?.dispatchedAt
          ? returnUserReadableDateTime(row?.dispatchData?.dispatchedAt)
          : '-'}
      </div>
    </div>
  );
};

export const returnParcelReturnScanInformation = (row) => {
  return (
    <div className="d-block">
      <div className="fw-normal">
        <div>
          {row?.returnScanWarehouse ? `${row?.returnScanWarehouse}` : ''}
        </div>
        <div>{row?.returnScannedBy ? `${row?.returnScannedBy}` : ''}</div>
        <div className="fw-normal">
          {row?.returnScannedAt
            ? returnUserReadableDateTime(row?.returnScannedAt)
            : ''}
        </div>
      </div>
    </div>
  );
};

export const returnProductBuybackInformation = (row) => {
  return (
    <div className="d-block">
      <div className="fw-normal">
        <div>
          {row?.buyback ? (
            <Badge bg={getTripBadgeStatus('')} className="badge-lg w-100">
              {'Buyback'}
            </Badge>
          ) : (
            '-'
          )}
        </div>
        <div>{row?.buybackProduct ? `${row?.buybackProduct}` : ''}</div>
      </div>
    </div>
  );
};

export const returnProductDispatchScanInformation = (row) => {
  return (
    <div className="d-block">
      <span className="fw-bold">{row?.scannedBy}</span>
      <div className="fw-normal">
        {row?.scannedAt ? returnUserReadableDateTime(row?.scannedAt) : '-'}
      </div>
    </div>
  );
};
export const returnBuybackScanInformation = (row) => {
  return (
    <div className="d-block">
      <div className="fw-normal">
        <div>
          {row?.buybackReceivedWarehouse
            ? `${row?.buybackReceivedWarehouse}`
            : ''}
        </div>
        <div>{row?.buybackReceivedBy ? `${row?.buybackReceivedBy}` : ''}</div>
        <div>
          {row?.buybackReceivedAt
            ? returnUserReadableDateTime(row?.buybackReceivedAt)
            : ''}
        </div>
        <div className="fw-bold">
          {row?.buybackRefNo ? `Buyback Ref: ${row?.buybackRefNo}` : ''}
        </div>
      </div>
    </div>
  );
};
export const returnBuybackReceivedInformation = (row) => {
  return (
    <div className="d-block">
      <div className="fw-normal">
        <div>
          {row?.buybackReceivedWarehouse
            ? `${row?.buybackReceivedWarehouse}`
            : ''}
        </div>
        <div>{row?.buybackReceivedBy ? `${row?.buybackReceivedBy}` : ''}</div>
        <div>
          {row?.buybackReceivedAt
            ? returnUserReadableDateTime(row?.buybackReceivedAt)
            : ''}
        </div>
        <div className="fw-bold">
          {row?.wingsTransferNo ? `Transfer No: ${row?.wingsTransferNo}` : ''}
        </div>
      </div>
    </div>
  );
};

export const returnParcelReturnReceivedInfo = (row) => {
  return (
    <div className="d-block">
      <div className="fw-normal">
        {returnUserReadableDateTime(row?.receivedAt)}
      </div>
      <div className="fw-bold">{row?.receivedBy}</div>
      <div className="fw-bold">{row?.receiveScanWarehouse}</div>
    </div>
  );
};

export const returnParcelStatusInformation = (row) => {
  return (
    <div className="d-block">
      <div className="fw-bold">{`${
        orderStatusMap[row?.dashboardStatus || row?.mobileStatus]
      } (${
        orderStatusMap[row?.dashboardSubStatus || row?.mobileStatus]
      })`}</div>
      <span className="fw-normal">{orderStatusMap[row?.failureReason]}</span>
    </div>
  );
};

export const returnParcelRescheduleInformation = (row) => {
  if (!row?.rescheduledBy) {
    return '';
  }
  return (
    <div className="d-block">
      <div className="fw-bold">{`${row?.rescheduleTargetBranch}`}</div>
      <span className="fw-normal">{row?.rescheduledBy}</span>
    </div>
  );
};

export const returnParcelProductInformation = (
  row,
  width = 300,
  length = 30
) => {
  return (
    <div className="d-block">
      <div className="fw-normal">
        {returnCustomerAddressString(row?.product, '', width, length)}
      </div>
      {/* <div className="fw-normal">{row?.productCategory}</div> */}
      {row?.serialNo && <div className="fw-bold">{row?.serialNo}</div>}
      {/* <div className="fw-bold">{row?.productCode ? `Model: ${row?.productCode}` : ""}</div> */}
    </div>
  );
};

export const returnParcelVehicleTripInfo = (row) => {
  return (
    <div className="d-block">
      <div className="fw-bold">
        <span>{row?.tripNumber || row?.dispatchData?.vehicleNumber}</span>
      </div>
      <span className="fw-normal">{row?.dispatchData?.vehicleNumber}</span>
      {(row?.dispatchData?.dispatchedAt?.iso ||
        row?.dispatchData?.dispatchedAt) && (
        <div className="fw-normal">
          Dispatched:{' '}
          {row?.dispatchData?.dispatchedAt?.iso
            ? returnUserReadableDateTime(row?.tripInfo?.dispatchedAt?.iso)
            : row?.dispatchData?.dispatchedAt
            ? returnUserReadableDateTime(row?.dispatchData?.dispatchedAt)
            : '-'}
        </div>
      )}
      {(row?.createdAt?.iso || row?.createdAt) &&
        !row?.status === 'notPlannedForDelivery' && (
          <div className="fw-normal">
            Created:{' '}
            {row?.createdAt?.iso
              ? returnUserReadableDateTime(row?.createdAt?.iso)
              : row?.createdAt
              ? returnUserReadableDateTime(row?.createdAt)
              : '-'}
          </div>
        )}
    </div>
  );
};

export const returnParcelStatusInfo = (row) => {
  const status = row?.dashboardStatus || row?.mobileStatus || row?.status;
  const subStatus = row?.dashboardSubStatus || row?.mobileSubStatus;
  return (
    <div className="d-block">
      <div className="fw-normal">
        <div>
          <Badge bg={getTripBadgeStatus(status)} className="badge-lg w-100">
            {orderStatusMap[status]}
          </Badge>
        </div>
        <div>
          {subStatus && (
            <Badge
              bg={getTripBadgeStatus(subStatus)}
              className="badge-lg w-100"
            >
              {orderStatusMap[subStatus]}
            </Badge>
          )}
        </div>
      </div>
      <div className="fw-bold">{orderStatusMap[row?.failureReason]}</div>
      <div className="fw-bold">
        {row?.remarks
          ? returnCustomerAddressString(row?.remarks, '', 200, 50)
          : ''}
      </div>
      {row?.priorityDelivery && (
        <>
          <Badge bg={'success'} className="badge-lg">
            {'Priority Delivery'}
          </Badge>
        </>
      )}
    </div>
  );
};
export const returntripClosingInfo = (row) => {
  return (
    <div className="d-block">
      <div className="fw-bold">
        <div>{row?.closedByWarehouse ? row?.closedByWarehouse : ''}</div>
        <div>{row?.closedBy}</div>
        <div className="fw-normal">
          {row?.closedAt ? returnUserReadableDateTime(row?.closedAt) : '-'}
        </div>
      </div>
    </div>
  );
};

export const returnParcelBranchInfo = (row) => {
  return (
    <div className="d-block">
      <div className="fw-bold">
        <div>{row?.branch ? row?.branch : ''}</div>
        <div>{row?.targetBranch ? `Target: ${row?.targetBranch}` : ''}</div>
      </div>
    </div>
  );
};
