import React, { useState } from 'react';
import CategoryUpdateModal from '../../inventory/components/CategoryUpdateModal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import { apiCallConsts } from '../../../../constants/apiCallConsts';
import { apiCall } from '../../../../middlewares/api';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CategorySafety = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const userName = useSelector((state) => state?.user?.user?.username || '');

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const handleShowCategoryModal = () => {
    setShowCategoryModal(true);
  };

  const handleCategoryModalSubmit = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(
        apiCallConsts.PUT_METHOD,
        `${apiCallConsts.INVENTORY_UPDATE_URL}/categorySafetyStock`,
        { ...data, userName }
      );
      setShowCategoryModal(false);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Category saftey stock updated!');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'safety stock updated Failed', error.message);
    }
  };

  return (
    <div>
      {!roles?.includes('inchargeOMS') && (
        <Button
          onClick={handleShowCategoryModal}
          variant="white"
          size="md"
          className="btn-export"
        >
          <FontAwesomeIcon icon={faPen} />
          &nbsp;&nbsp; {t('Update Category Stock')}
        </Button>
      )}
      {showCategoryModal && (
        <CategoryUpdateModal
          showModal={showCategoryModal}
          closeModal={() => setShowCategoryModal(false)}
          handleSubmit={handleCategoryModalSubmit}
        />
      )}
    </div>
  );
};

export default CategorySafety;
