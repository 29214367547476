import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  productListColumns,
  productsOptions,
  safetySummaryModalColumns,
} from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import NewTable from '../../../components/common/NewTable';
import {
  faArrowRotateLeft,
  faBroom,
  faCheck,
  faExclamationCircle,
  faRotateRight,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Form } from '@themesberg/react-bootstrap';
import ExcelDownloadReport from '../../../components/common/excel/download';
import { debounce } from '../../../utils/debounce';
import AsyncDropdown from '../../../components/AsyncDropdown';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import RejectModal from '../interBranchTransfers/components/RejectModal';
import InventoryModal from '../inventory/components/InvenoryModal';
import {
  setProductByVanNoInStore,
  setProductsMapInStore,
} from '../../../actions/oms/allProducts';
import { setPageNumberInStore } from '../../../actions/tablePage';
import CardDropdown from '../inventory/components/CardDropDown';
import SummaryModal from './components/SummaryModal';
import MoreOptionInProductsList from './components/MoreOptionInProductsList';

const ProductsList = () => {
  const { t } = useTranslation();
  const productsMap = useSelector(
    (state) => state?.allProducts?.productsMap || {}
  );
  const companies = useSelector((state) => state?.allProducts?.companies || []);
  const categories = useSelector(
    (state) => state?.allProducts?.categories || []
  );
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const userName = useSelector((state) => state?.user?.user?.username || '');
  // const [products, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openFilter, setOpenFilter] = useState(true);
  const [selectedVanNo, setSelectedVanNo] = useState(null);
  const [selectedVanNos, setSelectedVanNos] = useState([]);
  const [vanNo, setVanNo] = useState([]);
  const [name, setName] = useState([]);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  // const [vanNoOptions, setVanNoOptions] = useState([]);
  // const [nameOptions, setNameOptions] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [summaryModalData, setSummaryModalData] = useState([]);
  const [summaryModal, setSummaryModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [showUpdateInventoryModal, setShowUpdateInventoryModal] =
    useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const [isFileData, setIsFileData] = useState(false);
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vanNoOptions = useMemo(
    () => arrayToDropdownOptions(Object.keys(productsMap)),
    [productsMap]
  );
  const nameOptions = useMemo(
    () =>
      arrayToDropdownOptions(
        Object.values(productsMap).map((product) => product?.productTitle || '')
      ),
    [productsMap]
  );
  const [modalTitles, setModalTitles] = useState({
    modalTitle: '',
    modalSubTitle: '',
  });

  const filterObj = [
    { label: 'VAN NO', value: vanNo?.map((e) => e?.value), func: setVanNo },
    {
      label: 'PRODUCT NAME',
      value: name?.map((e) => e?.value),
      func: setName,
    },
    { label: 'CATEGORY', value: category, func: setCategory },
    { label: 'BRAND', value: brand, func: setBrand },
  ];

  const handleReset = () => {
    setVanNo([]);
    setName([]);
    setCategory([]);
    setBrand([]);
    setFilteredData([])
    setFlag((flag) => !flag);

    const queryParams = new URLSearchParams(location.search);

    if (vanNo) {
      queryParams.delete('vanNo');
    }
    if (name) {
      queryParams.delete('name');
    }
    if (category) {
      queryParams.delete('category');
    }
    if(brand)
    {
      queryParams.delete("brand")
    }
    navigate(`${location.pathname}?${queryParams.toString()}`);


    setFlag((flag) => !flag);
  };

  const fetchOptions = useCallback(
    debounce(async (inputValue, options) => {
      if (inputValue.length > 2) {
        try {
          const filteredOptions = options?.filter((product) =>
            product.value.toLowerCase().includes(inputValue.toLowerCase())
          );
          return filteredOptions;
        } catch (e) {
          console.log(e);
        }
      }
    }),
    []
  );

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedVanNos((prev) => {
        const selectedItem = filteredData?.find(
          (item) => item?.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedVanNos((prev) =>
        prev.filter((item) => item?.objectId !== objectId)
      );
    }
    setFilteredData((prev) =>
      prev?.map((item) =>
        item?.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const location = useLocation();

  const fetchProducts = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const productsMap = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/products/all?isOnlyVanNo=true'
      );
      const productsArray = Object.values(productsMap);
      // setProducts(productsArray);
      dispatch(setProductsMapInStore(productsMap));
      // setVanNoOptions(arrayToDropdownOptions(Object.keys(productsMap)));
      // setNameOptions(
      //   arrayToDropdownOptions(
      //     Object.values(productsMap).map(
      //       (product) => product?.productTitle || ''
      //     )
      //   )
      // );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    }
  };

  const fetchProductsData = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const productsArray = await apiCall(
        apiCallConsts.GET_METHOD,
        `internal/products/all?vanNo=${vanNo?.map((each)=>each?.value)}&category=${category}&brand=${brand}`
      );
      // setProducts(productsArray);
      dispatch(setProductsMapInStore(
         {
          ...productsMap, ...productsArray
        }
      ))
      setFilteredData(Object.values(productsArray))
      // setVanNoOptions(arrayToDropdownOptions(Object.keys(productsMap)));
      // setNameOptions(
      //   arrayToDropdownOptions(
      //     Object.values(productsMap).map(
      //       (product) => product?.productTitle || ''
      //     )
      //   )
      // );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    }
  };

  const onActionClick = (option, vanNo) => {
    if (option === productsOptions.markEndlessAisle) {
      // handle endless aisle
      setModalType(option);
      setModalTitles({
        modalTitle: 'Mark EndlessAisle',
        modalSubTitle:
          'Please enter no of days it will take to delivery the product?',
      });
      setConfirmationModalProps([
        {
          title: 'Delivery Days',
          type: 'number',
          value: '',
        },
      ]);
      setSelectedVanNo(vanNo);
      setShowUpdateInventoryModal(true);
    } else if (option === productsOptions.markManualInventory) {
      setModalType(option);
      setModalTitles({
        modalTitle: 'Mark Manual Inventory',
        modalSubTitle:
          'Please add inventory after marking this as manual inventory product',
      });
      setConfirmationModalProps([]);
      setSelectedVanNo(vanNo);
      setShowUpdateInventoryModal(true);
    } else if (option === productsOptions.markSystemInventory) {
      setModalType(option);
      setModalTitles({
        modalTitle: 'Mark System Inventory',
        modalSubTitle:
          'Are you sure to mark this product as an System Inventory Product?',
      });
      setConfirmationModalProps([]);
      setSelectedVanNo(vanNo);
      setShowUpdateInventoryModal(true);
    } else if (option === productsOptions.markVirtual) {
      setModalType(option);
      setModalTitles({
        modalTitle: 'Mark Virtual Product',
        modalSubTitle:
          'Are you sure to mark this product as a Virtual Product?',
      });
      setConfirmationModalProps([]);
      setSelectedVanNo(vanNo);
      setShowUpdateInventoryModal(true);
    } else if (option === productsOptions.updateSafetyStock) {
      // handle update
      setSelectedVanNo(vanNo);
      setUpdateModal(true);
    } else if (option === productsOptions.updateInventory) {
      setSelectedVanNo(vanNo);
      dispatch(setProductByVanNoInStore(vanNo));
      navigate(`/oms/products/updateInventory`);
    } else {
      dispatch(setProductByVanNoInStore(vanNo));
      navigate(`/oms/products/${vanNo}`);
    }
  };

  const filterSubmit = () => {
    const queryParams = new URLSearchParams(location.search);

    if (vanNo?.length > 0) {
      queryParams.set('vanNo',vanNo?.map(each =>each?.value ).join(','));
    }
    if (name?.length > 0) {
      queryParams.set('name',name?.map((each)=>each?.value).join(''));
    }
    if (category?.length > 0) {
      queryParams.set('category',category);
    }
    if(brand?.length > 0)
    {
      queryParams.set("brand",brand)
    }
    setFlag((flag) => !flag);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  const handleEmpty = async (fun) => {
    fun([]);
    setFlag((flag) => !flag);
  };

  const handleSubmit = () => {
    if (
      modalType !== productsOptions.markSystemInventory &&
      modalType !== productsOptions.markManualInventory &&
      modalType !== productsOptions.markVirtual &&
      (confirmationModalProps[0].length === 0 ||
        !confirmationModalProps[0].value)
    ) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: 'Please enter value before submitting',
        })
      );
      return;
    }
    handleModalSubmit();
    setModalType('');
  };

  const handleModalSubmit = async () => {
    let data;
    if (selectedVanNos.length > 0) {
      data = selectedVanNos.map((van) => {
        const key =
          modalType === productsOptions.markEndlessAisle
            ? 'isEndlessAisle'
            : 'isManualInventory';
        const value =
          modalType === productsOptions.markSystemInventory ? false : true;

        const vanData = {
          sku: van.vanNo,
          [key]: value,
        };

        if (modalType === productsOptions.markEndlessAisle) {
          vanData.edd = parseInt(confirmationModalProps[0].value);
        }

        return vanData;
      });
    } else {
      const key =
        modalType === productsOptions.markEndlessAisle
          ? 'isEndlessAisle'
          : modalType === productsOptions.markVirtual
          ? 'vsProductType'
          : 'isManualInventory';
      const value =
        modalType === productsOptions.markVirtual
          ? 'Digital / Serviceable'
          : modalType === productsOptions.markSystemInventory
          ? false
          : true;
      data = [
        {
          sku: selectedVanNo,
          [key]: value,
        },
      ];
      if (modalType === productsOptions.markEndlessAisle) {
        data[0].edd = parseInt(confirmationModalProps[0].value);
      }
    }
    let apiData = {
      data: data,
    };
    if (selectedVanNos.length === 0) {
      apiData.source = productsMap[selectedVanNo]?.source;
    }
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.POST_METHOD,
        'products/update',
        {...apiData,userName}
      );
      // const updatedMap = {
      //   ...productsMap,
      //   ...(selectedVanNos.length > 0
      //     ? selectedVanNos.reduce((acc, van) => {
      //         acc[van.vanNo] = {
      //           ...productsMap[van.vanNo],
      //           ...data.find((d) => d.sku === van.vanNo),
      //         };
      //         if (modalType === productsOptions.markVirtual) {
      //           acc[van.vanNo].vsProductType = 'Digital / Serviceable';
      //           acc[van.vanNo].size = 'Digital / Serviceable';
      //         }
      //         return acc;
      //       }, {})
      //     : {
      //         [selectedVanNo]: {
      //           ...productsMap[selectedVanNo],
      //           ...data[0],
      //           ...(modalType === productsOptions.markVirtual && {
      //             vsProductType: 'Digital / Serviceable',
      //             size: 'Digital / Serviceable',
      //           }),
      //         },
      //       }),
      // };
      // dispatch(setProductsMapInStore(updatedMap));
      // setProducts(Object.values(updatedMap));
      // setFilteredData(Object.values(updatedMap));
      setConfirmationModalProps([]);
      setSelectedVanNos([]);
      setSelectedVanNo(null);
      // await fetchProducts();
      // setTimeout(() => {
      // filterSubmit();
      // }, 10);
      setShowUpdateInventoryModal(false);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: 'Product Updated Successfully!',
        })
      );
      await fetchProductsData()
      // dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleSafetySubmit = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        apiCallConsts.INVENTORY_URL,
        { vanNo: selectedVanNo, isAdmin: true }
      );

      let modalData = [
        {
          available: response?.country || 0,
          safetyStockLimit: productsMap[selectedVanNo]?.safetyStockLimit || 0,
          change:
            data?.safetyStockLimit -
            productsMap[selectedVanNo]?.safetyStockLimit,
          newSafetyStockLimit: data?.safetyStockLimit || 0,
        },
      ];
      // console.log(modalData);

      setSummaryModalData(modalData);
      setUpdateModal(false);
      setSummaryModal(true);
      // console.log(response);
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const apiData = {
        data: isFileData
          ? summaryModalData.map((each) => ({
              sku: String(each.vanNo),
              safetyStockLimit: each.newSafetyStockLimit,
            }))
          : [
              {
                sku: selectedVanNo,
                safetyStockLimit: summaryModalData[0]?.newSafetyStockLimit,
              },
            ],
      };

      if (!isFileData) {
        apiData.source = productsMap[selectedVanNo]?.source;
      }

      dispatch(updateSpinnerState(true));
      await apiCall(apiCallConsts.POST_METHOD, 'products/update', {...apiData,userName});
      // const updatedMap = {
      //   ...productsMap,
      //   ...(isFileData
      //     ? summaryModalData.reduce((acc, van) => {
      //         acc[van.vanNo] = {
      //           ...productsMap[van.vanNo],
      //           safetyStockLimit: van?.newSafetyStockLimit || 0,
      //         };
      //         return acc;
      //       }, {})
      //     : {
      //         [selectedVanNo]: {
      //           ...productsMap[selectedVanNo],
      //           safetyStockLimit: summaryModalData[0]?.newSafetyStockLimit || 0,
      //           source: productsMap[selectedVanNo]?.source,
      //         },
      //       }),
      // };
      // setProducts(Object.values(updatedMap));
      // setFilteredData(Object.values(updatedMap));
      // dispatch(setProductsMapInStore(updatedMap));
      setIsFileData(false);
      setSummaryModal(false);
      setSummaryModalData([]);
      setSelectedVanNo(null);
      // filterSubmit();
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: 'SafteyStock Updated Successfully!',
        })
      );
      dispatch(updateSpinnerState(false));
      await fetchProductsData();
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    }
  };

  const handleCardChange = (e) => {
    console.log(e);
    const modalConfigurations = {
      markEndlessAisle: {
        modalTitle: 'Mark EndlessAisle',
        modalSubTitle:
          'Please enter no of days it will take to delivery the product?',
        modalType: productsOptions.markEndlessAisle,
        confirmationModalProps: [
          {
            title: 'Delivery Days',
            type: 'number',
            value: '',
          },
        ],
      },
      markManualInventory: {
        modalTitle: 'Mark Manual Inventory',
        modalSubTitle:
          'Please add inventory after marking this as manual inventory product',
        modalType: productsOptions.markManualInventory,
        confirmationModalProps: [],
      },
      markSystemInventory: {
        modalTitle: 'Mark System Inventory',
        modalSubTitle:
          'Are you sure to mark this product as a System Inventory Product?',
        modalType: productsOptions.markSystemInventory,
        confirmationModalProps: [],
      },
      markVirtual: {
        modalTitle: 'Mark Virtual Product',
        modalSubTitle:
          'Are you sure to mark this product as a Virtual Product?',
        modalType: productsOptions.markVirtual,
        confirmationModalProps: [],
      },
    };

    if (modalConfigurations[e?.value]) {
      const { modalTitle, modalSubTitle, modalType, confirmationModalProps } =
        modalConfigurations[e?.value];

      setModalTitles({ modalTitle, modalSubTitle });
      setModalType(modalType);
      setConfirmationModalProps(confirmationModalProps);
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: 'Please select a valid option',
        })
      );
    }

    setShowUpdateInventoryModal(true);
  };

  const uploadedData = (fileData = []) => {
    const fileUploadedData = fileData?.map((row) => ({
      vanNo: row['SKU'],
      safetyStockLimit: productsMap[row['SKU']]?.safetyStockLimit,
      change:
        Number(row['Safety Stock']) - productsMap[row['SKU']]?.safetyStockLimit,
      newSafetyStockLimit: row['Safety Stock'],
    }));
    setIsFileData(true);
    setSummaryModalData(fileUploadedData);
    setSummaryModal(true);
  };

  useEffect(() => {
    if (Object.keys(productsMap).length === 0) {
      fetchProducts();
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("vanNo")) {
      setVanNo(queryParams.get("vanNo")?.split(',')?.map((each) => {return {label : each , value : each}}))
    }
    if (queryParams.get("name")) {
      setName(queryParams.get("name")?.split(',')?.map((each) => {return {label : each , value : each}}))
    }
    if(queryParams.get("category"))
    {
      setCategory(queryParams.get("category")?.split(','))
    }
    if(queryParams.get('brand')){
      setBrand(queryParams.get("brand"))
    }
    setFlag(!flag)
  }, [location.search]);
  
  useEffect(() => {
   
    const data = async () => {
      if(category?.length > 0  ||
      vanNo?.length > 0 || name?.length > 0 || brand?.length > 0
     ){
      await fetchProductsData();
      }
    };
    data();
  }, [flag]);


  return (
    <>
      {/* <fieldset disabled={true}> */}
      <div className="fw-medium fs-4 my-2">{t('Products List')}</div>
      <div className="mt-2 d-flex justify-content-between">
        <div>
          {selectedVanNos.length > 0 && (
            <CardDropdown
              placeholder={t('Select Action')}
              options={[
                {
                  label: 'Mark Selected as Endless Aisle',
                  value: 'markEndlessAisle',
                },
                {
                  label: 'Mark Selected as Manual Inventory',
                  value: 'markManualInventory',
                },
                {
                  label: 'Mark Selected as System Inventory',
                  value: 'markSystemInventory',
                },
              ]}
              width={'20rem'}
              // value={cardFilters.store}
              onChange={handleCardChange}
            />
          )}
        </div>
        <div className="d-flex gap-3">
          <Button
            variant="white"
            size="md"
            className="btn-export"
            onClick={() => setOpenFilter(!openFilter)}
          >
            <FontAwesomeIcon icon={faSliders} />
            &nbsp;&nbsp;{t('Filter')}
          </Button>
          <Button
            variant="white"
            size="md"
            className="btn-export"
            onClick={fetchProducts}
          >
            <FontAwesomeIcon icon={faRotateRight} />
            &nbsp;&nbsp; {t('Refresh')}
          </Button>
          <ExcelDownloadReport
            data={Object.values(productsMap) || []}
            fileName="products"
          />
          <MoreOptionInProductsList safetyStockFileUpload={uploadedData} />
        </div>
      </div>
      <>
        <div
          className="table-settings mt-2 mb-4"
          style={{
            background: '#EAEDF2',
            maxHeight: openFilter ? '700px' : '0px',
            overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
            transition: 'max-height 300ms ease-in-out', // Smooth transition when toggling
          }}
        >
          <div style={{ gap: 8, display: 'flex' }}>
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)', // Three columns
                gap: '20px', // Gap between elements
                width: '100%',
                backgroundColor: 'white',
                padding: 20,
              }}
            >
              <div>
                <Form.Label>VAN NO.</Form.Label>
                <AsyncDropdown
                  onChange={(option) => {
                    dispatch(
                      setPageNumberInStore({ id: 'productsList', page: 1 })
                    );

                    console.log(option)
                    setVanNo(option);
                  }}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isMultiSelect={true}
                  value={vanNo}
                  backgroundColor={'#FFF'}
                  optionColor={'rgba(240, 240, 240, 1)'}
                  disabled={false}
                  fetchOptions={(e) => fetchOptions(e, vanNoOptions)}
                  placeholder={t('Select vanNo')}
                />
              </div>

              <div>
                <Form.Label>PRODUCT NAME</Form.Label>
                <AsyncDropdown
                  onChange={(option) => {
                    dispatch(
                      setPageNumberInStore({ id: 'productsList', page: 1 })
                    );
                    setName([option]);
                  }}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  value={name}
                  backgroundColor={'#FFF'}
                  optionColor={'light'}
                  disabled={false}
                  fetchOptions={(e) => fetchOptions(e, nameOptions)}
                  placeholder={t('Select field to Search by')}
                />
              </div>

              <div>
                <Form.Label>CATEGORY</Form.Label>
                <div>
                  <EditableDropDown
                    id={'category'}
                    onChange={(e) => {
                      dispatch(
                        setPageNumberInStore({ id: 'productsList', page: 1 })
                      );
                      setCategory(e.map((option) => option.value));
                    }}
                    style={{
                      height: 38,
                      width: '100%',
                      // backgroundColor: 'light-gray',
                    }}
                    isMultiSelect={true}
                    value={category}
                    dropdownBackgroundColor={'light'}
                    options={arrayToDropdownOptions(categories)}
                    placeholder={'Select Category'}
                  />
                </div>
              </div>

              <div>
                <Form.Label>BRAND</Form.Label>
                <EditableDropDown
                  id={'brand'}
                  onChange={(e) => {
                    dispatch(
                      setPageNumberInStore({ id: 'productsList', page: 1 })
                    );
                    setBrand([e.value]);
                  }}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  dropdownBackgroundColor={'light'}
                  value={brand}
                  options={arrayToDropdownOptions(companies)}
                  placeholder={'Select Brand'}
                />
              </div>

              <div
                style={{
                  gridColumn: 'span 3', // Span across all columns
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="white"
                  className="fa-export"
                  onClick={handleReset}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    background: '#EAEDF2',
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                  &nbsp;&nbsp;{t('Reset')}
                </Button>

                <Button
                  variant="white"
                  className="fa-export"
                  onClick={filterSubmit}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    border: '1px solid #262B40',
                  }}
                >
                  <FontAwesomeIcon icon={faSliders} />
                  &nbsp;&nbsp;{t('Apply Filter')}
                </Button>
              </div>
            </Form.Group>
          </div>
        </div>
        {!openFilter &&
          (vanNo?.length > 0 ||
            name?.length > 0 ||
            category != '' ||
            brand != '') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                className="gap-2"
                style={{
                  display: 'flex',
                  width: '100%',
                  background: '#F5F8FB',
                  padding: 20,
                }}
              >
                {filterObj.map((each) => {
                  if (each.value != '') {
                    return (
                      <div>
                        <Form.Label style={{ color: '#262B4099' }}>
                          {each.label}
                        </Form.Label>
                        <Badge
                          variant="light"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: 'auto',
                            height: '41px',
                            background: 'white',
                            borderRadius: '2px',
                            cursor: 'pointer',
                            color: '#262B40',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '16.41px',
                          }}
                        >
                          {each.value}
                          <span
                            onClick={() => handleEmpty(each.func)}
                            style={{
                              color: '#262B40',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '16.41px',
                            }}
                          >
                            &times;
                          </span>
                        </Badge>
                      </div>
                    );
                  }
                })}
              </div>
              <Button
                variant="primary"
                className="fa-export"
                onClick={handleReset}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                }}
              >
                <FontAwesomeIcon icon={faBroom} />
                &nbsp;&nbsp;{t('Clear')}
              </Button>
            </div>
          )}
      </>
      <NewTable
        id="productsList"
        data={filteredData || []}
        columns={productListColumns(onActionClick, onRowSelect, roles)}
      />
      {updateModal && (
        <InventoryModal
          showModal={updateModal}
          closeModal={() => setUpdateModal(false)}
          modalTitile={'Updating Safety Stock Limit'}
          handleSubmit={handleSafetySubmit}
          type={'safetyStockLimit'}
        />
      )}

      {showUpdateInventoryModal && (
        <RejectModal
          icon={
            modalType === productsOptions.updateInventory
              ? faExclamationCircle
              : faCheck
          }
          showModal={showUpdateInventoryModal}
          closeModal={() => setShowUpdateInventoryModal(false)}
          modalTitle={modalTitles.modalTitle}
          modalSubTitle={modalTitles.modalSubTitle}
          confirmActionName={'Confirm'}
          cancelActionName={'Cancel'}
          confirmationModalProps={confirmationModalProps}
          setConfirmationModalProps={setConfirmationModalProps}
          handleSubmit={handleSubmit}
        />
      )}

      {summaryModal && (
        <SummaryModal
          showModal={summaryModal}
          closeModal={() => setSummaryModal(false)}
          modalTitile={'Safety Stock Limit Update Summary'}
          data={summaryModalData || []}
          columns={safetySummaryModalColumns(isFileData)}
          handleSubmit={handleUpdateSubmit}
          isFooterRequired={false}
          summaryTitle="Impact"
          summaryMessage="Updating the safety stock at the product level will override the previous individual store-level safety stock values, ensuring a uniform safety stock across all locations. An additional 20 units are needed to maintain product serviceability."
        />
      )}

      {/* </fieldset> */}
    </>
  );
};

export default ProductsList;
