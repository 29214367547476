import React from 'react';
import { returnReadableDate } from '../datetime';
import { returnCustomerAddressString } from '../table';
import { formatToINR, schemeTypeMap } from './constantsAndUtils';
export const formatDecimal = (cell) => {
  if (typeof cell === 'number') {
    return cell.toFixed(2);
  }
  return cell;
};

export const individualSchemeTable = (type) => {
  switch (type) {
    case schemeTypeMap.sellIn:
      return [
        {
          dataField: 'Branch',
          sort: true,
          text: 'Branch',
        },
        {
          dataField: 'PurchaseDate',
          sort: true,
          text: 'Purchase Date',
          formatter: (cell, row) => {
            return returnReadableDate(cell);
          },
        },
        {
          dataField: 'Product',
          text: 'Product',
          formatter: (cell, row) => {
            return returnCustomerAddressString(cell, null, 200, 30, 'top');
          },
        },
        {
          dataField: 'PurchaseVNo',
          text: 'Voucher No.',
        },
        {
          dataField: 'TotalQuantity',
          text: 'Quantity',
        },
        {
          dataField: 'TotalAmount',
          text: 'Amount',
          formatter: formatToINR,
        },
        {
          dataField: 'TotalSGST',
          text: 'SGST',
          formatter: formatToINR,
        },
        {
          dataField: 'TotalCGST',
          text: 'CGST',
          formatter: formatToINR,
        },
        {
          dataField: 'TotalIGST',
          text: 'IGST',
          formatter: formatToINR,
        },
        {
          dataField: 'TotalGSTAmount',
          text: 'GST Amount',
          formatter: formatToINR,
        },
      ];
    case schemeTypeMap.priceDrop:
      return [
        {
          dataField: 'vanNo',
          text: 'VanNo',
        },
        {
          dataField: 'product',
          text: 'ProductName',
          formatter: (cell, row) => {
            return returnCustomerAddressString(cell, null, 200, 30, 'top');
          },
        },
        {
          dataField: 'storeName',
          text: 'Branch',
        },
        {
          dataField: 'totalStock',
          text: 'totalStock',
        },
      ];
    default:
      return [
        {
          dataField: 'orderDate',
          sort: true,
          text: 'Order Date',
          formatter: (cell, row) => {
            return returnReadableDate(cell);
          },
        },
        {
          dataField: 'branch',
          text: 'Branch',
        },
        {
          dataField: 'product',
          text: 'Product',
          formatter: (cell, row) => {
            return returnCustomerAddressString(cell, null, 200, 30, 'top');
          },
        },
        {
          dataField: 'serialNo',
          text: 'Serial No',
        },
        {
          dataField: 'invoiceNo',
          text: 'Invoice No',
          formatter: (cell, row) => {
            return <div>{cell || row?.returnVoucherNo || '-'}</div>;
          },
        },
        {
          dataField: 'orderNo',
          text: 'Order No',
        },
        {
          dataField: 'vanNo',
          text: 'VanNo',
        },
        {
          dataField: 'quantity',
          text: 'Quantity',
        },
        {
          dataField: 'amount',
          text: 'Amount',
          formatter: formatDecimal,
        },
        {
          dataField: 'SGST',
          text: 'SGST',
          formatter: formatDecimal,
        },
        {
          dataField: 'CGST',
          text: 'CGST',
          formatter: formatDecimal,
        },
        {
          dataField: 'IGST',
          text: 'IGST',
          formatter: formatDecimal,
        },
      ];
  }
};

export const sellOutTable = () => {
  return [
    {
      dataField: 'product',
      text: 'Product',
      formatter: (cell, row) => {
        return returnCustomerAddressString(cell, null, 300, 30, 'top');
      },
    },
    {
      dataField: 'vanNo',
      text: 'VanNo',
    },
    {
      dataField: 'payout',
      text: 'Payout',
      formatter: formatToINR,
    },
    {
      dataField: 'valuationType',
      text: 'Valuation',
    },
    {
      dataField: 'currentQuantity',
      text: 'Current Quantity',
    },
    {
      dataField: 'currentValue',
      text: 'Current Value',
      formatter: formatDecimal,
    },
    {
      dataField: 'totalPositiveQuantity',
      text: 'Sold/Purchase Quantity',
    },
    {
      dataField: 'totalNegativeQuantity',
      text: 'Return Qty',
    },
    {
      dataField: 'totalOpenQuantity',
      text: 'Open Qty',
    },
    {
      dataField: 'cappingType',
      text: 'Capping Type',
    },
    {
      dataField: 'cappingQuantity',
      text: 'Capping Qty',
    },
    ,
  ];
};

export const getSchemeDetailTable = (schemeType) => {
  let result = sellOutTable();
  switch (schemeType) {
    case 'Sell-in':
      result = sellOutTable().slice(0, 8);
      result.push({
        dataField: 'target',
        text: 'Target',
      });
      break;
    case 'Upgrade-offer':
      result = sellOutTable().slice(0, 9);
      result.push(
        {
          dataField: 'totalVsAmount',
          text: 'VS Amount',
          formatter: formatDecimal,
        },
        {
          dataField: 'totalBrandAmount',
          text: 'Brand Amount',
          formatter: formatDecimal,
        }
      );
      break;
    default:
      break;
  }
  return result;
};
