import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import { getAllSchemes } from '../../../../parse-functions/sms/scheme';
import { useNavigate } from 'react-router-dom';
import SchemeInfoModal from '../Scheme/SchemeInfoModal';
import { infoTable } from '../../../../utils/sms/infoTable';
import BulkAddSchemeModal from '../BulkAddSchemeModal';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
import {
  schemeTableRedesignColumns,
  ViewProductsTable,
} from '../../../../utils/sms/schemeTableRedesign';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from '@themesberg/react-bootstrap';
import ExcelDownloadReport from '../../../../components/common/excel/download';
import TableOnly from '../IndividualScheme/TableOnly';
import { setSchemesDataInStore } from '../../../../actions/sms/schemes';
import { StyledSearchBar } from '../../../trips/styles';
import { setPageNumberInStore } from '../../../../actions/tablePage';
import {
  exportSchemeDataSummary,
  exportSchemeIndexReport,
} from '../../../../utils/sms/exportSchemeData';
import { setSchemeOptionsInStore } from '../../../../actions/sms/schemeOptions';
import SchemeFilters from '../SchemRedesign/SchemeFilters';

const SchemeIndex = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );

  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );

  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );
  const schemes = useSelector((state) => state.schemes || {});
  const schemeIndexFilter = useSelector(
    (state) => state?.schemeOptions?.schemeIndexFilter || {}
  );

  const role = isAdmin
    ? 'isAdmin'
    : isBrandManager
    ? 'isBrandManager'
    : isBrandVendor
    ? 'isBrandVendor'
    : isAccountManager
    ? 'isAccountManager'
    : 'Unknown';
  const [schemeData, setSchemeData] = useState(schemes.schemeIndexReport || []);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoData, setInfoData] = useState();
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilterOptions, setShowFilterOptions] = useState(true);
  const [id, setId] = useState();

  let options = ['Action Timeline', 'View', 'Details'];

  const filteredData = schemeData?.filter((row) => {
    return Object.values(row).some((value) =>
      value?.toString()?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const fetchSchemes = async ({
    startDate,
    endDate,
    company,
    category,
    schemeType,
    status,
  }) => {
    try {
      dispatch(updateSpinnerState(true));
      if (startDate || endDate || company || category || schemeType || status) {
        dispatch(
          setSchemeOptionsInStore({
            type: 'schemeIndexFilter',
            data: { startDate, endDate, company, category, schemeType, status },
          })
        );
      }
      const response = await getAllSchemes({
        status: status,
        role: role,
        startDate,
        endDate,
        company,
        category,
        schemeType,
      });
      if (response.length === 0) {
        throw new Error('No schemes found!');
      }
      setSchemeData(response);
      dispatch(
        setSchemesDataInStore({ type: 'schemeIndexReport', data: response })
      );
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot fetch at the moment!',
          message: `${error.message}`,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleSelectedRowUpdate = (actionId, row) => {
    switch (actionId) {
      case 'View': {
        navigate(`/scheme/${row.objectId}/details`);
        break;
      }
      case 'Details': {
        navigate(`/scheme/schemeanalysis/${row.objectId}`);
        break;
      }
      case 'Action Timeline': {
        setInfoData(row.actionLog);
        setShowInfoModal(true);
      }
      default: {
        break;
      }
    }
  };

  const handleMoreProductsClick = (id) => {
    setId(id);
    setShowProductsModal(true);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbTitle breadcrumbs={[{ title: 'Scheme Index' }]} />
        </div>
        <div className="btn-toolbar mb-2 mb-md-0 gap-3">
          <StyledSearchBar
            width={'360.89px'}
            height={'46.78px'}
            type={'text'}
            value={searchTerm}
            placeholder={'Search value in the table'}
            onChange={(e) => {
              dispatch(setPageNumberInStore({ id: 'schemeIndex', page: 1 }));
              setSearchTerm(e.target.value);
            }}
          />
          <ButtonGroup className="gap-3">
            <Button
              variant={showFilterOptions ? 'primary' : 'white'}
              onClick={() => {
                setShowFilterOptions((prev) => !prev);
              }}
              size="md"
            >
              <FontAwesomeIcon icon={faSliders} />
              &nbsp;&nbsp;{t('Filter')}
            </Button>
            <ExcelDownloadReport
              data={exportSchemeIndexReport(schemeData) || []}
              fileName={`Scheme Index`}
            />
          </ButtonGroup>
        </div>
      </div>
      {showFilterOptions && (
        <SchemeFilters
          onSubmit={fetchSchemes}
          defaultFilter={schemeIndexFilter}
          isReport={true}
        />
      )}
      <TableOnly
        data={filteredData || []}
        columns={schemeTableRedesignColumns({
          handleMoreProductsClick,
          options: options,
          handleSelectedRowUpdate,
        })}
        id={'schemeIndex'}
      />
      <SchemeInfoModal
        showModal={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        data={infoData}
        columns={infoTable()}
      />
      {showProductsModal && (
        <BulkAddSchemeModal
          data={
            schemeData?.filter((item) => item.objectId === id)[0]?.products ||
            []
          }
          showModal={showProductsModal}
          closeModal={() => setShowProductsModal(false)}
          columns={ViewProductsTable(
            schemeData?.filter((item) => item.objectId === id)[0]
          )}
        />
      )}
    </>
  );
};
export default SchemeIndex;
