import React, { useEffect, useState } from 'react';
import DatePicker from '../../../../components/common/DatePicker';
import EditableDropDown from '../../../../components/common/EditableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setSchemeOptionsInStore } from '../../../../actions/sms/schemeOptions';
import { updateSpinnerState } from '../../../../actions/spinner';
import { getAllCompaniesCategoriesSubCategoriesData } from '../../../../parse-functions/sms/scheme';
import { arrayToDropdownOptions } from '../../../../utils/sms/arrayToDropdownOptions';
import { updateToastInfo } from '../../../../actions/settings';
import { useTranslation } from 'react-i18next';
import { Button, Form } from '@themesberg/react-bootstrap';
import {
  faArrowRotateLeft,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { schemeTypes } from '../../../../utils/sms/constantsAndUtils';
import { schemeStatus } from '../../../../utils/constants';

const SchemeFilters = ({ onSubmit, defaultFilter, isReport }) => {
  const { t } = useTranslation();
  const initialFormInput = {
    startDate: '',
    endDate: '',
    company: '',
    schemeType: '',
    category: '',
    status: '',
  };

  const schemeCompanies = useSelector(
    (state) => state?.schemeOptions?.schemeCompanies || []
  );
  const schemeCategories = useSelector(
    (state) => state?.schemeOptions?.schemeCategories || []
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const [formInput, setFormInput] = useState(defaultFilter || {});
  const dispatch = useDispatch();

  const onFilterSubmit = () => {
    onSubmit(formInput);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        let options = {};
        if (isAdmin) {
          options = await getAllCompaniesCategoriesSubCategoriesData();
          dispatch(
            setSchemeOptionsInStore({
              type: 'schemeCompanies',
              data: options.companies.map((item) => {
                return { label: item.name, value: item.name };
              }),
            })
          );
          dispatch(
            setSchemeOptionsInStore({
              type: 'schemeCategories',
              data: options.categories?.map((item) => {
                return { label: item.name, value: item.name };
              }),
            })
          );
        } else {
          dispatch(
            setSchemeOptionsInStore({
              type: 'schemeCompanies',
              data: arrayToDropdownOptions(
                userInfo?.company?.filter((item) => item !== 'All')
              ),
            })
          );
          dispatch(
            setSchemeOptionsInStore({
              type: 'schemeCategories',
              data: arrayToDropdownOptions(
                userInfo?.productCategory?.filter((item) => item !== 'All')
              ),
            })
          );
        }
      } catch (error) {
        console.log(error);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: t(`Error while fetching companies.`),
          })
        );
      } finally {
        dispatch(updateSpinnerState(false));
      }
    };
    if (schemeCompanies.length === 0 || schemeCategories.length === 0) {
      fetchData();
    }
  }, [userInfo]);

  return (
    <>
      <div
        className="table-settings mt-4 mb-4 p-3"
        style={{
          background: 'white',
          transition: 'max-height 0.7s ease-in-out',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '20px',
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <Form.Group>
            <Form.Label>From Date</Form.Label>
            <DatePicker
              value={formInput?.startDate}
              backgroundColor="white"
              setValue={(date) => {
                setFormInput((prev) => {
                  return {
                    ...prev,
                    startDate: date,
                  };
                });
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>To Date</Form.Label>
            <DatePicker
              value={formInput?.endDate}
              backgroundColor="white"
              setValue={(date) => {
                setFormInput((prev) => {
                  return {
                    ...prev,
                    endDate: date,
                  };
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t('Brand')}</Form.Label>
            <EditableDropDown
              id={'company'}
              onChange={(e) =>
                setFormInput((prev) => ({
                  ...prev,
                  company: e.map((item) => item.value),
                }))
              }
              value={formInput['company']}
              options={schemeCompanies}
              placeholder={'Choose Brand'}
              backgroundColor={'#F0F0F0'}
              isMultiSelect={true}
            ></EditableDropDown>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t('Category')}</Form.Label>
            <EditableDropDown
              id={'category'}
              onChange={(e) =>
                setFormInput((prev) => ({
                  ...prev,
                  category: e.map((item) => item.value),
                }))
              }
              value={formInput['category']}
              options={schemeCategories}
              placeholder={'Choose Category'}
              backgroundColor={'#F0F0F0'}
              isMultiSelect={true}
            ></EditableDropDown>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t('Scheme Type')}</Form.Label>
            <EditableDropDown
              id={'schemeType'}
              onChange={(e) =>
                setFormInput((prev) => ({
                  ...prev,
                  schemeType: [e.value],
                }))
              }
              value={formInput['schemeType']}
              options={arrayToDropdownOptions(schemeTypes)}
              placeholder={'Choose Scheme Type'}
              backgroundColor={'#F0F0F0'}
            ></EditableDropDown>
          </Form.Group>
          {isReport && (
            <Form.Group className="mb-2">
              <Form.Label>{t('Status')}</Form.Label>
              <EditableDropDown
                id={'status'}
                onChange={(e) =>
                  setFormInput((prev) => ({
                    ...prev,
                    status: [e.value],
                  }))
                }
                value={formInput['status']}
                options={arrayToDropdownOptions(schemeStatus)}
                placeholder={'Choose Scheme Status'}
                backgroundColor={'#F0F0F0'}
              ></EditableDropDown>
            </Form.Group>
          )}
        </div>
        <div
          style={{
            gridColumn: 'span 3',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            marginTop: '20px',
          }}
        >
          <Button
            variant="white"
            onClick={() => setFormInput(initialFormInput)}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
              background: '#EAEDF2',
            }}
          >
            <FontAwesomeIcon icon={faArrowRotateLeft} />
            &nbsp;&nbsp;{t('Reset')}
          </Button>
          <Button
            variant="white"
            className="fa-export"
            onClick={onFilterSubmit}
            style={{
              width: '150px',
              height: '38px',
              borderRadius: 0,
              border: '1px solid #262B40',
            }}
          >
            <FontAwesomeIcon icon={faSliders} />
            &nbsp;&nbsp;{t('Apply Filter')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SchemeFilters;
