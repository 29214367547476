import React from 'react';
import { returnUserReadableDateTime } from '../../utils/datetime';
import { Badge, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { returnLoaderInfoFromDispatchData } from '../../utils/table';

export const returnTemporaryTripColumns = () => {
  return [
    {
      dataField: 'tripNumber',
      text: 'Temposheet Number',
    },
    {
      dataField: 'vehicleNumber',
      text: 'Vehicle Number',
    },
    {
      dataField: 'vehicleArea',
      text: 'Vehicle Area',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
            <div className="fw-normal">{row?.vehicleArea}</div>
          </div>
        );
      },
    },
    {
      dataField: 'createdBy',
      text: 'Creation Info',
      formatter: (cell, row) => {
        return (
          <td>
            <div className="fw-bold">{cell}</div>
            <div className="fw-normal">
              {row?.createdAt
                ? returnUserReadableDateTime(row?.createdAt)
                : '-'}
            </div>
          </td>
        );
      },
    },
    {
      dataField: 'status',
      text: '',
      formatter: (cell, row) => {
        const statusVariant =
          row?.status === 'created'
            ? 'success'
            : row?.status === 'temporary'
            ? 'warning'
            : row?.status === 'Canceled'
            ? 'danger'
            : 'primary';
        return (
          <div style={{ width: 100, marginRight: 15 }}>
            <Badge
              bg={statusVariant}
              style={{ width: 90, textTransform: 'capitalize' }}
              className="badge-lg"
            >
              {row?.status}
            </Badge>
          </div>
        );
      },
    },
  ];
};

export const returnActiveTripColumns = (handlePrintClick) => {
  return [
    {
      dataField: 'tripNumber',
      text: 'Temposheet Number',
    },
    {
      dataField: 'vehicleNumber',
      text: 'Vehicle Number',
    },
    {
      dataField: 'vehicleArea',
      text: 'Vehicle Area',
    },
    {
      dataField: 'loaderInfo',
      text: 'Loader Info',
      formatter: (cell, row) => {
        return returnLoaderInfoFromDispatchData(row);
      },
    },
    {
      dataField: 'createdBy',
      text: 'Creation Info',
      formatter: (cell, row) => {
        return (
          <td>
            <div className="fw-bold">{cell}</div>
            <div className="fw-normal">
              {row?.createdAt
                ? returnUserReadableDateTime(row?.createdAt)
                : '-'}
            </div>
          </td>
        );
      },
    },
    {
      dataField: 'convertedBy',
      text: 'Converted Info',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="fw-bold">{row?.convertedBy}</div>
            <div className="fw-normal">
              {row?.dispatchedAt
                ? returnUserReadableDateTime(row?.dispatchedAt)
                : '-'}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'closedAt',
      text: 'Closed Info',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="fw-bold">{row?.closedBy}</div>
            <div className="fw-normal">
              {row?.closedAt ? returnUserReadableDateTime(row?.closedAt) : '-'}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'status',
      text: '',
      formatter: (cell, row) => {
        const statusVariant =
          row?.status === 'created'
            ? 'success'
            : row?.status === 'temporary'
            ? 'warning'
            : row?.status === 'Canceled'
            ? 'danger'
            : 'primary';
        return (
          <div
            style={{ width: 100, marginRight: 15 }}
            className="d-flex flex-column gap-2"
          >
            <Badge
              bg={statusVariant}
              style={{ width: 90, textTransform: 'capitalize' }}
              className="badge-lg"
            >
              {row?.status}
            </Badge>
            {row?.isPendingReceived && (
              <Badge
                bg={statusVariant}
                style={{ width: 120, textTransform: 'capitalize' }}
                className="badge-lg"
              >
                {'Pending Received'}
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'print',
      text: 'Print',
      formatter: (cell, row) => {
        if (row?.status !== 'pending') {
          return '';
        }
        return (
          <Button
            variant="secondary"
            size="sm"
            onClick={(event) => {
              handlePrintClick(event, row?.objectId);
            }}
          >
            <FontAwesomeIcon icon={faPrint} />
            &nbsp;&nbsp;{'Print Sheet'}
          </Button>
        );
      },
    },
  ];
};
