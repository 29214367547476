import React, { useState } from "react";
import Datetime from "react-datetime";
import { faCalendarAlt, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, InputGroup, Row, Col, Button } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import { returnEndOfMonth, returnStartOfMonth, returnUserReadableMonthYear } from "../../utils/datetime";


const DateMonthSelector = (props) => {
    const {
        filtersValue = {},
        onSubmit
    } = props;
    const { t } = useTranslation();
    const [selectedMonth, setSelectedMonth] = useState(filtersValue.selectedMonth ? new Date(filtersValue.selectedMonth) : returnStartOfMonth(new Date()));
    const [formErrors, setFormErrors] = useState({});
    const handleSubmit = () => {
        const formErrors = {
            selectedMonth: selectedMonth ? "" : t("Please Select Start Date")
        };
        setFormErrors(formErrors);
        if (formErrors?.selectedMonth?.length === 0) {
            // if we reached here, that means form is fine. pass this value to the parent
            onSubmit(returnStartOfMonth(selectedMonth), returnEndOfMonth(selectedMonth));
        }

    };
    return (
        <Form.Group className="w-100">
            <Row className="d-flex justify-content-start">
                <Col xs={5} md={5} >
                    <Datetime
                        dateFormat="YYYY-MM"
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={(selectedMonth) => {
                            setSelectedMonth(returnStartOfMonth(selectedMonth))
                        }}
                        renderInput={(props, openCalendar) => (
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs" /></InputGroup.Text>
                                <Form.Control
                                    isInvalid={formErrors["selectedMonth"]?.length > 0}
                                    required
                                    type="text"
                                    value={returnUserReadableMonthYear(selectedMonth)}
                                    placeholder="Select Month: mm/yyyy"
                                    onFocus={openCalendar}
                                />
                                {formErrors["selectedMonth"]?.length > 0 && (
                                    <Form.Control.Feedback type="invalid">{formErrors["selectedMonth"]}</Form.Control.Feedback>
                                )}
                            </InputGroup>
                        )} /></Col>
                <Col xs={5} md={5} >
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                    >
                        <FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp; {t("Filter")}
                    </Button>
                </Col>
            </Row>
        </Form.Group>
    )
};
export default DateMonthSelector;