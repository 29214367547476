import React from 'react';

export const vehicleAttendanceReportTableColumns = (headers) => {
  return headers?.map((keyName) => ({
    dataField: keyName,
    text: keyName,
    style: (cell) => ({
      backgroundColor: cell === 'P' ? '#caf5d5' : cell === 'A' ? '#f09693' : '',
    }),
  }));
};

export const monthlyVehicleDeliveryReportColumns = () => {
  return [
    { dataField: 'vehicleNumber', text: 'Vehicle Number' },
    { dataField: 'vehicleType', text: 'Vehicle Type' },
    { dataField: 'deliveryDate', text: 'Delivery Date' },
    { dataField: 'warehouseCode', text: 'Branch Name' },
    { dataField: 'vendorName', text: 'Vendor Name' },
    { dataField: 'products', text: 'Products' },
    { dataField: 'deliveryQuantity', text: 'Delivery Quantity' },
    { dataField: 'isDisplay', text: 'Is Display' },
    { dataField: 'branchDroppedAt', text: 'Branches' },
    { dataField: 'delivered', text: 'Delivered' },
    { dataField: 'pending', text: 'Pending' },
    { dataField: 'droppedathub', text: 'Dropped At Hub' },
    { dataField: 'returnok', text: 'Return OK' },
    { dataField: 'defectivepicked', text: 'Return Defective' },
  ];
};

export const paymentsReportColumns = () => {
  const columns = [
    {
      dataField: 'date',
      text: 'Date',
    },
    {
      dataField: 'vehicleDetails',
      text: 'Vehicle Number',
      formatter: (cell, row) => {
        return (
          <>
            <div>{cell.vehicleNumber}</div>
            <div>{cell.vehicleType}</div>
            <div>{cell.paymentMode}</div>
          </>
        );
      },
    },
    {
      dataField: 'vehicleDetails.vendorName',
      text: 'Vendor',
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: 'deliveryAmount',
      text: 'RATE',
      formatter: (cell, row) => {
        return cell.map((item) => <div>{item}</div>);
      },
    },
    {
      dataField: 'totalDays',
      text: 'Total Days',
    },
    {
      dataField: 'totalPresent',
      text: 'Total Present',
    },
    {
      dataField: 'totalTrips',
      text: 'Total Trips',
    },
    {
      dataField: 'totalDeliveryAttempted',
      text: 'Delivery Attempted',
    },
    {
      dataField: 'totalDeliveryQuantity',
      text: 'Delivered',
    },
    {
      dataField: 'totalFailed',
      text: 'Failed',
    },
    {
      dataField: 'droppedAtHub',
      text: 'DroppedAtHub',
    },
    {
      dataField: 'totalDisplay',
      text: 'Total Display/GT',
    },
    {
      dataField: 'totalDisplayProducts',
      text: 'Total GT Products',
    },
    {
      dataField: 'totalFailed',
      text: 'Total Failed',
    },
    {
      dataField: 'totalAmount',
      text: 'Total Amount',
      formatter: (cell, row) => {
        return <div>{cell?.toFixed(2) || 0}</div>;
      },
    },
  ];

  return columns;
};

export const exportPaymentsExcel = (exportData) => {
  const result = exportData.map((data) => ({
    date: data?.date,
    vehicleNumber: data?.vehicleDetails?.vehicleNumber,
    driverName: data?.vehicleDetails?.driverName,
    vendorName: data?.vehicleDetails?.vendorName,
    storeName: data?.vehicleDetails?.storeName,
    vehicleType: data?.vehicleDetails?.vehicleType,
    zone: data?.vehicleDetails?.zone,
    paymentMode: data?.vehicleDetails?.paymentMode,
    paymentAmount: data?.vehicleDetails?.paymentAmount,
    deliveryAmount: data?.deliveryAmount?.join(', ') || '',
    totalTrips: data?.totalTrips,
    totalPresent: data?.totalPresent,
    totalDeliveryAttempted: data?.totalDeliveryAttempted,
    totalDeliveryQuantity: data?.totalDeliveryQuantity,
    totalDisplay: data?.totalDisplay,
    totalDisplayProducts: data?.totalDisplayProducts,
    droppedAtHub: data?.droppedAtHub,
    droppedAtHubProducts: data?.droppedAtHubProducts,
    totalFailed: data?.totalFailed,
    pendingTrips: data?.pendingTrips,
    voucherAmount: data?.voucherAmount,
    totalAmount: data?.totalAmount,
  }));
  return result;
};
