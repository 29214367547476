import React, { useState } from 'react';
import BreadCrumbComponent from '../../components/common/Breadcrumb';
import { Button, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExcelDownloadReport from '../../components/common/excel/download';
import {
  faArrowRotateLeft,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import EditableDropDown from '../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';
import useFetchVendors from '../reports/useFetchVendors';
import DateRangeSelector from '../../components/common/DateRangeSelector';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { inputValidate, perDeliveryColumns } from './utils';
import {
  getTripsPaymentList,
  updateTripPayment,
} from '../../parse-functions/payments';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import TableOnly from '../scheme-management/Components/IndividualScheme/TableOnly';
import TripEditModal from './TripEditModal';
import { getTripDetailsByTripNumberFromServer } from '../../parse-functions/trips';
import TripSaveModal from './TripSaveModal';
import { ErrorMessage } from '../scheme-management/Components/StyledComponents';
import { returnWarehouseDropdownOptions } from '../../utils/warehouse';

const PerDeliveryPayments = () => {
  const initialFormInput = {
    vendorName: '',
    vehicleNumber: '',
    zone: '',
    storeName: '',
  };
  const [showFilterOptions, setShowFilterOptions] = useState(true);
  const vendorsList = useSelector((state) => state?.vendors?.vendorsList || {});
  const warehouse = useSelector((state) => state.user.warehouse || {});
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const [formInput, setFormInput] = useState(initialFormInput);
  const [formErrors, setFormErrors] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [tripsData, setTripsData] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [saveData, setSaveData] = useState({});
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const configData = useSelector((state) => state?.user?.config);
  const isWHPaymentUser = useSelector(
    (state) => state?.user?.preferences?.isWHPaymentUser || false
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const breadCrumbItems = [
    {
      title: t('PerDelivery Trips'),
      link: '',
    },
  ];
  const getUnpaidTripsData = async (filterData) => {
    try {
      dispatch(updateSpinnerState(true));
      const formData = { ...filterData };
      formData.fromDate = new Date(formData.fromDate);
      formData.fromDate.setHours(0, 0, 0, 0);

      formData.toDate = new Date(formData.toDate);
      formData.toDate.setHours(23, 59, 59, 999);
      formData.type = 'perDelivery';
      const inputValid = inputValidate(formData, setFormErrors);
      if (!inputValid) {
        dispatch(updateSpinnerState(false));
        return;
      }
      let data = await getTripsPaymentList({
        filters: {
          ...formData,
          vendorId: Object.values(vendorsList).filter(
            (item) => item.vendorName === formData.vendorName
          )?.[0]?.objectId,
          warehouse: (!isAdmin || !isWHPaymentUser) && warehouse?.warehouseCode,
        },
      });
      data.result = data?.result.sort((a, b) => {
        const dispatchedAtA = new Date(
          a.dispatchedAt.getTime() + 5.5 * 60 * 60 * 1000
        );
        const dispatchedAtB = new Date(
          b.dispatchedAt.getTime() + 5.5 * 60 * 60 * 1000
        );
        const dateA = Number(
          dispatchedAtA
            .toISOString()
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('')
        );
        const dateB = Number(
          dispatchedAtB
            .toISOString()
            .substring(0, 10)
            .split('-')
            .reverse()
            .join('')
        );

        // Primary sort: dispatchedAt (by date only)
        if (dateA !== dateB) {
          return dateA - dateB;
        }

        // Secondary sort: warehouseCode (alphabetically)
        if (a.warehouseCode !== b.warehouseCode) {
          return a.warehouseCode.localeCompare(b.warehouseCode);
        }

        // Tertiary sort: createdAt (full timestamp)
        return new Date(a.createdAt) - new Date(b.createdAt);
      });

      setTripsData(data.result);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch data!'),
          message: `${error.message}`,
        })
      );
    }
  };

  const handleFilterSubmit = async (startDate, endDate) => {
    if (!startDate && !endDate) {
      await getUnpaidTripsData(formInput);
    } else {
      const data = {
        ...formInput,
        fromDate: new Date(startDate),
        toDate: new Date(endDate),
      };
      setFormInput(data);
      await getUnpaidTripsData(data);
    }
  };

  const handleEditClick = async (tripNumber) => {
    try {
      dispatch(updateSpinnerState(true));
      const parcels = await getTripDetailsByTripNumberFromServer(tripNumber);
      setSelectedTrip({
        trip: tripsData.find((item) => item.tripNumber === tripNumber),
        parcels,
      });
      setShowEditModal(true);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch data!'),
          message: `${error.message}`,
        })
      );
    }
  };

  const handleSubmitClick = (data) => {
    setSaveData(data);
    setShowSaveModal(true);
  };

  const handleSaveClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateTripPayment(data);
      await handleFilterSubmit();
      setShowSaveModal(false);
      setShowEditModal(false);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to save data!'),
          message: `${error.message}`,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  useFetchVendors(vendorsList);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <h4>{t('Per Delivery Trips')}</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '4px',
              }}
            >
              <Button
                variant={showFilterOptions ? 'primary' : 'white'}
                onClick={() => {
                  setShowFilterOptions((prev) => !prev);
                }}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                }}
                size="sm"
              >
                <FontAwesomeIcon icon={faSliders} />
                &nbsp;&nbsp;{t('Filter')}
              </Button>
              {/* <ExcelDownloadReport
                  data={dataToDownload || []}
                  fileName={'vehicle-payments'}
                /> */}
            </div>
          </div>
        </div>
      </div>
      {showFilterOptions && (
        <>
          <div
            className="table-settings mb-2 p-3"
            style={{
              background: 'white',
              transition: 'max-height 0.7s ease-in-out',
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '20px',
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <Form.Group>
                <Form.Label>Vendor Name</Form.Label>
                <EditableDropDown
                  id={'vendorName'}
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      vendorName: e.value,
                    }))
                  }
                  backgroundColor="#eaedf2"
                  value={formInput['vendorName']}
                  options={arrayToDropdownOptions(
                    Object.values(vendorsList).map((item) => item.vendorName)
                  )}
                  placeholder={'Select Vendor Name'}
                  isInvalid={formErrors['vendorName']}
                />
                <ErrorMessage>{formErrors['vendorName']}</ErrorMessage>
              </Form.Group>
              <Form.Group>
                <Form.Label>Vehicle Number</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                    backgroundColor: '#eaedf2',
                  }}
                  type="text"
                  value={formInput['vehicleNumber']}
                  onChange={(e) => {
                    e.persist();
                    setFormInput((prev) => ({
                      ...prev,
                      vehicleNumber: e.target.value,
                    }));
                  }}
                  placeholder="Enter the Vehicle No."
                />
                <ErrorMessage>{formErrors['vehicleNumber']}</ErrorMessage>
              </Form.Group>
              {isAdmin && (
                <>
                  <Form.Group>
                    <Form.Label>Store Name</Form.Label>
                    <EditableDropDown
                      id={'warehouses'}
                      onChange={(e) =>
                        setFormInput((prev) => ({
                          ...prev,
                          storeName: e.value,
                        }))
                      }
                      backgroundColor="#eaedf2"
                      value={formInput['storeName']}
                      options={returnWarehouseDropdownOptions(otherWarehouses)}
                      placeholder={'Select Store Name'}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Zone</Form.Label>
                    <EditableDropDown
                      onChange={(e) =>
                        setFormInput((prev) => ({
                          ...prev,
                          zone: e.value,
                        }))
                      }
                      options={arrayToDropdownOptions(
                        Object.keys(configData?.zones)
                      )}
                      placeholder={'Select an Option'}
                      value={formInput['zone']}
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group className="d-flex align-items-end">
                <DateRangeSelector onSubmit={handleFilterSubmit} />
              </Form.Group>
            </div>
            <div
              className="mt-3"
              style={{
                gridColumn: 'span 3',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <Button
                variant="white"
                onClick={() => setFormInput(initialFormInput)}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                  background: '#EAEDF2',
                }}
              >
                <FontAwesomeIcon icon={faArrowRotateLeft} />
                &nbsp;&nbsp;{t('Reset')}
              </Button>
            </div>
          </div>
        </>
      )}
      <div className="mb-2 mt-2">
        Total Amount :{' '}
        {tripsData?.reduce((acc, item) => acc + item.totalAmount, 0) || 0}
      </div>
      <TableOnly
        data={tripsData}
        columns={perDeliveryColumns({
          isModal: true,
          isEdit: true,
          editFunction: handleEditClick,
        })}
        id={'perDeliveryPayments'}
      />
      {selectedTrip && (
        <TripEditModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          tripDetails={selectedTrip}
          onSubmit={handleSubmitClick}
          handleSaveClick={handleSaveClick}
        />
      )}
      {Object.keys(saveData).length > 0 && (
        <TripSaveModal
          show={showSaveModal}
          handleClose={() => setShowSaveModal(false)}
          saveData={saveData}
          setSaveData={setSaveData}
          tripDetails={selectedTrip}
          vendorsList={vendorsList}
          onSubmit={handleSaveClick}
        />
      )}
    </>
  );
};

export default PerDeliveryPayments;
