import {
  faFileExport,
  faHome,
  faPlus,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setActiveTripsListInStore,
  setConfirmedTripsInStore,
  setOpenTripsListInStore,
  setSelectedTripInStore,
} from '../../actions/trips';
import { Routes } from '../../router/routes';
import {
  getCurrentTripsFromServer,
  getTemporaryTripsFromServer,
} from '../../parse-functions/trips';
import { convertArrayToObj } from '../../utils/json';
import TripsTableData from './tripsTableData';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import DateRangeSelector from '../../components/common/DateRangeSelector';
import { setFiltersInStore } from '../../actions/filters';
import { returnEndOfDay, returnStartOfDay } from '../../utils/datetime';
import { returnActiveTripColumns } from './tripsUtils';
import { returnActiveTripsDataToDownload } from '../../utils/trips';
import ExcelDownloadReport from '../../components/common/excel/download';
import { returnWarehouseDropdownOptions } from '../../utils/warehouse';
import EditableDropDown from '../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';

const ConfirmedTrips = () => {
  const { t } = useTranslation();
  const pageType = 'confirmedTrips';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirmedTrips = useSelector(
    (state) => state.trips.confirmedTrips || {}
  );
  const filtersValue = useSelector((state) => state.filters?.[pageType]);
  const [formInput, setFormInput] = useState({
    storeName: filtersValue?.storeName,
    region: filtersValue?.region,
  });
  const zones = useSelector((state) => state?.user?.config?.zones || {});
  const storeInfo = useSelector((state) => state.user.warehouse || {});
  const [dataToDownload, setDataToDownload] = useState([]);
  const isSuperAdmin = useSelector(
    (state) => state.user.preferences.isSuperAdmin || false
  );
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const handleRowClick = (tripNumber, objectId) => {
    dispatch(setSelectedTripInStore(tripNumber));
    navigate(`/trips/confirmed/${objectId}`);
  };

  const handlePrintClick = (event, objectId) => {
    event.stopPropagation();
    window.open(`${window.location.origin}/trips/${objectId}/print`, '_blank');
  };
  const getAllTrips = async (requestProps) => {
    dispatch(updateSpinnerState(true));
    try {
      dispatch(setActiveTripsListInStore([]));
      const tempTrips = await getTemporaryTripsFromServer({
        ...requestProps,
        isConfirmed: true,
      });
      const tripsObject = convertArrayToObj(tempTrips, 'tripNumber');
      dispatch(setConfirmedTripsInStore(tripsObject));
      const data = returnActiveTripsDataToDownload(tripsObject);
      setDataToDownload(data);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('All trips fetched'),
          message: t(
            'These are all the confirmed trips currently from your warehouse'
          ),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error in getting cofirmed Trips'),
          message: e.message,
        })
      );
    }
  };
  const onDateSelect = (startDate, endDate) => {
    const requestProps = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      warehouseCode: !isSuperAdmin
        ? storeInfo?.warehouseCode
        : formInput.storeName?.[0],
      region: isSuperAdmin && formInput.region?.[0],
      isSuperAdmin,
    };
    dispatch(
      setFiltersInStore({
        pageType,
        data: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          storeName: formInput.storeName,
          region: formInput.region,
        },
      })
    );
    getAllTrips(requestProps);
  };
  const handleGetTrips = () => {
    getAllTrips({
      startDate: filtersValue?.startDate
        ? new Date(filtersValue?.startDate)
        : returnStartOfDay(new Date()),
      endDate: filtersValue?.endDate
        ? new Date(filtersValue?.endDate)
        : returnEndOfDay(new Date()),
      warehouseCode:
        filtersValue?.selectedStore?.[0] || storeInfo?.warehouseCode,
      isSuperAdmin,
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Trips')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('List')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t('Confirmed Trips')}</h4>
          <p className="mb-0">
            {t('All the confirmed trips of your warehouse are listed here.')}
          </p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            size="sm"
            variant="primary"
            style={{
              marginRight: 20,
            }}
            onClick={() => {
              navigate(Routes.PlanningPage.path);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;&nbsp; {t('New Trip')}
          </Button>
          <ButtonGroup>
            <Button
              onClick={() => {
                handleGetTrips();
              }}
              variant="outline-primary"
              size="sm"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;&nbsp;{t('Refresh')}
            </Button>
            <ExcelDownloadReport
              data={dataToDownload || []}
              fileName={`Confirmed-Trips.csv`}
            />
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <div className="mb-2 d-flex gap-4">
          {isSuperAdmin && (
            <div className="d-flex gap-4">
              <Form.Group>
                <Form.Label>{t('Region')}</Form.Label>
                <EditableDropDown
                  id="region"
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      region: [e.value],
                    }))
                  }
                  value={formInput.region}
                  options={[
                    { label: 'All', value: 'All' },
                    ...arrayToDropdownOptions(Object.keys(zones)),
                  ]}
                  placeholder="Choose Region"
                  backgroundColor="#F0F0F0"
                  width={'250px'}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Store Name')}</Form.Label>
                <EditableDropDown
                  id="warehouseCode"
                  onChange={(e) =>
                    setFormInput((prev) => ({
                      ...prev,
                      storeName: [e.value],
                    }))
                  }
                  value={formInput.storeName}
                  options={[
                    { label: 'All', value: 'All' },
                    ...returnWarehouseDropdownOptions(otherWarehouses),
                  ]}
                  placeholder="Choose Store Name"
                  backgroundColor="#F0F0F0"
                  width={'250px'}
                />
              </Form.Group>
            </div>
          )}
          <div className="d-flex gap-4">
            <div className="mt-4">
              <DateRangeSelector
                onSubmit={onDateSelect}
                filtersValue={filtersValue}
              />
            </div>
          </div>
        </div>
      </div>

      <TripsTableData
        columns={returnActiveTripColumns(handlePrintClick)}
        data={Object.values(confirmedTrips)}
        onRowClick={handleRowClick}
      />
    </>
  );
};
export default ConfirmedTrips;
