export const orderStatusMap = {
  pending: 'Pending',
  delivered: 'Delivered',
  failed: 'Failed',
  yes: 'No Buyback',
  buybackpicked: 'BuyBack - Collected',
  buybackpending: 'BuyBack - Not Collected',
  buybackdeclined: 'BuyBack Not Given & Cash Collected',
  buybackpickedcashcollected: 'BuyBack - Collected & Cash Collected',
  defectivepending: 'Delivered - Defective',
  replacementpicked: 'Defective - Collected',
  replacementpending: 'Defective - Not Collected',
  returnok: 'Return OK',
  defectivepicked: 'Return Defective',
  modelchange: 'Model Change',
  houselocked: 'House Locked',
  nextdaydelivery: 'Next Day Delivery',
  addressnotfound: 'Address Not Found',
  noresponsibleperson: 'No Responsible Person',
  holddelivery: 'Hold Delivery',
  others: 'Others',
  monthlyFixed: 'Monthly Fixed',
  fixedPerDelivery: 'Fixed Per Delivery',
  extraVehicle: 'Extra Vehicle',
  extraPerDelivery: 'Extra Per delivery',
  droppedathub: 'Dropped at Hub',
  notPlannedForDelivery: 'Not Loaded',
  modelChange: 'Model Change',
  deliveries: 'Deliveries',
  products: 'Products',
  branchDroppedAt: 'Branches',
  isDisplay: 'Display / GT',
  deliveryQuantity: 'Delivery Quantity',
};

export const yesNoDropdownOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const warehousesToHideFromList = [
  'Goregaon Warehouse',
  'Bhiwandi Warehouse',
];
export const loaderMasterSampleFile =
  'https://vs-systems-artifacts.s3.ap-south-1.amazonaws.com/LOADER+MASTER.xlsx';
export const warehouseTargetSampleFile =
  'https://vs-system-templates.s3.ap-south-1.amazonaws.com/Warehouse-Target-Sample.xlsx';
export const productsUploadSampleFile =
  'https://vs-system-templates.s3.ap-south-1.amazonaws.com/Products_Upload_Example.xlsx';
export const liquidationSchemeSampleFile =
  'https://vs-system-templates.s3.ap-south-1.amazonaws.com/Liquidation_Scheme_Example.xlsx';
export const upgradeOfferSchemeSampleFile =
  'https://vs-system-templates.s3.ap-south-1.amazonaws.com/UpgradeOffer_Upload_Example.xlsx';

export const unpaidTripCategories = ['gt', 'failed'];
export const tripCategories = ['gt', 'failed', 'droppedAtHub'];

export const schemeStatus = [
  'draft',
  'ongoing',
  'completed',
  'cancelled',
  'rejected',
  'closed',
];

export const paymentTypes = {
  monthlyFixed: 'Monthly Fixed',
  extraVehicle: 'Extra Vehicle',
  fixedPerDelivery: 'Per Delivery',
  extraPerDelivery: 'Per Delivery',
};
