import React, { useEffect, useState } from 'react';
import AccordionComponent from '../../../../components/AccordionComponent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddSchemeDetails from './AddSchemeDetails';
import AddProductDetails from './AddProductDetails';
import { convertExcelDataToJSONRows } from '../../../../utils/excel';
import { arrayToDropdownOptions } from '../../../../utils/sms/arrayToDropdownOptions';
import { Button, Card } from '@themesberg/react-bootstrap';
import {
  addNewScheme,
  getAllCompaniesCategoriesSubCategoriesData,
  getProductsByCompaniesCategoriesSubCategories,
} from '../../../../parse-functions/sms/scheme';
import { updateSpinnerState } from '../../../../actions/spinner';
import { updateToastInfo } from '../../../../actions/settings';
import {
  addSchemeValidate,
  validateProductFetch,
} from '../../validate/schemeValidate';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
const AddSchemeRedesign = ({ editData, handleSave }) => {
  const initialFormInput = editData
    ? editData
    : {
        company: [],
        schemeCircularNo: '',
        schemeCircularDate: '',
        validFrom: '',
        validTill: '',
        regions: [],
        type: [],
        remarks: '',
        uploadExcel: false,
        sellIntype: [],
        category: [],
        subCategory: [],
        subCategory2: [],
        subCategory3: [],
        payoutBasis: [],
        products: [{ productName: [], valuationType: [], payout: '' }],
      };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formInput, setFormInput] = useState(initialFormInput);
  const [formErrors, setFormErrors] = useState({});
  const [categoryOptions, setCategoryOptions] = useState({
    categoryOptions: [],
    subCategory1Options: [],
    subCategory2Options: [],
    subCategory3Options: [],
  });
  const [subCategoryOptions, setSubCategoryOptions] = useState();
  const [productOptions, setProductOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [formProps, setFormProps] = useState({
    showCappingType: editData?.type?.[0] === 'Sell-out' ? true : false,
    copyCappingType: false,
    copyPayoutValue: true,
    copyValuationType: true,
    addTarget: false,
  });
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const handleAddScheme = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const valid = addSchemeValidate(formInput, setFormErrors, formProps);
      if (!valid) {
        dispatch(updateSpinnerState(false));
        return;
      }
      let data = formInput;
      data.products = data.products?.map((item) => {
        return {
          ...item,
          productName: Array.isArray(item.productName)
            ? item.productName[0]
            : item.productName,
          vanNo:
            item.vanNo ||
            (Array.isArray(item.productName)
              ? products[item.productName[0]]
              : products[item.productName]),
          cappingType: formProps.showCappingType ? item.cappingType : '',
          cappingQuantity: formProps.showCappingType
            ? item.cappingQuantity
            : '',
          valuationType: Array.isArray(item.valuationType)
            ? item.valuationType[0]
            : item.valuationType,
        };
      });
      if (editData) {
        await handleSave(data);
      } else {
        await addNewScheme(data);
      }
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: editData
            ? t(`Scheme edited Successfully.`)
            : t(`Scheme created Successfully.`),
        })
      );
      setFormInput(initialFormInput);
      setProductOptions([]);
      setProducts([]);
      setFormProps({
        showCappingType: false,
        copyCappingType: false,
        copyPayoutValue: true,
        copyValuationType: true,
      });
    } catch (error) {
      console.log(error);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: editData
            ? t(`Scheme edit failed.`)
            : t(`Scheme creation failed.`),
          message: error.message,
        })
      );
    }
  };

  const fileUploadComplete = (file) => {
    const allColumns = file.rows[0];
    const allRows = file.rows.slice(1, file.rows.length);
    const arrData = convertExcelDataToJSONRows(allColumns, allRows);

    const errors = new Set();
    const validProducts = [];

    arrData.forEach((item, index) => {
      // Required field checks
      if (!item.productName) errors.add('product');
      if (
        !item.vanNo ||
        typeof item.vanNo !== 'number' ||
        item.vanNo?.toString()?.length !== 6
      )
        errors.add('vanNo');
      if (!item.valuationType) errors.add('valuationType');
      if (!item.payout || isNaN(Number(item.payout))) errors.add('payout');
      // Ensure valuationType is either "value" or "percentage"
      if (
        !['value', 'percentage'].includes(item.valuationType?.toLowerCase())
      ) {
        errors.add('valuationType');
      }

      // Store errors for this row
      if (errors.size > 0) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: `Upload valid excel file with correct formatting for ${Array.from(
              errors
            ).join(', ')}`,
          })
        );
        return;
      } else {
        validProducts.push({
          productName: [item.productName],
          vanNo: item.vanNo,
          valuationType: [item.valuationType],
          payout: item.payout,
          cappingType: [item.cappingType],
          cappingQuantity: item.cappingQuantity,
          vsAmount: item.vsAmount,
          brandAmount: item.brandAmount,
          target: item.target,
        });
      }
    });

    // Update state only with valid products
    setFormInput((prev) => ({
      ...prev,
      products: validProducts,
    }));

    // Show capping type if any row contains capping data
    if (arrData.some((item) => item.cappingType || item.cappingQuantity)) {
      setFormProps((prev) => ({
        ...prev,
        showCappingType: true,
      }));
    }
    if (arrData.some((item) => item.target)) {
      setFormProps((prev) => ({
        ...prev,
        addTarget: true,
      }));
    }
  };

  const fetchProducts = async () => {
    const valid = validateProductFetch(formInput, setFormErrors);
    if (!valid) {
      return;
    } else {
      try {
        dispatch(updateSpinnerState(true));
        const options = await getProductsByCompaniesCategoriesSubCategories({
          companiesName: formInput.company,
          categoriesName: formInput.category,
          subCategories: formInput.subCategory,
          subCategory2: formInput.subCategory2,
          subCategory3: formInput.subCategory3,
        });
        const productNames = options?.map((item) => item.productName);
        // setFormInput((prev) => {
        //   const product = prev.products.filter((item) =>
        //     productNames.includes(item?.productName?.[0])
        //   );
        //   return {
        //     ...prev,
        //     products:
        //       product.length === 0
        //         ? [{ productName: [], valuationType: [], payout: '' }]
        //         : product,
        //   };
        // });
        setProducts(
          options.reduce((acc, item) => {
            if (!acc[item.productName]) {
              acc[item.productName] = item.vanNo;
            }
            return acc;
          }, {})
        );
        setProductOptions(arrayToDropdownOptions(productNames));
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: '',
            message: t(`Successfully fetched Products.`),
          })
        );
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: t(`Error while fetching products.`),
          })
        );
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        let data = [];
        if (formInput.company.length > 0 && isAdmin) {
          data = await getAllCompaniesCategoriesSubCategoriesData({
            isCategory: true,
            company: formInput.company[0],
          });
          setCategoryOptions((prev) => ({
            ...prev,
            categoryOptions: arrayToDropdownOptions(
              data?.map((item) => item.categoryName)
            ),
          }));
        } else {
          data = arrayToDropdownOptions(
            userInfo?.productCategory?.filter((item) => item !== 'All') || []
          );
          setCategoryOptions((prev) => ({
            ...prev,
            categoryOptions: data,
          }));
        }

        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        console.log(error);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: t(`Error while fetching categories.`),
          })
        );
      }
    };
    if (!editData) {
      setFormInput((prev) => {
        let updatedInput = { ...prev };
        updatedInput.category = [];
        updatedInput.subCategory = [];
        updatedInput.products = [
          { productName: [], valuationType: [], payout: '' },
        ];
        return updatedInput;
      });
    }
    fetchData();
  }, [formInput.company]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        let data = [];
        if (formInput.category?.length > 0) {
          data = await getAllCompaniesCategoriesSubCategoriesData({
            isAllSubCategory: true,
            company: formInput.company[0],
            category: formInput.category,
          });
          setSubCategoryOptions(data);
          setCategoryOptions((prev) => ({
            ...prev,
            subCategory1Options: arrayToDropdownOptions(
              Array.from(
                new Set(
                  data
                    ?.filter((item) => item.productSubCategory1)
                    .map((item) => item.productSubCategory1)
                )
              )
            ),
          }));
        }
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: t(`Error while fetching subCategories.`),
          })
        );
      }
    };
    fetchData();
  }, [formInput.category]);
  return (
    <div className="p-2">
      {!editData && (
        <BreadCrumbTitle breadcrumbs={[{ title: t('Add New Scheme') }]} />
      )}
      <Card className="mt-4 p-2 mb-2">
        <h5 className="m-0">Scheme Details</h5>
      </Card>
      <AddSchemeDetails
        formInput={formInput}
        setFormInput={setFormInput}
        formErrors={formErrors}
        handleFileUpload={fileUploadComplete}
        setFormProps={setFormProps}
      />
      <Card className="mt-4 p-2 mb-2">
        <h5 className="m-0">Product Details</h5>
      </Card>
      <AddProductDetails
        formInput={formInput}
        setFormInput={setFormInput}
        formProps={formProps}
        setFormProps={setFormProps}
        formErrors={formErrors}
        productOptions={productOptions}
        categoryOptions={categoryOptions}
        subCategoryOptions={subCategoryOptions}
        setCategoryOptions={setCategoryOptions}
        fetchProducts={fetchProducts}
      />
      <div className="d-flex justify-content-end mt-4 ">
        <Button
          style={{
            backgroundColor: '#F0F0F0',
            color: 'black',
          }}
          onClick={handleAddScheme}
        >
          {editData ? 'Save Update' : 'Create Scheme'}
        </Button>
      </div>
    </div>
  );
};
export default AddSchemeRedesign;
