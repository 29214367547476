import React from 'react';
import { useSelector } from 'react-redux';
import { Routes } from '../../router/routes';
import AssignedOrders from '../oms/orders/AssignedOrders';
import AwaitingInvoiceRegion from '../oms/regionOrders/AwaitingInvoiceRegion';
import PlanningPage from '../PlanningPage';
import AssignedTargets from '../wms/targets/assigned';
import EntryList from '../securityGuard/EntryList';
import ActiveSchemes from '../scheme-management/Components/SchemRedesign/RedesignActiveSchemes';

const HomePage = () => {
  const preferences = useSelector((state) => state?.user?.preferences) || {};
  const permissions = useSelector((state) => state?.user?.permissions || {});

  if (preferences?.applications?.includes('sms')) {
    return <ActiveSchemes />;
  }

  if (preferences?.userType === 'securityGuard') {
    return <EntryList />;
  }

  if (preferences?.applications?.includes('wms')) {
    return <AssignedTargets />;
  }

  if (preferences?.applications?.includes('oms')) {
    if (permissions.hasOwnProperty(Routes.OMS.Orders.Assigned.path)) {
      return <AssignedOrders />;
    } else {
      return <AwaitingInvoiceRegion />;
    }
  }

  if (
    preferences?.applications?.includes('dms') ||
    !preferences?.hasOwnProperty('applications')
  ) {
    return <PlanningPage />;
  }

  return <PlanningPage />;
};

export default HomePage;
