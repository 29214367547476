import React, { useState, useEffect } from 'react';
import {
  Badge,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from '@themesberg/react-bootstrap';
import { getOrderCount, getPerdeliveryRate } from './utils';
import { returnCustomerAddressString } from '../../utils/table';
import NewTable from '../../components/common/NewTable';
import {
  faCheck,
  faCircleInfo,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { tripCategories, unpaidTripCategories } from '../../utils/constants';
import { getTripBadgeStatus } from '../../utils/trips';

const TripEditModal = ({
  show,
  handleClose,
  tripDetails,
  onSubmit,
  handleSaveClick,
}) => {
  const [parcels, setParcels] = useState([]);
  const [parcelCalculation, setParcelCalculation] = useState([]);
  const [focusRowId, setFocusRowId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (tripDetails) {
      setParcels(tripDetails.parcels || []);
      if (tripDetails.trip?.parcelCalculation) {
        const parcelCalculation = JSON.parse(
          tripDetails.trip?.parcelCalculation
        );
        setParcelCalculation(
          Object.keys(parcelCalculation).map((item) => {
            const matchedParcels = tripDetails.parcels.filter((parcel) => {
              const keyName = `${parcel?.customerName?.toLowerCase()}-${
                parcel.mobileNo
              }`;
              return keyName === item;
            });
            const extraCharges = matchedParcels.reduce((sum, parcel) => {
              return sum + (parcel.voucherDetails?.invoiceAmount || 0);
            }, 0);

            const perDeliveryRate = parcelCalculation[item].perDeliveryRate
              ? parcelCalculation[item].perDeliveryRate
              : getPerdeliveryRate(
                  parcelCalculation[item].tripCategory,
                  tripDetails.trip?.zone
                )
              ? 0
              : tripDetails?.trip?.perDeliveryRate;
            const deliveryCost =
              parcelCalculation[item].deliveryCost ??
              (unpaidTripCategories.includes(
                parcelCalculation[item].tripCategory
              )
                ? 0
                : getOrderCount(
                    parcelCalculation[item].count,
                    parcelCalculation[item].tripCategory,
                    tripDetails.trip?.zone
                  )) * perDeliveryRate;
            return {
              ...parcelCalculation[item],
              keyName: item,
              customerName: item.split('-')[0],
              mobileNo: item.split('-')[1],
              perDeliveryRate,
              deliveryCost,
              extraCharges,
              totalAmount: deliveryCost + extraCharges,
            };
          })
        );
      }
    }
  }, [tripDetails]);

  const renderToolTip = (props, row) => {
    const groupedInvoices = Array.from(
      new Set(
        parcels
          .map((parcel) => {
            const [name, number] = row.keyName.split('-');
            if (
              (name?.trim() === parcel?.customerName?.toLowerCase().trim() &&
                number?.split('_')?.[0]?.trim() === parcel?.mobileNo) ||
              name?.toLowerCase().trim() ===
                parcel?.receiveScanWarehouse?.toLowerCase().trim()
            ) {
              return parcel.invoiceNo;
            }
          })
          .filter((invoice) => invoice !== undefined)
      )
    );

    return (
      <Tooltip id="button-tooltip" {...props}>
        {groupedInvoices.map((invoice, index) => (
          <div key={index}>Invoice:{invoice}</div>
        ))}
      </Tooltip>
    );
  };

  const renderChallanToolTip = (props, parcels) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {parcels.map((parcel, index) => (
          <>
            <div key={index}>Voucher No: {parcel.voucherDetails?.voucher}</div>
            <div key={index}>
              Payment Type: {parcel.voucherDetails?.paymentType}
            </div>
            <div key={index}>
              Remarks: {parcel.voucherDetails?.remarks || ' '}
            </div>
          </>
        ))}
      </Tooltip>
    );
  };

  const handleInputChange = (e, keyName, field) => {
    const { value } = e.target;
    const currentCalculation = parcelCalculation.map((item) => {
      if (item.keyName === keyName) {
        switch (field) {
          case 'perDeliveryRate':
            return {
              ...item,
              perDeliveryRate: value,
              deliveryCost:
                (!tripCategories.includes(item?.tripCategory)
                  ? getOrderCount(item.count, '', tripDetails?.trip?.zone)
                  : 1) * value,
              edited: true,
            };
          case 'remarks':
            return {
              ...item,
              remarks: value,
              edited: true,
            };
          default:
            return item;
        }
      }
      return item;
    });
    setParcelCalculation(currentCalculation);
  };

  const handleSubmit = () => {
    const data = parcelCalculation.map(
      ({ customerName, mobileNo, ...rest }) => {
        if (rest?.edited) {
          return {
            ...rest,
            totalAmount: rest.deliveryCost + rest.extraCharges,
          };
        } else {
          return {
            keyName: rest.keyName,
            count: rest.count,
            tripCategory: rest.tripCategory,
            deliveryCost: rest.deliveryCost,
          };
        }
      }
    );

    const extraCharges = parcels.reduce(
      (acc, item) => acc + (item.voucherDetails?.invoiceAmount || 0),
      0
    );
    const totalAmount = data.reduce((acc, item) => acc + item?.deliveryCost, 0);
    const totalCost = extraCharges + totalAmount;
    onSubmit({
      totalCost,
      data,
      vendorName: tripDetails.trip?.driverInfo?.vendorName,
      driverName: tripDetails.trip?.driverInfo?.driverName,
      totalQuantity:
        (tripDetails?.trip?.deliveryRateInfo?.deliveryQuantity ||
          tripDetails?.trip?.deliveryRateInfo?.gtCount ||
          0) +
        (tripDetails?.trip?.deliveryRateInfo?.package || 0) +
        (tripDetails?.trip?.deliveryRateInfo?.defectivePicked || 0),
      totalAmount,
      tripId: tripDetails.trip?.objectId,
      extraCharges,
    });
  };

  const columns = [
    {
      dataField: 'customerName',
      text: 'Customer Name',
      formatter: (cell, row) => {
        return (
          <div className="row d-flex">
            {returnCustomerAddressString(
              cell.toUpperCase(),
              `${row.mobileNo || '-'}`,
              200,
              20
            )}
          </div>
        );
      },
    },
    {
      dataField: 'keyName',
      text: 'Address',
      formatter: (cell, row) => {
        const address = parcels.find((item) => {
          let keyName = `${item?.customerName?.toLowerCase()}-${item.mobileNo}`;
          if (row.tripCategory === 'failed') {
            keyName = `${keyName}_failed`;
          }
          return keyName === cell;
        });
        let shippingAddress, shippingPincode;
        if (address) {
          shippingAddress = `${address?.shippingAddress1 || ''} ${
            address?.shippingAddress2 || ''
          } ${address?.shippingAddress3 || ''}`;
          shippingPincode = address?.shippingPincode || '';
        }
        return (
          <div>
            {returnCustomerAddressString(
              shippingAddress || 'No Address Found',
              shippingPincode,
              250,
              30
            )}
          </div>
        );
      },
    },
    {
      dataField: 'count',
      text: 'Delivered',
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex gap-2">
              <div>{cell}</div>
              <div className="mb-1">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => renderToolTip(props, row)}
                >
                  <div>
                    <FontAwesomeIcon icon={faCircleInfo} className="fs-6" />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            {tripCategories.includes(row.tripCategory) && (
              <Badge
                bg={getTripBadgeStatus(row?.tripCategory)}
                className="badge-md"
              >
                {row?.tripCategory}
              </Badge>
            )}
          </>
        );
      },
    },
    {
      dataField: 'quantity',
      text: 'Qty',
      formatter: (cell, row) => {
        return (
          <div>
            {cell
              ? cell
              : !unpaidTripCategories.includes(row?.tripCategory)
              ? getOrderCount(
                  row.count,
                  row.tripCategory,
                  tripDetails?.trip?.zone
                )
              : 1}
          </div>
        );
      },
    },
    {
      dataField: 'perDeliveryRate',
      text: 'Rate',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Form.Group>
            <Form.Control
              type="number"
              id={row.keyName}
              value={cell}
              onChange={(e) => formatExtraData.handleRowChange(e, row.keyName)}
              style={{ width: '75px' }}
              onFocus={() => formatExtraData.handleFocusChange(row?.keyName)}
              onClick={() => formatExtraData.handleFocusChange(row?.keyName)}
              autoFocus={row?.keyName === focusRowId}
              disabled={!handleSaveClick}
            />
          </Form.Group>
        );
      },
      formatExtraData: {
        handleRowChange: (e, value) => {
          handleInputChange(e, value, 'perDeliveryRate');
        },
        handleFocusChange: (id) => {
          setFocusRowId(id);
        },
      },
    },
    {
      dataField: 'remarks',
      text: 'Remarks',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Form.Group>
            <Form.Control
              type="text"
              id={row.keyName}
              value={cell}
              onChange={(e) => formatExtraData.handleRowChange(e, row.keyName)}
              style={{ width: '150px' }}
              onFocus={() => formatExtraData.handleFocusChange(row?.keyName)}
              onClick={() => formatExtraData.handleFocusChange(row?.keyName)}
              autoFocus={row?.keyName === focusRowId}
              disabled={!handleSaveClick}
            />
          </Form.Group>
        );
      },
      formatExtraData: {
        handleRowChange: (e, value) => {
          handleInputChange(e, value, 'remarks');
        },
        handleFocusChange: (id) => {
          setFocusRowId(id);
        },
      },
    },
    {
      dataField: 'extraCharges',
      text: 'Vouchers',
      formatter: (cell, row) => {
        const matchedParcels = parcels.filter((item) => {
          const keyName = `${item?.customerName?.toLowerCase()}-${
            item.mobileNo
          }`;
          return keyName === row.keyName && item.voucherDetails;
        });

        if (!matchedParcels || matchedParcels.length === 0) {
          return <div>-</div>;
        }
        return (
          <div className="d-flex gap-2">
            <div>{cell}</div>
            <div className="mb-1">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => renderChallanToolTip(props, matchedParcels)}
              >
                <div>
                  <FontAwesomeIcon icon={faCircleInfo} className="fs-6" />
                </div>
              </OverlayTrigger>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'deliveryCost',
      text: 'Total Amount',
    },
  ];

  return (
    <Modal show={show} centered onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Trip Details - {tripDetails?.trip?.tripNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-flex gap-2">
          <Form.Group className="w-100">
            <Form.Label>Total Deliveries Count</Form.Label>
            <Form.Control
              type="text"
              value={
                (tripDetails?.trip?.deliveryRateInfo?.deliveryQuantity ||
                  tripDetails?.trip?.deliveryRateInfo?.gtCount ||
                  0) +
                (tripDetails?.trip?.deliveryRateInfo?.package || 0) +
                (tripDetails?.trip?.deliveryRateInfo?.defectivePicked || 0) +
                (tripDetails?.trip?.deliveryRateInfo?.droppedAtHub || 0)
              }
              disabled
            />
          </Form.Group>
          <Form.Group className="w-100">
            <Form.Label>Total Cost</Form.Label>
            <Form.Control
              type="text"
              value={parcelCalculation.reduce(
                (acc, item) => acc + item.deliveryCost,
                0
              )}
              disabled
            />
          </Form.Group>
        </Form>
        <NewTable
          columns={columns}
          data={parcelCalculation}
          id="tripeditmodal"
        />
      </Modal.Body>
      {handleSaveClick && (
        <Modal.Footer>
          <Button
            className="text-gray"
            onClick={handleClose}
            style={{
              backgroundColor: '#F0F0F0',
              color: '#444444',
              border: 'None',
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
            &nbsp;&nbsp;
            {t('Cancel')}
          </Button>
          <Button
            style={{
              backgroundColor: '#444444',
              color: '#FFFFFF',
              border: 'None',
            }}
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;&nbsp;
            {t('Confirm')}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default TripEditModal;
