import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentInputValidate } from './utils';
import { updateSpinnerState } from '../../actions/spinner';
import { useDispatch } from 'react-redux';
import { savePaymentData } from '../../parse-functions/payments';
import { updateToastInfo } from '../../actions/settings';
import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { generateRandomId } from '../../utils/string';
import { ErrorMessage } from '../scheme-management/Components/StyledComponents';
import DatePicker from './DatePicker';

const TripPaidModal = (props) => {
  const { showModal, closeModal, data } = props;
  const { t } = useTranslation();
  const initialFormInput = {
    paymentMethod: '',
    invoiceNumber: '',
    invoiceDate: '',
    paymentDate: '',
    paymentRemarks: '',
  };
  const [formInput, setFormInput] = useState(initialFormInput);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const handleConfirmClick = async () => {
    try {
      const valid = paymentInputValidate(formInput, setFormErrors);
      if (!valid) {
        return;
      }
      dispatch(updateSpinnerState(true));
      const tripsIds = data.flatMap((item) =>
        item.trips.map((trip) => trip.objectId)
      );
      const saveData = {
        ...formInput,
        totalAmount:
          data.reduce((acc, item) => acc + item.totalAmount, 0)?.toFixed(2) ||
          0,
        tripNumbers: data.flatMap((item) =>
          item.trips.map((trip) => trip.tripNumber)
        ),
        vendorName: data[0].vehicleDetails?.vendorName,
        vendorId: data[0].vehicleDetails?.vendorObjectId,
        totalTrips: tripsIds.length,
      };

      await savePaymentData({ tripsIds, data: saveData });
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('Data saved successfully!'),
        })
      );
      closeModal();
    } catch (error) {
      if (error.message.invoiceNumber) {
        setFormErrors((prev) => ({
          ...prev,
          invoiceNumber: error.message.invoiceNumber,
        }));
      }
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t(''),
          message: t('Failed to save payment information!'),
        })
      );
    }
  };

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="h6">{t('Payment Information')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Payment Method</Form.Label>
          <Form.Control
            style={{
              height: 38,
              width: '100%',
              backgroundColor: '#eaedf2',
            }}
            type="text"
            value={formInput['paymentMethod']}
            isInvalid={formErrors['paymentMethod']}
            onChange={(e) => {
              e.persist();
              setFormInput((prev) => ({
                ...prev,
                paymentMethod: e.target.value,
              }));
            }}
            placeholder="Enter the Payment Method."
          />
          <ErrorMessage>{t(formErrors['paymentMethod'])}</ErrorMessage>
        </Form.Group>
        <Form.Group>
          <Form.Label>Payment Date</Form.Label>
          <DatePicker
            value={formInput['paymentDate']}
            setValue={(date) =>
              setFormInput((prev) => ({
                ...prev,
                paymentDate: date,
              }))
            }
            isInvalid={formErrors['paymentDate']}
            errorMessage={t(formErrors['paymentDate'])}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Invoice Number</Form.Label>
          <Form.Control
            style={{
              height: 38,
              width: '100%',
              backgroundColor: '#eaedf2',
            }}
            type="text"
            value={formInput['invoiceNumber']}
            isInvalid={formErrors['invoiceNumber']}
            onChange={(e) => {
              e.persist();
              setFormInput((prev) => ({
                ...prev,
                invoiceNumber: e.target.value,
              }));
            }}
            placeholder="Enter the Invoice No."
          />
          <ErrorMessage>{t(formErrors['invoiceNumber'])}</ErrorMessage>
        </Form.Group>
        <Form.Group>
          <Form.Label>Invoice Date</Form.Label>
          <DatePicker
            value={formInput['invoiceDate']}
            setValue={(date) =>
              setFormInput((prev) => ({
                ...prev,
                invoiceDate: date,
              }))
            }
            isInvalid={formErrors['invoiceDate']}
            errorMessage={t(formErrors['invoiceDate'])}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Payment Remarks</Form.Label>
          <Form.Control
            style={{
              width: '100%',
              backgroundColor: '#eaedf2',
            }}
            as="textarea"
            rows={3}
            value={formInput['paymentRemarks']}
            isInvalid={formErrors['paymentRemarks']}
            onChange={(e) => {
              e.persist();
              setFormInput((prev) => ({
                ...prev,
                paymentRemarks: e.target.value,
              }));
            }}
            placeholder="Enter the remarks"
          />
          <ErrorMessage>{t(formErrors['paymentRemarks'])}</ErrorMessage>
        </Form.Group>
        <Form.Group>
          <Form.Label>Total Final Payment</Form.Label>
          <Form.Control
            style={{
              height: 38,
              width: '100%',
              backgroundColor: '#eaedf2',
            }}
            type="text"
            value={data.reduce((acc, item) => acc + item.totalAmount, 0)}
            disabled={true}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={handleConfirmClick}>
          {t('Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TripPaidModal;
