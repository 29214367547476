import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import ParcelStatusPage from '../../../../components/common/parcelStatusPages';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { usersTableColumns } from '../../../../utils/sms/usersTable';
import { getSMSUsers } from '../../../../parse-functions/sms/getUserInfo';
import UserEditModal from '../UserEditModal';
import {
  updateUserStatus,
  updateUsers,
} from '../../../../parse-functions/sms/addUsers';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
import { Button, ButtonGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import TableOnly from '../IndividualScheme/TableOnly';
import ExcelDownloadReport from '../../../../components/common/excel/download';

const SMSUserList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const preferences = useSelector((state) => state?.user?.preferences || {});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [updateMode, setUpdateMode] = useState('');
  const [showFormEditModal, setShowFormEditModal] = useState(false);
  const [editData, setEditData] = useState();
  const [id, setId] = useState('');
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );
  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );

  const role = isAdmin
    ? 'isAdmin'
    : isBrandManager
    ? 'isBrandManager'
    : isAccountManager
    ? 'isAccountManager'
    : 'Unknown';

  const breadCrumbItems = arrayToBreadCrumbs([['Users'], ['SMS Users']]);

  const getSMSUsersList = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const smsUsers = await getSMSUsers({
        id: preferences.userId,
        role,
      });
      setUsersData(smsUsers);
      setSelectedUsers([]);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('SMS Users List fetched'),
          message: t('All the Users that use the SMS system'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };

  const onRefreshClick = () => {
    getSMSUsersList();
  };

  const handleShowDeleteModal = () => {
    setShowConfirmationModal(true);
  };

  const handleSelectedRowUpdate = (actionId, row) => {
    switch (actionId) {
      case 'Deactivate': {
        setUpdateMode('Deactivate');
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      case 'Edit': {
        setUpdateMode('Edit');
        let data = usersData.find((user) => user.objectId === row.objectId);
        if (data.role !== 'Account Manager') {
          data = {
            ...data,
            category: data.productCategory,
          };
        }
        setEditData(data);
        setShowFormEditModal(true);
        break;
      }
      case 'Activate': {
        setUpdateMode('Activate');
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCloseFormEditModal = () => {
    setShowFormEditModal(false);
  };
  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateUserStatus(data);
      getSMSUsersList();
      setSelectedUsers([]);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'User Update Successful',
          message: '',
        })
      );
      setShowConfirmationModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'User Update Failed',
          message: 'Please contact support for help',
        })
      );
    }
  };
  const handleModalOnConfirmClick = () => {
    const userId = usersData
      .filter((item) => item.objectId === id)
      .map((item) => item.userId);

    const updatedUsers = {
      id: userId,
      status: updateMode === 'Activate' ? 'active' : 'inactive',
    };
    handleOnConfirmClick(updatedUsers);
  };
  const handleFormSave = async (data) => {
    await updateUsers(data);
    handleCloseFormEditModal();
    getSMSUsersList();
  };

  useEffect(() => {
    if (role !== 'Unknown') {
      getSMSUsersList();
    }
  }, [role]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbTitle breadcrumbs={[{ title: 'SMS User List' }]} />
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup className="gap-3">
            <Button
              onClick={onRefreshClick}
              variant="white"
              className="btn-export "
              size="md"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;&nbsp;{t('Refresh')}
            </Button>
            <ExcelDownloadReport
              data={Object.values(usersData) || []}
              fileName={`SMS Users List`}
            />
          </ButtonGroup>
        </div>
      </div>
      <TableOnly
        data={usersData || []}
        columns={usersTableColumns({
          handleSelectedRowUpdate,
        })}
        id={'smsUsersList'}
      />
      <ConfirmationModal
        showModal={showConfirmationModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowConfirmationModal(false);
        }}
        modalBody={t(`Are you sure you want to ${updateMode} these users`)}
        modalTitle={t('Delete Users')}
      />
      <UserEditModal
        editData={editData}
        show={showFormEditModal}
        handleSave={handleFormSave}
        topTitle="Edit Users"
        handleClose={handleCloseFormEditModal}
      />
    </>
  );
};
export default SMSUserList;
